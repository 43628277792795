<template>
  <one-column-sidebar-left
      tab-selector="projecttab"
      type="project"
      back-button-tab-selector="projectbackbutton"
      :id="$route.params.id"
  >
    <info-panel slot="sidebarLeft"
                :id="$route.params.id"
                :preview-uri="$store.getters.getProjectPreviewUri(this.$route.params.id)"
                :image-editable="true"
                upload-type="Project"
                :created="$store.getters.getProjectCreationDate($route.params.id)"
                :last-change="$store.getters.getProjectChangeDate($route.params.id)"
                :downloadable="true"
                :organization="$store.getters.getProjectOrganization($route.params.id)"
                preview-id="projectEdit"
                :removable="true"
    >

      <div class="form-section" slot="info" v-if="$store.getters.isSuperAdmin">
         <div class="mt-2 mb-2"><span class="lighter"><icon type="folder" /> STORAGE</span><br /><span class="small">{{ `${$store.getters.getProjectOrganization($route.params.id)}/projects/${this.$route.params.id}` }}</span></div>
      </div>
    </info-panel>

    <team-attacher
        v-if="$store.getters.getProjectOrganization($route.params.id)"
      slot="sidebarLeft"
              class="mt-3"
              :id="$route.params.id"
              :teams="teams"
              store-name="Project"
              :limit-by-organization-id="$store.getters.getProjectOrganization($route.params.id)"
              @added="loadProjectTeams"
              @removed="loadProjectTeams"
          />
    <info-panel
        slot="sidebarLeft"
        :id="$route.params.id"
        :tags="$store.getters.getProjectTags($route.params.id)"
        :taggable="true"
        @removeTag="removeTag"
        @addTag="addTag"
        :limit-tags-to-organization="$store.getters.getProjectOrganization($route.params.id)"
        preview-id="projectEdit"
    />
        <div slot="mainContent">
            <content-title-block
                    v-if="$store.getters.getProjectName(id)"
                    :value="$store.getters.getProjectName(id)"
                    :id="$route.params.id"
                    store-name="Project"
                    :organization-id="$store.getters.getProjectOrganization($route.params.id)"
                    icon="folder"
            />
            <content-description-block
                    :value="$store.getters.getProjectDescription(id)"
                    :id="$route.params.id"
                    store-name="Project"
            />
           <div v-if="$store.getters.isSuperAdmin" class="row">
            <state-setter
              class="col-12 col-md-4 mb-2 mt-2"
              :target-id="$route.params.id"
              store-name="Project"
              :given-state="$store.getters.getProjectState($route.params.id)"
            />
          </div>

            <div class="row">
              <div class="col-12 col-md-6 no-gutters">
                <info-panel
                    preview-id="metasetattacher"
                    :collapsible="true"
                    :initially-collapsed="true"
                    info-panel-header="sfx edit"
                    v-if="$store.getters.isSuperAdmin"
                >
                  <meta-set-attacher
                      slot="info"
                      :target-id="$route.params.id"
                      store-name="Project"
                      @added="loadProject"
                      @removed="loadProject"
                      :given-meta-sets="$store.getters.getProjectMetaSets(id)"
                  />
                </info-panel>
              </div>
              <meta-panel
                  class="col-12 col-md-8"
                  :key="item.id" v-for="(item) in $store.getters.getProjectMetaSets(id)"
                  :title="item.description" :fields="item.metaFields" icon-left="folder"
                  store-name="Project"
                  :organization-id="$store.getters.getProjectOrganization($route.params.id)"
                  @updated="loadProject"
              />
            </div>

</div>
    </one-column-sidebar-left>
</template>

<script>
    import InfoPanel from "../components/InfoPanel";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import MetaPanel from "../components/widgets/meta/MetaPanel";
    import ContentTitleBlock from "../components/ContentTitleBlock";
    import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
    import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
    import TeamAttacher from "@/components/organizations/TeamAttacher";
    import StateSetter from "@/components/StateSetter";

    export default {
        name: 'ProjectEdit',
        components: {
            MetaPanel,
            InfoPanel,
            OneColumnSidebarLeft,
            ContentTitleBlock,
            ContentDescriptionBlock,
            MetaSetAttacher,
            TeamAttacher,
            StateSetter
        },
        data() {
            return {
                id: '',
                teams: []
            };
        },
        beforeMount() {
          this.loadProject();
          this.loadProjectTeams();
          this.id = this.$route.params.id;
        },
        methods: {
          loadProjectTeams() {
            this.$store.dispatch('clientLoadProjectTeams', {
              id: this.$route.params.id
            }).then(async data => {
              this.teams = data;
            });
          },
          loadProject() {
              this.$store.dispatch('loadProject', {
                id: this.$route.params.id,
                include: 'organization,tags,metaSets'
              }).catch(e => {
                if(e.status === 401) {
                  this.$router.push('/access-denied');
                }
              });
            },
            removeTag: function(tag) {
              this.$store.dispatch('removeProjectTag', {
                id: this.$route.params.id,
                args: [tag.id],
              });
            },
            addTag: function(tag) {
              this.$store.dispatch('addProjectTag', {
                id: this.$route.params.id,
                args: [tag.id],
              });
            },
            updateTags: function(tags) {
              this.$store.dispatch('updateProject', {
                id: this.$route.params.id,
                tags: tags,
              });
            },
  },
}
</script>