<template>
    <div class="p-2">
        <!--ADD DIX TRANSLATION-->
        <div class="col row mt-2 dflex">
            <div
                class="col-12 dflex checkbox-wrapper"
                v-if="element.formElementTypeString === vFormControls.CHECKBOXES"
            >
                <input
                    :id="'chkEmphasized' + $vnode.key"
                    type="checkbox"
                    v-model="element.emphasized"
                />
                <label :for="'chkEmphasized' + $vnode.key">{{ $t("emphasized") }}</label>
            </div>
            <div class="col-12 dflex checkbox-wrapper">
                <input
                    :id="'chkRequired' + $vnode.key"
                    type="checkbox"
                    v-model="element.isRequired"
                />
                <label :for="'chkRequired' + $vnode.key">{{ $t("isRequired") }}</label>
            </div>
            <div class="col-12 dflex checkbox-wrapper">
                <input
                    :id="'chkCorrect' + $vnode.key"
                    type="checkbox"
                    v-model="element.validateCorrectness"
                />
                <label :for="'chkCorrect' + $vnode.key">{{
                    $t("validateCorrectness")
                }}</label>
            </div>
        </div>
        <!--DIX-->
        <!--FOR EACH TRANSLATION ONE FIELD-->
        <div
            v-if="element.currentLang === key"
            class="col-12 dflex"
            :key="index2 + 1000000000 + forceReRenderKey"
            v-for="(key, index2) in Object.keys(element.label.dix)"
        >
            <div class="col-auto pl-0 pr-0 fit-content">
                <v-form-image-dix-cell
                    :element="element"
                    :image-dix="element.label.imageDix"
                />
            </div>
            <div class="col pl-1 pr-0">
                <!-- <span class="lighter">{{ key === "Unknown" ? "Default" : key }}</span> -->
                <!--QUESTION-->
                <!-- <span class="lighter"> {{ $t("question") }}</span> -->
                <textarea
                    :placeholder="
                        key === 'Unknown' ? $t('Question') : key + ' ' + $t('question')
                    "
                    rows="300"
                    class="form-text v-form-label form-text-dark"
                    type="text"
                    v-model="element.label.dix[key]"
                />

                <!--ANSWERS-->
                <div
                    class="dflex mt-1 row"
                    :key="index4 + 98239283"
                    v-for="(item, index4) in element.options"
                >
                    <div class="col-12 col-xxl-6 dflex">
                        <div class="col-auto pl-0 pr-0 fit-content">
                            <v-form-image-dix-cell
                                :element="element"
                                :image-dix="item.text.imageDix"
                            />
                        </div>
                        <div class="col pl-1 pr-0">
                            <textarea
                                :placeholder="
                                    (key === 'Unknown' ? '' : key) +
                                    ' ' +
                                    $t('answer') +
                                    ' ' +
                                    (index4 + 1)
                                "
                                rows="300"
                                class="form-text v-form-label form-text-dark"
                                type="text"
                                v-model="item.text.dix[key]"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-xxl-6 dflex mt-1">
                        <div class="col-10 pl-1 pr-0">
                            <div v-if="index2 === 0">
                                <div class="col-12 dflex checkbox-wrapper row">
                                    <input
                                        :id="'chkCorrect' + $vnode.key + index4"
                                        type="checkbox"
                                        v-model="item.isCorrectAnswer"
                                    />
                                    <label :for="'chkCorrect' + $vnode.key + index4">{{
                                        $t("isCorrectAnswer")
                                    }}</label>
                                </div>
                                <div class="col-12 dflex checkbox-wrapper row">
                                    <input
                                        :id="'chk' + $vnode.key + index4"
                                        type="checkbox"
                                        v-model="item.isChecked"
                                    />
                                    <label :for="'chk' + $vnode.key + index4">{{
                                        $t("isChecked")
                                    }}</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-2 pl-0 pr-0 text-right">
                            <Button class="icon-button" @click="removeOption(index4)">
                                <icon type="trash-alt" />
                            </Button>
                        </div>
                    </div>
                    <div
                        class="w-100 mt-1 text-right col"
                        v-if="index4 === element.options.length - 1"
                    >
                        <Button class="icon-button" @click="addOption(key)">
                            <icon type="plus" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from "@/components/Icon";
import Button from "@/components/forms/Button";
import { vFormControls } from "@/enum";
import vFormImageDixCell from "@/components/vForm/vFormImageDixCell";

export default {
    name: "vFormTEXT",
    components: {
        Icon,
        Button,
        vFormImageDixCell,
    },
    props: {
        element: { type: Object, required: true },
        stepNo: { type: Number, required: true },
    },
    data() {
        return {
            forceReRenderKey: 0,
            editImage: "",
            vFormControls: vFormControls,
        };
    },
    watch: {
        element: {
            deep: true,
            handler() {
                if (
                    this.element.options.filter((item) => {
                        return item.isCorrectAnswer;
                    }).length > 1
                ) {
                    this.element.formElementTypeString = vFormControls.MULTIPLE_CHOICE;
                    this.element.formElementType = vFormControls["MULTIPLE_CHOICE"];
                    //console.log("switching to multiple choise");
                } else {
                    this.element.formElementTypeString = vFormControls.CHECKBOXES;
                    this.element.formElementType = vFormControls["CHECKBOXES"];
                    //console.log("switching to radiobutton - single choice");
                }
            },
        },
    },
    beforeMount() {
        if (!this.element.options) {
            this.addOption("Unknown");
        }
        if (this.element.formElementTypeString === vFormControls.CHECKBOXES) {
            this.element.formElementType = vFormControls["CHECKBOXES"];
        } else {
            this.element.formElementType = vFormControls["MULTIPLE_CHOICE"];
        }
    },
    methods: {
        removeOption(index) {
            this.element.options.splice(index, 1);
        },
        addOption(lang) {
            if (!this.element.options) {
                this.element.options = [];
            }
            this.element.options.push({
                isCorrectAnswer: false,
                isChecked: false,
                text: {
                    dix: {
                        [lang]: "",
                    },
                    imageDix: {
                        [lang]: "",
                    },
                },
            });
            this.forceReRenderKey++;
        },
        addTranslation(lang) {
            this.element.label.dix[lang] = {
                isRequired: false,
                emphasized: false,
                showastiles: false,
            };
            this.addOption(lang);

            this.forceReRenderKey++;
        },
    },
};
</script>
