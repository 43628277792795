<template>
    <div class="p-2 mb-3">
        <!--NO TRANSLATION HERE-->
        <div class="pt-2 mb-2">
            <div class="col-12 dflex checkbox-wrapper">
                <input
                    id="chkRequired"
                    type="checkbox"
                    v-model="element.isRequired"
                />
                <label for="chkRequired">{{ $t("isRequired") }}</label>
            </div>
        </div>
        <div class="col-12"><slot /></div>
    </div>
</template>

<script>
import { vFormControls } from "@/enum";

export default {
    name: "vFormQR_CODE",
    components: {},
    props: {
        element: { type: Object, required: true },
    },
    data() {
        return {
            vFormControls: vFormControls,
        };
    },
    beforeMount() {
        this.element.formElementType = vFormControls["QR_CODE"];
        if (!this.element.isRequired) {
            this.element.isRequired = false;
        }
    },
    methods: {
        save() {
            this.$emit("save", this.element);
        },
    },
};
</script>

<style scoped></style>
