<template>
    <div v-if="globalUser">
        <div v-if="orgs && orgs.length">
            {{ $t("Organization") }}
            <div class="mt-1 org-switch orange-switch">
                <select class="custom-select" ref="orgswitch" v-model="selected">
                    <option v-for="(org, index) in orgs" :key="index" :value="org.id">
                        {{ org.displayName ? org.displayName : org.name }}
                    </option>
                </select>
            </div>
        </div>

        <div class="mt-2" v-if="teams && teams.length && showTeams">
            {{ $t("Team") }}
            <div class="org-switch orange-switch">
                <select class="custom-select" ref="teamswitch" v-model="selectedTeam">
                    <option
                        v-for="(team, index) in teams"
                        :key="index"
                        :value="team.id"
                        v-if="team.organizationId === selected"
                    >
                        {{ team.displayName ? team.displayName : team.name }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import { AdminData } from "@/enum";
import { mapState } from "vuex";
import ArrayMixin from "@/components/mixins/ArrayMixin.js";

export default {
    name: "OrganizationSwitcher",
    mixins: [ArrayMixin],
    props: {
        excludeViewers: { type: Boolean, default: true },
        op: { type: String, default: "create" },
        opOnly: { type: Boolean, default: false },
        showTeams: { type: Boolean, default: true },
        showAllOrgs: { type: Boolean, default: false },
        preSetOrganizationId: {type: String, default: null}
    },
    computed: mapState({
        globalUser: (state) => state.users.globalUser,
        globalTeams: (state) => state.dynamicStore.globalTeams,
    }),
    data() {
        return {
            orgs: [],
            teams: [],
            AdminData: AdminData,
            userId: "",
            selected: "",
            selectedTeam: "",
            loaded: false,
        };
    },
    watch: {
        globalTeams() {
            if (this.$store.getters.isSuperAdmin) {
                const tempTeams = this.globalTeams;
                this.teams = Object.keys(tempTeams).map((id) => {
                    return tempTeams[id];
                });
            }
        },
        globalUser(user) {
            if (user) {
                this.loadOrganizations().then(() => {
                    this.loadTeams();
                });
            }
        },
        /**
         * @callback setTeam - emits the selected team id
         * @callback unsetTeam - no params
         * */
        selectedTeam() {
            if (this.selectedTeam === "none") {
                this.$emit("unsetTeam");
            } else {
                this.$emit("setTeam", this.selectedTeam);
            }
        },
        /**
         * @callback setOrganization - emits the selected organization id
         * */
        selected() {
            this.$emit("setOrganization", this.selected);
            const org = this.orgs.filter((item) => {
                return item.id === this.selected;
            });
            let publicTeams = this.teams.filter((team) => {
                return team.organizationId === this.selected && team.visibility === 2;
            });
            console.log(
                this.teams.filter((team) => {
                    return team.organizationId === this.selected;
                })
            );
            if (!publicTeams) {
                publicTeams = this.teams.filter((team) => {
                    return team.organizationId === this.selected;
                });
            }
            if (org[0].hasPerm) {
                console.log("setting team with perm...");
                this.teams.unshift({
                    id: "none",
                    displayName: "none",
                    organizationId: this.selected,
                });
                this.selectedTeam = publicTeams[0].id;
            } else {
                console.log("setting team without perm...");
                this.teams = this.teams.filter((team) => {
                    return team.id !== "none";
                });
                this.selectedTeam = publicTeams[0].id;
            }
        },
    },
    beforeMount() {
        this.loadOrganizations()
          .then(() => {
            this.loadTeams();
          });
    },
    methods: {
        async loadOrganizations() {
            if (this.$store.getters.isSuperAdmin) {
                const tempOrgs = this.$store.getters.getOrganizations;
                this.orgs = Object.keys(tempOrgs)
                    .map((id) => {
                        return tempOrgs[id];
                    })
                    .filter((org) => {
                        return org.visibility > 0;
                    });
            } else if (this.showAllOrgs) {
                const tempOrgs = this.$store.getters.getOrganizations;
                this.orgs = Object.keys(tempOrgs)
                    .map((id) => {
                        return tempOrgs[id];
                    })
                    .filter((item) => {
                        return !this.$store.getters.isSuperOrg(item.id);
                    });
                // need to set perms as false because else they get saved as true
                this.orgs = this.orgs.map((org) => {
                    org.hasPerm = false;
                    return org;
                });
                return;
            } else {
                await this.$store
                    .dispatch("getUserOrganizationsWithPermission", {
                        op: this.op,
                    })
                    .then((orgs) => {
                        this.orgs = orgs;
                    });
            }
            this.orgs = this.orgs.map((org) => {
                org.hasPerm = true;
                return org;
            });

            return this.orgs.sort(this.objectComparer("displayName"));
        },
        async loadTeams() {
            if (this.showTeams) {
                if (this.$store.getters.isSuperAdmin) {
                    await this.$store.dispatch("loadTeams", {listName: 'globalTeams'}).then((teams) => {
                        this.teams = teams;
                    });
                } else {
                    await this.$store
                        .dispatch("getUserTeamsWithPermission", { op: this.op })
                        .then((teams) => {
                            this.teams = teams;
                            console.log(this.teams);
                            teams.map((team) => {
                                if (
                                    !this.orgs.filter((org) => {
                                        return org.id === team.organizationId;
                                    }).length
                                ) {
                                    this.orgs.push(
                                        this.$store.getters.getOrganizationById(
                                            team.organizationId
                                        )
                                    );
                                }
                            });
                        });
                }
            }
            this.setInitialActive();
            return this.teams.sort(this.objectComparer("displayName"));
        },
        // if only one organization is available, set it to active
        setInitialActive() {
            if(this.orgs && this.preSetOrganizationId) {
              this.selected = this.preSetOrganizationId;
            }
            else if (this.orgs && this.orgs.length === 1) {
                this.selected = this.orgs[0].id;
            }
        },
    },
};
</script>

<style lang="scss">
#sfx-user-navigation {
    .org-switch {
        background-color: rgba(0, 0, 0, 0.3);
        display: inline-block;
        margin-bottom: 0;
    }
}
</style>
