<template>
    <three-columns
        sub-menu-selector="librarypanel"
        back-button-tab-selector="sfxbackbutton"
        tab-selector="assemblytab"
        :id="$route.params.id"
    >
      <portal-target slot="leftContent" name="aboveTreeSection"/>
        <div class="row" slot="leftContent">
          <div class="col-12">
            <h2 class="box-title mb-2 too-long-text-break">
              {{ $store.getters.getAssemblyName($route.params.id) }}
            </h2>
          </div>
        </div>
        <div class="bg-beige p-3 mb-1" slot="leftContent" v-if="$route.query.nodeId">
          Showing subtree to node: {{ $route.query.nodeId }}<br />
          <Button @click="removeNodeId()">Reset Tree</Button>
        </div>
        <lazy-loading-hierchy
                slot="leftContent"
                v-if="projectId"
                :project-id="projectId"
                id="sfxEditLinkedDataHierarchy"
                :fields="[
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Typ',
                        id: 'type',
                        type: 'string',
                    },
                ]"
                selection-handler="setAssemblyTreeSelected"
                open-handler="setAssemblyTreeOpen"
                close-handler="setAssemblyTreeClosed"
                :initially-selected-node="$route.query.nodeId"
                :key="forceReRenderKey2"
        />

        <template slot="middleContent">
            <div class="row">
                <div class="col-12 col-md-6 col-xl-12 mb-3 mb-xl-0 float-left float-xl-none">
                    <h3 class="too-long-text-break">
                        {{ $store.getters.getAssemblyListOptions($route.params.id, 'title') }}
                    </h3>
                </div>
                <preview
                        :removable="false"
                        class="col-12 col-md-6 col-xl-12 mb-2 mt-2 float-right float-xl-none"
                        source="instance"
                        v-if="$store.getters.getAssemblyListOptions($route.params.id, 'fbxPreview')"
                        :preview-uri="$store.getters.getAssemblyListOptions($route.params.id, 'fbxPreview')"
                        :preview-id="$store.getters.getAssemblyListOptions($route.params.id, 'selected')"
                        :downloadable="true"
                />
                <download-button
                  v-if="$store.getters.getAssemblyListOptions($route.params.id, 'fbxPreview')"
                  loading-panel-classes="black"
                  class="col-12 xol-md-6 not-absolute clickable"
                  download-message=""
                  :show-stats="true"
                  :object-id="$store.getters.getAssemblyListOptions($route.params.id, 'selected')"
                  file-key="lod0.fbx"
                  >{{ $t('downloadType', {type: 'FBX'}) }}</download-button>
            </div>
        </template>

        <div class="row mb-3"
             v-if="$store.getters.getAssemblyListOptions($route.params.id, 'selected') && projectId"
             slot="middleContent"
        >
            <div :class="['collapsible mt-4 col-12', tableCollapsed ? 'is-collapsed' : '']">
                <div @click="tableCollapsed = !tableCollapsed" class="header transparent slim"><h3>{{ $t('localLinkedItems') }}</h3> <icon type="angle-down" /></div>
                <div id="linked-items" class="content">
                    <all-instances
                            :class="['w-100']"
                            v-if="$store.getters.getAssemblyListOptions($route.params.id, 'selected') && projectId"
                            :connected-instance="$store.getters.getAssemblyListOptions($route.params.id, 'selected')"
                            :id="projectId"
                            :key="forceReRenderKey + 3"
                            @clickAsset="clickAsset"
                    />
                </div>
            </div>
        </div>

        <div v-if="hasRoot && !isExtracting" slot="rightContent">
            <h3 class="mb-3 mb-xl-5">
                {{ $t('LinkedData') }}
            </h3>
            <tab-switcher
                    v-if="$store.getters.getAssemblyListOptions($route.params.id, 'selected')"
                    id="sfxLinkedDataTab"
                    :tabs="tabs"
                    @setSelected="setSelectedTab"
            />
            <!--id of view-switcher needs to be the same like name of component in order to work -->
            <div class="row mt-3 linking-part" v-if="$store.getters.getAssemblyListOptions($route.params.id, 'selected') && projectId">
                <content-filter
                        :id="id"
                        @filter="filter"
                        :type="'miniFilter'"
                        class="col-12 mb-3 linked-data-filter"
                        store-name="Assembly"
                        :org-filtering="true"
                        :org-filter-local="true"
                        :limit-to-organization-ids="[organizationId, $store.getters.getSuperOrg]"
                        v-if="organizationId && projectId"
                        :key="forceReRenderKey + 88"
                />
                <view-switcher
                        v-if="selectedAssetComponent"
                        :id="selectedAssetComponent"
                        :table="true"
                        :thumbnails="true"
                        :key="forceReRenderKey + 2"
                        class="col-4"
                />
              <div class="w-100" />
                <pagination
                        :limit="paginationLimit"
                        :total="$store.getters['get' + storeName + 'ListOptions'](listName, 'pagination_items')"
                        :id="storeName + 'pagination'"
                        @setActive="loadPaginatedData"
                        class="col-7 mt-3 text-center"
                        :key="storeName"
                        v-if="projectId"
                />
                <asset-table
                        class="col-12"
                        v-if="selectedAssetComponent"
                        :connected-instance="$store.getters.getAssemblyListOptions($route.params.id, 'selected')"
                        @selectCheckbox="clickAsset"
                        :id="listName"
                        :show-organization="true"
                        :key="forceReRenderKey + 1"
                        :name="selectedAssetComponent"
                        :organization-id="organizationId"
                        :store-name="getSelectedTab().storeName"
                        :asset-instance-limit="getSelectedTab().limit ? getSelectedTab().limit : null"
                        :table-fields="getSelectedTab().tableFields ? getSelectedTab().tableFields : []"
                        :thumbnail-fields="getSelectedTab().thumbnailFields ? getSelectedTab().thumbnailFields : []"
                        :pre-content="getSelectedTab().preContent ? getSelectedTab().preContent : null"
                        :show-add-button="getSelectedTab().showAddButton ? getSelectedTab().showAddButton : false"
                />
            </div>
        </div>
      <div v-if="hasRoot && !isExtracting" slot="fullContentAfter" class="row">
        <div class="col-12 mt-5">
          <connection-overview :key="forceReRenderKey" @deleted="connectionOverviewDeleted" v-if="projectId" :project-id="projectId" />
        </div>
      </div>
    </three-columns>
</template>

<script>
    import ContentFilter from "../components/widgets/filter/ContentFilter";
    import LazyLoadingHierchy from "../components/tree/LazyLoadingHierchy";
    import ThreeColumns from "../layouts/ThreeColumns";
    import Preview from "../components/preview/Preview";
    import TabSwitcher from "../components/widgets/TabSwitcher";
    import Pagination from "../components/Pagination";
    import ViewSwitcher from "../components/widgets/ViewSwitcher";
    import Icon from "../components/Icon";
    import AssetTable from "../components/connections/assets/AssetTable";
    import {sfxConfig} from '@/components/connections/dataLinkerConfig';
    import allInstances from "../components/connections/instances";
    import ConnectionOverview from "@/components/connections/ConnectionOverview";
    import {mapState} from "vuex";
    import Button from "@/components/forms/Button";
    import DownloadButton from "@/components/forms/DownloadButton";
    export default {
        name: "SFXLinkedData",
        components: {
            ContentFilter,
            Preview,
            ThreeColumns,
            TabSwitcher,
            LazyLoadingHierchy,
            Pagination,
            ViewSwitcher,
            Icon,
            allInstances,
            AssetTable,
            ConnectionOverview,
            Button,
            DownloadButton
        },
        data() {
            return {
                offset: 0,
                listName: this.$route.params.id + 'Instances',
                paginationLimit: 15,
                queryRelevantComponents: 2, //pagination and filter
                queryRelevantInits: 0,
                tableCollapsed: false,
                forceReRenderKey: 0,
                forceReRenderKey2: 0,
                previewPath: null,
                selected: null,
                selectedAssetComponent: '',
                connectedInstance: this.$store.getters.getAssemblyListOptions(this.id, 'selected'),
                projectId: '',
                storeName: '',
                organizationId: '',
                tabs: [],
                fbxFiltering: false,
                hasRoot: false,
                isExtracting: true,
            };
        },
        computed: mapState({AssemblyListOptions: state => state.dynamicStore.AssemblyListOptions,}),
        watch: {
            AssemblyListOptions: {
              deep: true,
              handler() {
                this.loadPaginatedData(0, false, false);
              }
            },
            queryRelevantInits (val) {
                if(val === this.queryRelevantComponents) {
                    this.loadPaginatedData(this.offset);
                }
            }
        },
        beforeMount() {
            this.id = this.$route.params.id;
            this.$store.dispatch('clientGetPipelineStatus', {id: this.$route.params.id }).then(res => {
              this.isExtracting = false;
              if(res) {
                if(res.task === 'extract') {
                  this.isExtracting = true;
                }
              }
            });

            this.loadAssembly().then(data => {
                this.projectId = data.sourceProjectId ? data.sourceProjectId : null;
                this.organizationId = data.organizationId ? data.organizationId : null;
                return this.loadProjectInstances(this.projectId);
            }).then(res => {
              this.hasRoot = !!(res && res.length);
              this.setInitialTabs();
            }).catch(e => {
              if(e.status === 401) {
                this.$router.push('/access-denied');
              }
            });
        },
        methods: {
            removeNodeId() {
              let query = Object.assign({}, this.$route.query);
              delete query.nodeId;
              this.$router.replace({ query });
              this.forceReRenderKey2++;
            },
            setInitialTabs() {
              this.tabs = sfxConfig;

              if (this.$route.query.sfxLinkedDataTab) {
                  this.setSelectedTab(this.$route.query.sfxLinkedDataTab);
              } else {
                  this.setSelectedTab(this.tabs[0].name);
              }
            },
            async loadAssembly() {
              return this.$store.dispatch('loadAssembly', {
                id: this.id,
              });
            },
            async loadProjectInstances(projectId) {
              return this.$store.dispatch('clientLoadProjectInstances', {
                id: projectId,
                filter: 'type in node model, level eq 1, parentId eq null'
              });
            },
            loadPaginatedData: function (offset, initial, markOnly = false) {
              this.offset = offset;
                let args = {};
                if (args) {
                    args.add = {
                        paging: {
                            [this.listName]: true,
                        },
                        offset: {
                            [this.listName]: offset,
                        },
                        limit: {
                            [this.listName]: this.paginationLimit,
                        },

                    };
                    this.loadDataWithParams(args, initial, markOnly);
                }
            },
            filter: function (params, initial) {
               this.loadDataWithParams(params, initial);
            },
            loadDataWithParams (params, initial, markOnly = false) {
              const selectedInstance = this.$store.getters.getAssemblyListOptions(this.$route.params.id, 'selected');
              let args = {
                listName: this.listName,
              };
              const paginationAddon = {paging: {
                  [this.listName]: true,
                },
                offset: {
                  [this.listName]: this.offset,
                },
                limit: {
                  [this.listName]: this.paginationLimit,
                }};
              if(params.add) {args.add = params.add ? {...paginationAddon, ...params.add} : paginationAddon}
              if(params.remove) {args.remove = params.remove}

                if(initial && this.storeName) {
                    this.queryRelevantInits++;
                    this.$store.dispatch(`register${this.storeName}QueryParams`, {listName: this.listName, params: args});
                }
                else if(markOnly && this.storeName) {
                  const items = this.$store.getters[`get${this.storeName}sByListName`](this.listName);
                  this.markItems(items, selectedInstance);
                }
                else if (!initial && this.storeName) {
                    this.$store.dispatch(`load${this.storeName}s`, args).then(async items => {
                      await this.markItems(items, selectedInstance);
                    });
                }
            },
            async markItems(items, selectedInstance) {
              await this.$store.dispatch(`mark${this.storeName}Unselected`, {listName: this.listName, data: items});

              await this.$store.dispatch('getProjectConnections', {id: this.projectId}).then(async connections => {
                for(let i = 0; i < items.length; i++) {
                  const item = items[i];
                  if(connections.filter(conn => {return conn.target.assetId === item.id && conn.source.id === selectedInstance}).length) {
                    await this.$store.dispatch(`mark${this.storeName}Selected`, {listName: this.listName, data: [{id: item.id}]});
                  }
                }
                this.forceReRenderKey++;
              });
            },
            setSelectedTab: function (selected) {
                this.queryRelevantInits = 0;
                let selectedTab = this.tabs.find(x => x.name === selected);
                this.selected = selected;
                this.connectedInstance = this.$store.getters.getAssemblyListOptions(this.$route.params.id, 'selected');
                if (this.selected) {
                    this.selectedAssetComponent = selectedTab.assetComponent;
                    this.storeName = selectedTab.storeName;
                    this.loadPaginatedData(0, false);
                }
            },
            clickAsset: function (linkedDataId, operation) {
                let instanceId = this.$store.getters.getAssemblyListOptions(this.$route.params.id, 'selected'); //id of instance in hierarchy
                let listName = instanceId + 'Instances';
                this.$log.warn(instanceId + ' ' + this.projectId + ' ' + linkedDataId);
                if (instanceId && this.projectId && linkedDataId) {
                    if (operation === 'add') {
                        this.$store.dispatch('createNotification', {'text': this.$t('addconnection')});
                        let args2 = {
                            args: [{
                              assetId: linkedDataId,
                            }],
                            id: this.projectId,
                            listName: listName,
                        };

                        this.$store.dispatch('clientCreateProjectInstances', args2).then(data => {
                            let targetId = data[0].id;
                            if (instanceId) {
                                let args = {
                                    id: this.projectId,
                                    listName: listName,
                                    args: [{
                                        targetId: targetId,
                                        sourceId: instanceId,
                                        name: "sfxDatalink",
                                    }],
                                };
                                this.$store.dispatch('createProjectInstanceConnections', args).then(() => {
                                    //setDatasourceProperty
                                  this.tableSetUnselected(linkedDataId, true);
                                    // this.increaseTreeItemSources();
                                    this.forceReRenderKey++;
                                });
                            }
                        });
                    } else if (operation === 'remove') {
                        this.$store.dispatch('createNotification', {'text': this.$t('removeconnection')});
                        /*
                        * linkedDataId = assetId in the left table
                        * */
                        let args2 = {
                            id: this.projectId,
                            cid: instanceId,
                            filter: 'targetId eq ' + linkedDataId,
                            include: 'instanceAndAsset',
                        };
                        this.$store.dispatch('getProjectInstanceConnections', args2).then(data => {
                            let connectionId = null;
                            for (let i = 0; i < data.length; i++) {
                                let connection = data[i];
                                if (connection.source && connection.source.assetId && connection.source.assetId === linkedDataId) {
                                    connectionId = connection.id;
                                    break;
                                }
                                else if (connection.target && connection.target.assetId && connection.target.assetId === linkedDataId) {
                                    connectionId = connection.id;
                                    break;
                                }
                            }
                            let deletionArgs = {
                                id: this.projectId,
                                args: [connectionId]
                            };
                            this.$store.dispatch('deleteProjectInstanceConnections', deletionArgs).then(() => {
                                this.tableSetUnselected(linkedDataId, false);
                                // this.decreaseTreeItemSources();
                                this.forceReRenderKey++;
                            });
                            this.forceReRenderKey++;
                        });
                    }
                }
            },
            tableSetUnselected(assetId, bool = false) {
              const dispatchMethod = bool ? `mark${this.storeName}Selected` : `mark${this.storeName}Unselected`;
              this.$store.dispatch(dispatchMethod, {
                listName: this.listName,
                data: [{
                  id: assetId,
                }]
              });
            },
            connectionOverviewDeleted() {
                this.loadPaginatedData(0);
                this.forceReRenderKey++;
            },
            decreaseTreeItemSources() {
                this.$store.dispatch('decreaseAssemblyTreeSourceList', {
                    id: this.$store.getters.getAssemblyListOptions(this.$route.params.id, 'selected'),
                    listName: this.listName,
                });
            },
            increaseTreeItemSources() {
                this.$store.dispatch('increaseAssemblyTreeSourceList', {
                    id: this.$store.getters.getAssemblyListOptions(this.$route.params.id, 'selected'),
                    listName: this.listName,
                });
            },
            getSelectedTab: function() {
                return this.tabs.find(x => x.name === this.selected);
            },
        },
    }
</script>
<style lang="scss">
    .linked-data-filter {
        input {
            width: 100%;
        }
    }
    .toggler {
        cursor: pointer;

        i {
            -webkit-transition: all 300ms ease;
            transition: all 300ms ease;
        }


        &.open {
            i {
                -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
            }
        }

    }

    .collapsed {
        height: 0;
        overflow: hidden;
    }

    .linking-part > .pagination {
        ul {
            margin: 0 !important;
            float: right;
        }
    }
    #linked-items {
      table td.field-id-name, table th.field-id-name {
        width: auto;
      }
    }

</style>