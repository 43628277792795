<template>
<div class="mt-2 mb-2 separator">
</div>
</template>

<script>
export default {
  name: "Separator",
  props: {
    config: {type: Object, required: true},
    lang: {type: String, required: true}
  },
  methods: {
    validate() {
        return true;
    },
    checkRequired() {
      return true;
    },
    getLogEntries() {
        return [];
    }
  }
}
</script>

<style scoped>
  .separator {
    width: 100%;
    border-bottom: 2px solid black;
  }
</style>