<template>
        <tr v-if="!checkingPermission" :id="item.id" :class="[navByClickingRow && hasEditPermission ? 'hoverRow' : '', !hasEditPermission ? 'no-access' : '', fullRowSelectable ? 'hoverRowHighlight' : '']" @click="(e) => { fullRowSelectable ? clickCheckBox(e, item.id) : null }">
            <popup
                    @close="() => {showDeletePrompt = false; unlinkAbort();}"
                    v-if="showDeletePrompt || showUnlinkPrompt"
            >
                <delete-prompt
                        v-if="showDeletePrompt"
                        slot="popupMainContent"
                               @abort="() => {showDeletePrompt = false; this.toBeDeletedId = null;}"
                               @confirm="() => {showDeletePrompt = false; deleteRowFinally();}"
                />
                <delete-prompt
                        v-if="showUnlinkPrompt"
                        slot="popupMainContent"
                        custom-message="unlinkPromptQuestion"
                        @abort="unlinkAbort"
                        @confirm="() => {showUnlinkPrompt = false; clickCheckBoxFinally();}"
                />
            </popup>
            <td v-if="addPlaceholderStart"></td>
            <td class="selectLinking" v-if="canUnlink"
                v-on="canUnlink ? { click: (e) => {e.stopPropagation(); $emit('unlink', item)}} : {}"
            >
                <icon type="times"/>
            </td>
            <td :class="selectableStyle === 'default' ? 'selectLinking' : ''" v-if="selectableCheckbox"
                v-on="selectableCheckbox ? { click: (e) => {clickCheckBox(e, item.id ? item.id : item, item.selected ? item.selected : false);}} : {}"
            >
                <div v-if="selectableStyle === 'default'" :class="[item.selected ? 'selected' : '']"
                >
                    <icon v-if="!item.selected" type="unlink"/>
                    <icon v-else type="link"/>
                </div>
                 <div v-if="selectableStyle === 'button'" :class="[item.selected ? 'selected' : '']"
                >
                    <Button v-if="!item.selected">{{ $t("link") }}<icon type="link"/></Button>
                   <Button v-else>{{ $t("unlink") }}<icon type="unlink"/></Button>
                </div>
            </td>
            <td  :class="[field.type, 'field-id-' + field.id]" :key="index" @click.self="() => {if(hasEditPermission && navByClickingRow) {$router.push(item.editLink)}}" v-for="(field, index) in fields">
                <div v-if="field.type === 'image'" @click.self="() => {if(hasEditPermission && navByClickingRow) {$router.push(item.editLink)}}">
                    <preview
                            class="table-thumbnail"
                            :asset-or-project-id="item.id"
                            :source="resource"
                            :preview-uri="item.previewUri ? item.previewUri : item.profilePictureUri"
                            :type="'image'"
                            :preview-id="item.id"
                            :limited-preview="true"
                            :removable="false"
                    />
                </div>
                <div v-else-if="field.type === 'boolean'">
                  <icon :type="item[field.id] ? 'check' : 'times'" />
                </div>
                <div v-else-if="field.id === 'teams'">
                  <div class="tag" :key="index" v-for="(team, index) in item.teams"><icon class="mr-1" type="users" />{{ team.displayName ? team.displayName : team.name }}</div>
                </div>
                <div v-else-if="field.type === 'qrcode' || field.id === 'qrcode'">
                    <qrcode-view
                            class="table-thumbnail"
                            :source="item.type"
                            :id="item.id"
                    />
                </div>
                    <template v-else-if="field.type === 'color'">
                        <div :class="[colorPickrId === item.id ? 'selected' : '', 'color-preview', hasEditPermission ? 'editable' : '', !colorPickrId ? '' : 'hover']" @click.self="() => {colorPickrId = item.id; }" :style="{backgroundColor: (item[field.id] ? item[field.id] : '#fff')}">
                            <color-picker
                                    v-if="colorPickrId === item.id && hasEditPermission"
                                    ref="colorpickr"
                                    slot="popupMainContent"
                                    :value="item[field.id]"
                                    @save="updateColor"
                                    @close="() => {colorPickrId = null;}"
                                    :popup-style="true"
                            />
                        </div>
                    </template>
                <div class="preview table-thumbnail" v-else-if="field.type === 'icon'">
                        <div class="square-image lighter">
                            <icon v-if="field.virtual" size="small" :type="item.type"/>
                            <icon v-else-if="!field.virtual" size="small" :type="item.type"/>
                        </div>
                </div>
                <div v-else-if="field.type === 'editButton'">
                    <Button @click="$emit('edit', item.id)" v-if="hasEditPermission && !hideEditButton"
                            :link="item.editLink" icon="pencil-alt">
                        {{ $t('edit') }}
                    </Button>
                </div>

                <Button
                    v-else-if="field.type === 'editPopup'"
                    @click="$emit('edit', item.id)"
                    icon="pencil-alt">
                    {{ $t('edit') }}
                </Button>
                <div class="name-field" @click="() => {if(hasEditPermission && navByClickingRow) {$router.push(item.editLink)}}" v-else-if="field.id === 'name' && item[field.id] && item.editLink">
                  <state-display class="float-right ml-4" v-if="showState" :state="item.state" />
                    <div class="lighter" v-if="item.organizationId && showOrganization">
                        <icon type="folder" v-if="hasEditPermission && navByClickingRow" /><icon v-else-if="navByClickingRow" type="lock" /> {{ $store.getters.getOrganizationDisplayName(item.organizationId) }}
                    </div>
                    <router-link
                            class="table-row-title"
                            v-if="$store.getters.isSuperAdmin"
                                 :to="item.editLink">
                        <div class="overflow-text ">
                            <div :class="[item[field.id] && item[field.id].length > 60 ? 'shorttext' : '']">
                                {{ item[field.id].slice(0, 60) }}
                                <span v-if="item[field.id] && item[field.id].length > 60">...</span>
                                </div>
                            <div class="longtext" v-if="item[field.id] && item[field.id].length > 30">{{ item[field.id] }}</div>
</div>
                    </router-link>
                    <div class="table-row-title" v-else>
                        {{ item[field.id] }}
                    </div>
                    <div v-if="item.tags && showTags">
                        <tag v-for="value in item.tags" :key="value.id" :tag="value" :is-removable="false" :clickable="false" />
                    </div>
                    <div class="mt-3 lighter table-row-instances" v-if="item.instances && item.instances.length && showInstances">
                        <h6>{{ $t('instances') }}</h6>

                        <div :key="index2" v-for="(instance, index2) in item.instances">
                            <div class="mt-1">
                                <router-link class="clickable withHoverBrightBackground p-1 d-block" target="_blank" :to="'/project/' + instance.projectId + '/linked_data?'">
                                    <icon class="mr-2 mt-1" size="0.9" type="angle-right" />
                                    <content-display
                                            class="link-name"
                                            :show-inline="true"
                                            list-name="tableRowInstanceNames"
                                            type="project"
                                            :value="instance.projectId"
                                    />
                                </router-link>
                                </div>
                        </div>
                    </div>
</div>
                <span v-else-if="field.type === 'date'">
                    {{ $store.getters.convertDate(item[field.id]) }}
                </span>
                <span v-else-if="field.type === 'state'">
                    <toggle-button v-if="hasEditPermission" :value="item[field.id] === 'a'"
                                   color="#DA5E06"
                                   :sync="true"
                                   :labels="true"
                                   @change="$emit('updateContent', {
                                            id: item.id,
                                            state: $event.value?'a':'i'
                                    })" />
                </span>
                <span v-else-if="field.type === 'teamvisibility'">
                    {{ $t('teamVisibilityTypes.' + item[field.id]) }}
                </span>
                <span v-else-if="field.type === 'orgvisibility'">
                    {{ $t('orgVisibilityTypes.' + item[field.id]) }}
                </span>
                <span v-else-if="field.translate">
                    {{ field.translateFn?$t(field.translateFn(field, item)):$t(item[field.id]) }}
                </span>
                <span v-else-if="field.type === 'tag'">
                    <tag :clickable="false" :is-removable="false" :tag="{id: item.id, name: item.name, color: item.color}" />
                </span>
                <span v-else>
                    {{ item[field.id] }}
                </span>
            </td>
            <td v-if="selectableRows"
                v-on="selectableRows ? { click: () => $emit('selectRow', {id: item.id, assetId: item.assetId ? item.assetId : item.id, asset: item})} : {}">
                <div class="rowSelector">
                    <icon type="eye"/>
                </div>
            </td>
            <td class="buttonField" v-if="deletable"
            >
                <button-field
                        v-if="hasDeletePermission"
                    :deletable="deletable && hasDeletePermission"
                    :item-id="item.id"
                        :deleting="!!toBeDeletedId"
                    @deleteRow="deleteRow(item.id)"
                />

            </td>
        </tr>
</template>

<script>
    import Button from "../forms/Button";
    import Icon from "../Icon";
    import Preview from "../preview/Preview";
    import ButtonField from "../forms/ButtonField";
    import QrcodeView from "./QrcodeView";
    import Popup from "../Popup";
    import DeletePrompt from "../forms/DeletePrompt";
    import Tag from "../Tag";
    import ColorPicker from "./editors/ColorPicker";
    import ContentDisplay from "./editors/ContentDisplay";
    import StateDisplay from "@/components/StateDisplay";
    import {OrganizationVisibility} from '../../enum';

    export default {
        name: 'TableRow',
        components: {
            Button,
            Icon,
            Preview,
            ButtonField,
            QrcodeView,
            Popup,
            DeletePrompt,
            Tag,
            ColorPicker,
            ContentDisplay,
            StateDisplay
        },
        props: {
            fields: {type: Array, required: true,},
            item: {type: Object, required: true},
            deletable: {type: Boolean, default: false,},
            resource: {type: String, default: 'asset'},
            showOrganization: {type: Boolean, default: false},
            showState: {type: Boolean, default: false},
            editPermission: {type: String, default: null},
            selectableCheckbox: {type: Boolean,},
            canUnlink: {type: Boolean, default: false},
            showTags: {type: Boolean, default: false},
            selectableRows: {type: Boolean,},
            navByClickingRow: {type: Boolean, default: false,},
            deletePermission: {type: String, default: null},
            /**
             * todo: rename variable
             * @selectableRows Boolean Whether or not to make row-preview selectable
             */
            fullRowSelectable: {type: Boolean, default: false},

            showInstances: {type: Boolean, default: false},

             /**
             * @permissionByType If there are multiple permissions per type, use this one
             */
            permissionByType: {type: Object, default: () => {}},
            /***
            * Adds an empty td item at the beginning
            * */
            addPlaceholderStart: {type: Boolean, default: false},
            /***
            * How the selectableCheckbox should be styled
            * */
            selectableStyle: {type: String, default: "default"},
            hideEditButton: {type: Boolean, default: false}
        },
        data() {
            return {
                OrganizationVisibility: OrganizationVisibility,
                showDeletePrompt: false,
                showUnlinkPrompt: false,
                deleteConfirmed: false,
                toBeDeletedId: '',
                clickedCheckBoxId: '',
                clickedCheckBoxAction: '',
                colorPickrId: null,
                hasEditPermission: false,
                hasDeletePermission: false,
                processing: false,
                checkingPermission: true,
                deleteLoadingState: false
            }
        },
      watch: {
          item: {
            deep: true,
            handler: function() {
              this.checkPermission();
            }
          }
      },
        mounted() {
            this.checkPermission();
        },
        methods: {
            async checkPermission() {
              this.checkingPermission = true;
              let editDispatchMethod = 'checkTeamOrgPermission';
              let deleteDispatchMethod = 'checkTeamOrgPermission';
              if(this.editPermission === 'userIsOrganizationMember') {
                deleteDispatchMethod = editDispatchMethod = 'userIsOrganizationMember';
              }
              this.hasEditPermission = await this.$store.dispatch(editDispatchMethod, {
                  teams: await this.$store.getters.checkIsTeamPermission(this.editPermission) ? [{id: this.item.id}] : this.item.teams,
                  op: this.permissionByType && this.permissionByType[this.item.type] ? this.permissionByType[this.item.type] : this.editPermission,
                  organizationId: this.item.organizationId
                })

                this.hasDeletePermission = await this.$store.dispatch(deleteDispatchMethod, {
                  teams: await this.$store.getters.checkIsTeamPermission(this.deletePermission) ? [{id: this.item.id}] : this.item.teams,
                  op: this.deletePermission,
                  organizationId: this.item.organizationId
                })

              this.checkingPermission = false;
            },
            clickCheckBox: function(e, id, selected/*, item*/) {
                if(!this.processing) {
                    this.processing = true;
                    e.stopPropagation();
                    this.clickedCheckBoxId = id;
                    this.clickedCheckBoxAction = selected ? 'remove' : 'add';
                    if(selected) {
                        this.showUnlinkPrompt = true;
                    }
                    else {
                        this.clickCheckBoxFinally();
                    }
                }
            },
            clickCheckBoxFinally: function() {
                this.$emit('selectCheckbox', this.clickedCheckBoxId, this.clickedCheckBoxAction, this.item);
                let $this = this;
                setTimeout(function(){
                    $this.processing = false;
                }, 800);
                this.clickedCheckBoxAction = '';
                this.clickedCheckBoxId = '';
            },
            unlinkAbort: function() {
                this.showUnlinkPrompt = false;
                this.processing = false;
                this.clickedCheckBoxAction = '';
                this.clickedCheckBoxId = '';
            },
            updateColor: function(color) {
                let id = this.colorPickrId;
                this.colorPickrId = null;
                this.$emit('updateColor', {id: id, color: color});
            },
            rowIsClickable: function() {
                if(this.navByClickingRow && (!this.editPermission || this.hasEditPermission)) {
                    return true;
                }
            },
            deleteRow: function(id) {
                this.showDeletePrompt = true;
                this.toBeDeletedId = id;
            },
            deleteRowFinally: function() {
                this.$emit('deleteRow', this.toBeDeletedId);
                this.deleteConfirmed = false;
                this.toBeDeletedId = null;
            },
        }
    }
</script>
