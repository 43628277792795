<template>
<div>
  <iframe src="https://google.ch" />
</div>
</template>

<script>
import UtilsMixinJs from "@/components/vForm/viewer/UtilsMixin.js";

export default {
  name: "WEBSITE",
  mixins: [UtilsMixinJs],
  props: {
    config: {type: Object, required: true},
    lang: {type: String, required: true}
  },
}
</script>

<style scoped>
  iframe {
    width: 100%;
  }
</style>