<template>
  <div>
    <div class="container-fluid">
      <div class="connect-info p-3" v-if="$store.getters.getConnectStatus === 'error'">
        {{ $t('ConnectionError') }}
      </div>
      <div class="connect-info p-3" v-if="$store.getters.getConnectStatus === 'waiting'">
        {{ $t('ConnectionWaiting') }}
      </div>
      <div v-if="loading">
        <div class="loading-screen">
          <loading-spinner />
        </div>
      </div>
      <div :class="['app-inner row', environment]" v-else-if="currentUserId">
        <notification/>
        <div class="col-12 col-md-3 main-nav p-3" v-if="!$route.query.noNav">
          <router-link to="/">Home</router-link>
        </div>
        <div :class="[!$route.query.noNav ? 'col-12 col-md-8' : 'col-12 col-md-12']">
          <slot v-if="globalOrganizations && Object.keys(globalOrganizations).length" /><!--this is where the router view goes-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Notification from '@/components/Notification';
import {mapState} from 'vuex';
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  name: "Page",
  components: {
    Notification,
    LoadingSpinner,
  },
  props: {
    appHost: {type: String, default: null}
  },
  data() {
    return {
      tempURL: '',
      environment: '',
      userId: null,
      hasOrgs: false,
    };
  },
  computed:
      mapState({
        loading: state => state.users.loading,
        currentUserId: state => state.users.currentUserId,
        globalUser: state => state.users.globalUser,
        globalOrganizations: state => state.organization.globalOrganizations,
        globalTeams: state => state.dynamicStore.globalTeams,
        login: state => state.vStage.login.param1,
        project: state => state.vStage.project
      }),
  watch: {
    globalUser(user) {
      if(!this.userId && user.id) {
        this.userId = user.id;
        this.loadOrganizations()
          .then(() =>{
            return this.loadTeams();
          });
      }
     },
    currentUserId(id) {
      if(this.userId && id === null) {
        // null means logout
        this.userId = null;
      }
    },
    project() {
      console.log("got project")
      console.log(this.project)
      if(this.project.param1) {
        console.log(this.$route.query);
        const query = this.$route.query ? Object.keys(this.$route.query).map(key => {return key + "=" + this.$route.query[key]}) : "";
        this.$router.push("/" + this.project.param1 +"/vform?" +query);
      }
    },
  },
  created: function () {
    window.addEventListener('keyup', this.historyBack)
  },
  beforeMount() {
    this.$store.dispatch('checkFilterSettings');
    this.$store.dispatch('checkPageViewSwitcher', {routeName: this.$route.name, id: 'mainContent'});
    //todo: remove when interacting with vStage
      /*this.$store.dispatch("vStageCommunicate", JSON.stringify({
        realm: "login",
        command: "login",
        param1: "08f1c9de-173c-44a2-a889-c85d2a91d2c5"
      }));
      this.$store.dispatch("vStageCommunicate", JSON.stringify({
        realm: "project",
        command: "project",
        param1: "78d98e7f-a43a-4d40-8f3d-3e0c5b770dca"
      }));*/
      /*this.$store.dispatch("vStageCommunicate", JSON.stringify({
        realm: "login",
        command: "login",
        param1: "d54ad4d2-888e-48fd-b665-23108dc0bcdd"
      }));
      this.$store.dispatch("vStageCommunicate", JSON.stringify({
        realm: "project",
        command: "setProjectId",
        param1: "a1797778-b85a-48a1-96ea-faee4ba441c0"
      }));*/
      this.$store.dispatch("communicateWithIFrame", {
                  host: this.appHost,
                  message: {
                    "command":"ccLightReady",
                    "param1":1
                  }
                })
  },
  methods: {
    async loadOrganizations() {
      return this.$store.dispatch('loadOrganizations', {
        ignoreIndex: true,
        add: {
          limit: {
            default: 800,
          },
        },
        listName: 'globalOrganizations'
      });
    },
    async loadTeams() {
      return this.$store.dispatch('loadTeams', {
         ignoreIndex: true,
          add: {
            limit: {
              default: 15500,
            }
          },
          listName: 'globalTeams'
        });
    },
    historyBack(e) {
      const el = document.activeElement;
      if (el.id && el.id === 'vhub' && e.keyCode === 8) {
        this.$router.go(-1);
      }
    },
  },
}
</script>

<style lang="scss">
.language-switch-front {
  position:fixed;
  top:0;
  right:15px;
  z-index:10;
}
.environment {
  position:fixed;
  top:0;
  left:50%;
  -webkit-transform:translateX(-50%);
  transform:translateX(-50%);
  width: 250px;
  height:45px;
  font-size: 1.1rem;
  text-transform:uppercase;
  z-index:500;
  .text {
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }
  .spickel {
    right: -25px;
    left:auto;
    top:0;
    width:50px;
    background-color: $stagingColor;
    -webkit-transform: skew(-26deg, 0deg);
    transform: skew(-26deg, 0deg);
  }
  .spickel2 {
    left: -30px;
    top:0;
    width:50px;
    height:100%;
    background-color: $stagingColor;
    -webkit-transform: skew(-26deg, 0deg);
    transform: skew(-26deg, 0deg);
    position:relative;
  }
  background-color: $stagingColor;
  display:none;
  &.staging {
    display:block;
    background-color: $stagingColor;
    .spickel, .spickel2 {
      background-color: $stagingColor;
    }
  }
  &.demo {
    display:block;
    background-color: $demoColor;
    .spickel, .spickel2 {
      background-color: $demoColor;
    }
  }
}
.connect-info {
  position:fixed;
  bottom:25px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: $highlight;
  z-index:18;
}
</style>