<template>
<div>
  HOME!
</div>
</template>

<script>
export default {
  name: "Home.vue"
}
</script>

<style scoped>

</style>