<template>
    <div class="row">
        <div class="col-12 col-md-8 mb-3">
            <div v-if="!titleEdit" class="title-edit">
                <h1 class="inline-block mr-2"><icon class="mr-2" size="0.9" v-if="icon" :type="icon" />{{ value }}</h1>
                <div class="edit-trigger mb-1 lighter" @click.prevent="editTitle">
                    <icon :type="'edit'"/>
                </div>
            </div>
            <div v-else>
                <input :class="['form-text', error ? 'form-group--error' : '']"
                       ref="name"
                       type="text"
                       @keyup.enter="updateContent"
                       @keyup.esc="cancelEditing"
                       v-model="updateValue"
                       @input="delayTouch($v.updateValue)"
                >
                <div class="form-error" v-if="error">{{ $t(error) }}</div>
                <div class="form-error" v-if="$v.updateValue.$dirty && !$v.updateValue.required && $v.updateValue.$error">{{ $t('errors.required') }}</div>
                <div class="form-error" v-if="$v.updateValue.$dirty && !$v.updateValue.alphaNumSpace">{{ $t('errors.alphaNumSpaceOnly') }}</div>

                <div class="form-error" v-if="$v.updateValue.$dirty && !$v.updateValue.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>

                <div class="form-error" v-if="$v.updateValue.$dirty && !$v.updateValue.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
                <div class="form-error" v-if="$v.updateValue.$dirty && !$v.updateValue.isUnique">{{ $t('errors.objectNameAlreadyExists') }}</div>

                <div class="edit-trigger mr-2" @click="cancelEditing">
                    <icon :type="'times'"/>
                </div>
                <div v-if="enableSaving" class="edit-trigger" @click="updateContent">
                    <icon :type="'save'"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from "@/components/Icon";
    import { required, minLength, maxLength } from 'vuelidate/lib/validators';
    import {alphaNumSpace} from '../customValidators';
    import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";

    export default {
        name: "ContentTitleBlock",
        components: {
            Icon,
        },
        mixins: [VuelidateMixin],
        props: {
            value: {type: String, default: ''},
            id: {type: String, required: true},
            storeName: {type: String, required: true},
            organizationId: {type: String, default: ''}, //necessary in most cases /except users
            icon: {type: String, default: null},
        },
        data() {
            return {
                titleEdit: false,
                error: null,
                updateValue: null,
                enableSaving: true,
            };
        },
        validations: {
            updateValue: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(127),
                alphaNumSpace,
                async isUnique(value) {
                    // standalone validator ideally should not assume a field is required
                    if (value === '' || value === this.value) {
                        return true;
                    }
                    let bool = true;
                    let args = {value: value, name: value};
                    if(this.organizationId) {
                        args.organizationId = this.organizationId;
                    }
                    await this.$store.dispatch('checkIf' + this.storeName + 'NameExists', args).then(data => {
                        bool = data.length === 0;
                    });
                    return bool;
                },
            },
        },
        watch: {
            value(val) {
                if(val) {
                    this.updateValue = this.value;
                }
            },
            updateValue() {
                this.error = null;
            },
            '$v.$invalid': function() {
                this.enableSaving = !this.$v.$invalid;
                this.$emit('enableSaving', !this.$v.$invalid);
            },
        },
        beforeMount() {
            this.updateValue = this.value;
        },
        methods: {
            editTitle: function () {
                this.titleEdit = true;
            },
            cancelEditing: function () {
                this.titleEdit = false;
                this.updateValue = this.value;
            },
            updateContent() {
                if(this.enableSaving) {
                    let args = {
                        id: this.id,
                        name: this.updateValue,
                    };
                    this.error = null;
                    this.$store.dispatch('update' + this.storeName, args).then(() => {
                        if (!this.error) {
                            this.titleEdit = false;
                        }
                        this.$emit('updated');
                    }).catch(res => {
                        if (res.response.errors.errorCode === 1003) {
                            this.error = this.$t('errors.duplicateNameForAsset');
                        }
                        else {
                          this.error = res.response.errors.message;
                        }
                    })
                }
                else if(this.updateValue === this.value) {
                    this.titleEdit = false;
                }

            }
        },
    }
</script>

<style lang="scss" scoped>
    .title-edit {
        position:relative;
        word-break: break-all;
        .edit-trigger {
            position:absolute;
            margin-top:-3px;
            top:50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }

    }
    .form-text {
        width:100%;
    }
</style>