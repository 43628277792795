import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/store/store';
import i18n from '@/translation';
import BootstrapVue from 'bootstrap-vue';
import VueCookies from 'vue-cookies';
import underscore,{_} from 'vue-underscore';
import VueLogger from 'vuejs-logger';
const options = {
  isEnabled: true,
  logLevel :  (process.env.NODE_ENV === 'production')? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
};
Vue.use(VueLogger, options);
Vue.use(require('vue-moment'));

import moment from 'moment-timezone'
let defTZ = moment.tz.guess();
moment.tz.setDefault(defTZ);

import VueDraggable from 'vue-draggable'
Vue.use(VueDraggable);

import UUID from "vue-uuid";
Vue.use(UUID);
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(underscore);
Vue.use(BootstrapVue);
Vue.use(VueCookies);
VueCookies.config('7d');
Vue.config.productionTip = false;

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import PortalVue from 'portal-vue';
Vue.use(PortalVue);

import '@/apps/ccLight/scss/global.scss';

Vue.prototype.$hideAllNavs = true;
Vue.prototype.$hideButtonBar = true;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');

_.mixin({
  extendNonNull: function(obj) {
    _.each(_.rest(arguments,1), function(source) {
      if (source) {
        for (var prop in source) {
          if( _.isObject(source[prop]) && _.isObject(obj[prop])){
            obj[prop] = _.extendNonNull(obj[prop], source[prop]);
          }
          else if( !_.isNull(source[prop])){
            obj[prop] = source[prop];
          }
        }
      }
    });
    return obj;
  }
});
