<template>
    <div>
        <!--IMAGEDIX-->
        <div
            @click="selectImage(stepNo, index3)"
            style="cursor: pointer"
            v-if="element.currentLang === key"
            
            :key="index3 + 1000000000 + forceReRenderKey"
            v-for="(key, index3) in Object.keys(imageDix)"
        >
            <preview
                :class="previewSize + '-thumbnail mb-1'"
                :asset-or-project-id="imageDix[key]"
                :preview-uri="$store.getters['getMediaPreviewUri'](imageDix[key])"
                :type="'image'"
                :preview-id="imageDix[key]"
                :removable="false"
            ></preview>
        </div>
        <popup
            @close="
                () => {
                    showSelect = false;
                }
            "
            v-if="showSelect"
        >
            <div slot="popupMainContent" v-if="showSelect" class="">
                <div class="col row mt-4">
                    <!-- <h2>{{ $t("image") }}</h2> -->
                    <!--FOR EACH TRANSLATION ONE FIELD-->
                    <div
                        v-if="element.currentLang === key"
                        class="col-12"
                        :key="index3 + 1000000000 + forceReRenderKey"
                        v-for="(key, index3) in Object.keys(imageDix)"
                    >
                        <div class="dflex">
                            <div>
                                <content-display
                                    class=""
                                    list-name="generalList"
                                    type="media"
                                    :value="imageDix[key]"
                                    :show-preview="true"
                                />
                            </div>
                            <div class="ml-2 mt-4">
                                <Button
                                    class="icon-button"
                                    @click="
                                        imageDix[key] = '';
                                        forceReRenderKey++;
                                        showSelect = false;
                                    "
                                >
                                    <icon type="trash-alt" />
                                </Button>
                            </div>
                            <content-list
                                v-if="editImage === stepNo + '/' + index3"
                                @save="
                                    (id) => {
                                        imageDix[key] = id;
                                        editImage = null;
                                        showSelect = false;
                                        loadImage();
                                    }
                                "
                                :mediaCategory="3"
                                type="media"
                                :organization-id="organizationId"
                                listName="vformImages"
                                :show-preview="true"
                                :thumbnail-view="true"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </popup>
    </div>
</template>

<script>
import Icon from "@/components/Icon";
import Button from "@/components/forms/Button";
import ContentList from "@/components/widgets/editors/ContentList";
import ContentDisplay from "@/components/widgets/editors/ContentDisplay";
import Popup from "@/components/Popup";
import Preview from "@/components/preview/Preview";

export default {
    name: "vFormImageDixCell",
    components: {
        Icon,
        Button,
        ContentList,
        ContentDisplay,
        Popup,
        Preview,
    },
    props: {
        imageDix: { type: Object, required: true },
        element: { type: Object, required: true },
        previewSize:{ type: String, required: false, default: 'small' },
    },
    data() {
        return {
            forceReRenderKey: 0,
            editImage: null,
            showSelect: false,
        };
    },
    beforeMount() {
        console.log("img" + this.imageDix[this.element.currentLang]);
        this.loadImage();
    },
    methods: {
        loadImage() {
            if (this.imageDix[this.element.currentLang]) {
                this.$store.dispatch("loadMedia", {
                    id: this.imageDix[this.element.currentLang],
                });
            }
        },
        addImgLang(lang) {
            this.imageDix[lang] = "";
            this.forceReRenderKey++;
        },
        selectImage(stepNo, index) {
            console.log(stepNo);
            console.log(index);
            this.editImage = stepNo + "/" + index;
            this.showSelect = true;
            this.forceReRenderKey++;
        },
    },
};
</script>

<style scoped>
.small-thumbnail {
    width: 40px;
}
</style>
