import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

//Perhaps replace with Vuex-Version: https://www.npmjs.com/package/vuex-i18n (not really like a store at all...)

import gdpr_de from './assets/texts/gdpr_de.txt';
import gdpr_en from './assets/texts/gdpr_en.txt';

import saas_agb_en from './assets/texts/saas_agb_en.txt';
import saas_services_en from './assets/texts/saas_services_en.txt';

/**
 * Translations
 * */
const messages = {
    en: {
        NoneSelected: 'None selected',
        teamsNotMatching: 'Be aware that this item is not available for all teams. Missing team: ',
        addLoggingTable: 'create logging table',
        hasNextButton: 'has next button',
        addStep: 'add step',
        emphasized: 'emphasized',
        showAsTiles: 'show as tiles',
        isUserIdentifierHint: 'For the data of this form to be logged there must be exactly one field that identifies the user. Add a short answer field and set its user identifier property to true.',
        isUserIdentifier: 'identifies user',
        validateCorrectness: 'validate correctness',
        isRequired: 'is required',
        projectFilesNotAvailable: 'The project data for this project are not on the server. Please push your local project to the server and then try again.',
        vFormProjectHint: 'A vForm references slides from a project. Therefore it is not recommended to switch or remove the project because the references will be invalid and have to be changed to slides of the new project. One vForm can only be connected to one single project.',
        addTranslation: 'Add translation',
        editFile: 'edit',
        duplicateFile: 'copy',
        createUpdateDefaultJson: 'create/update default json',
        TileButtons: {
            webapp: 'Webapp',
            app: 'App',
            node: 'Node',
            material: 'Material',
            filter: 'ExportProfile',
            text: 'Text',
            model: '3D-Element',
            form: 'vForm',
            qrcode: 'QR-Code',
            report: 'Report',
            sensor: 'Sensor',
            feed: 'Feed',
            scenery: 'Scene',
            assembly: '3D-Group',
            link: 'Link',
            media: 'Media',
            helper: 'vHelper'
        },
        LoadStats: 'Load Stats',
        importAssetViaCSV: 'Import assets via CSV',
        importLog: 'Import log',
        gotIt: 'Got it',
        cookieNotification: 'By logging in you accept the use of cookies. The application will not work without the' +
            ' use of cookies.',
        gdpr_link: 'Data protection declaration',
        Accept: 'Accept',
        Deny: 'Deny',
        gdprExplanation: 'By clicking "accept" you accept the data protection declaration above.<br />When you click' +
            ' "deny" you will be logged out automatically.',
        gdprHint: 'To use vHub you need to accept the following data protection declaration:',
        gdpr: gdpr_en,
        saas_agb: saas_agb_en,
        saas_services: saas_services_en,
        RowLifetime: 'Row Lifetime',
        Days: 'Days',
        Hours: 'Hours',
        Minutes: 'Minutes',
        Seconds: 'Seconds',
        usePicker: 'Use picker tool',
        manualEntry: 'Manual entry',
        noFilterData: 'There is no data in this column to be filtered',
        pwdConditions: {
            atLeastOneUppercaseLetter: 'One uppercase letter',
            atLeastOneDigit: 'One digit',
            atLeastOneSpecialCharacter: 'One special character: {characters}',
            atLeastCharacters: 'Length between {min} and {max} characters',
        },
        repeatPassword: 'Repeat password',
        showPassword: 'Show Password',
        selectVM: 'Select Virtual Machine',
        LoadStorageUsage: 'Load storage usage',
        StorageUsage: 'storage usage',
        stopFeed: 'Stop feed',
        startFeed: 'Start feed',
        restartFeed: 'Restart feed',
        getFeedStatus: 'Get feed status',
        uploadvStageProject: 'Upload a project from vStage first in order to link it here',
        noDataYet: 'No data yet?',
        permissions: {
            logs: 'Logs',
            organization: 'Organization',
            general: 'General',
            form: 'Form',
            user: 'User',
            metaset: 'MetaSet',
            team: 'Team'
        },
        a: 'active',
        h: 'hidden',
        p: 'processing',
        i: 'inactive',
        m: 'maintenance',
        createNewAssetBundle: 'Create Asset Bundle',
        UseMetaSetValuesForPivots: 'Use the values for pivots set in meta set: sfx.asset.transform (3D Transforms)',
        DefinePivotCorrections: 'Define pivot corrections',
        keepOriginalNames: 'Maintain original names',
        centerPivots: 'Center pivots',
        pivotPlacement: 'Pivot placement',
        pivotComment: 'Source is the default state which means that the pivots will be used as present in the file and not be changed',
        isPreview: 'Is the preview',
        setAsPreview: 'Set as preview',
        valueCopiedToClipboard: 'The value got copied to your clipboard',
        New: 'New',
        DefineMerging: 'Define merging groups',
        State: 'State',
        ConnectionWaiting: 'The connection seem sto be slow, trying to connect to server.',
        ConnectionError: 'Could not connect to server, trying to reconnect.',
        RemoveMetaSetWarning: 'ATTENTION: if you remove this metaSet here, all values you might have set anywhere on a 3D-Group with this meta set on groups will be lost',
        AttachOrRemoveMetaSet: 'Attach / remove meta sets',
        UpdateProcessStarted: 'The updated process is now in progress. You will receive a notification when it is finished.',
        NoExportProfileAccess: 'Your organization does not seem to have activated this feature yet – contact us for more information',
        WillDeleteAllRows: 'This will delete all rows in this table',
        for: 'for',
        AllowAccess: 'Allow access',
        SelectOrganizations: 'Select organizations',
        UserCreationHint: 'You can only create users for organizations in which you are an administrator.',
        Role: 'Role',
        AddEntry: 'Add entry',
        limitAccess: 'Limit access',
        fullAccessInOrganization: 'Free access for organization members',
        allowTeamAccess: 'Team access',
        isDefault: 'Is default',
        Documentation: 'Documentation',
        SignIn: 'Sign in',
        frontSloganRight: 'Start now.<br />Create your own<br />3D realtime',
        frontSloganLeft: 'Tomorrow\'s<br />3D Digital Twin<br />Cloud Platform. Now.',
        AssemblyProcessing: 'This 3D item is being processed – updates will be possible after the process is finished',
        UpdateSfxData: 'Update 3D-Data',
        XMLUpdateStart: 'Update meta data',
        SpecifyColumns: 'Column selection',
        GetEmailNotification: 'Get notification per email',
        MetaUpdateMode: 'On <b>reset</b> all data of the selected meta set will be removed<br />On <b>overwrite</b> existing values will be overwritten if new values are provided<br />On <b>extend</b> a value will only be set if no value is pre-existing',
        extend: 'extend',
        overwrite: 'overwrite',
        reset: 'reset',
        ChooseMaterialColumnIfOverwrite: 'Choose a material column if you wish to overwrite the default materials',
        ChooseColorColumnIfOverwrite: 'Choose a color column if you wish to overwrite the default colors',
        UploadMetaFile: 'Upload meta file',
        UpdateMetaData: 'Update meta data',
        MetaData: 'Meta Data',
        ActiveServices: 'Active Services',
        NameIsDefinitive: 'Attention, the name cannot be changed after saving.',
        downloadAsCsv: 'Download as CSV',
        Type: 'Type',
        type: {
            filter: 'filter',
            Version: 'Version',
            ExportProfile: 'Export profile',
            exportProfile: 'export profile',
            MetaSet: 'Meta set',
            service: 'Service',
            team: 'Team',
            material: 'Material',
            user: 'User',
            scene: 'Scene',
            project: 'Project',
            VirtualMachine: 'Virtual Machine',
            element: 'Element'
        },
        willAlsoBeDeleted: 'The following connected items will also be deleted',
        new: 'new',
        finished: 'finished',
        processing: 'processing',
        greatherThan: 'greater than',
        equals: 'equals',
        lessThan: 'less than',
        contains: 'contains',
        FileSize: 'File Size',
        DownloadFbx:'Download FBX File',
        sourceProjectId: 'Source project id',
        SourceCode: 'Source Code',
        SelectedFile: 'Selected file',
        importSFX: 'Import 3D-Data',
        PostProcessingForMeta: 'Format of meta file',
        metaSetName: 'Name of the meta set',
        metaSetMaterialField: "Meta Set Material Field",
        matMapperLoadFromServer: "LOAD FROM SERVER",
        matMapperLoadFromXML: "LOAD FROM XML",
        selectMaterialColumn: "Select Material Column",
        materialName: "Material Name",
        materialRemapWith: "Remap with",
        loadMaterials: "Load Materials",
        assignMaterials: "Assign Materials",
        CreateNew: 'Create new',
        AllConnections: 'All Connections',
        Disclaimer: 'Disclaimer',
        ExportedProfiles: 'Exported Files',
        Preview: 'Preview',
        startProcess: 'Start process',
        ExportProfiles: 'Export profiles',
        createNewSFXFromExportProfile: 'Start export',
        DefineProfile:'Define Profile',
        searchValues: 'Search values',
        chooseType: 'Choose {type}',
        createExportProfile: 'Create export profile',
        axixMultiplier: 'Axis multiplier',
        axisMultiplierDesc: 'If the axis of the model needs to be rotated, set the number in degrees (e.g.: x: 90, y: 180, z:0)',
        metaFilterInstructions: 'For numbers:<br /><ul><li>gt (greather than)</li><li>lt (less than)</li><li>eq (equal)</li></ul>For text:<br /><ul><li>eq (equal)</li><li>like</li></ul><b>Examples:</b> "gt 5" or "like *text*" or "eq text with spaces"',
        globalValues: 'Global values',
        localValues: 'Local values',
        importStart: 'Start import',
        importConfig: 'Import configuration',
        identifierColumn: 'ID column',
        chooseModelIdField: 'Please choose the column from your meta file that contains the ID of the component/element in your model',
        filter3DItems: 'Filter 3D-Elements',
        filter3DItemsDescription: 'If you want only import some parts of your CAD data you can set some filters here.',
        metaFileDataSet: 'Dataset for meta data',
        metaFile: 'Meta File',
        File: 'File',
        file: 'file',
        "File(s)": 'File(s)',
        thisCanTakeSeveralSeconds: 'This can take up to {num} seconds. Depending on the filesize and your connection speed it can also take considerably longer.',
        selectedFiles: 'Selected File(s)',
        removeFile: 'Remove File',
        rootFile: 'Root File',
        dataDescriptionFile: 'Metadata file',
        Filter: 'Filter',
        Search: 'Search',
        pleaseChooseRoot: 'Please select the root component. (Uploaded components need to have a single root element).',
        pleaseChooseDataFile: 'Please select the data enrichment file if available. At the moment only XML-Files resulting from a Windchill search result export are supported.',
        Back: 'Back',
        Forward: 'Forward',
        folderStructureForUpload: 'Required folder structure: there should be no folders in the zip, just the files',
        attentionUploadStartsProcess: 'Attention: Upload starts importing-process right away',
        importHierarchy: 'Import hierarchy',
        cadConvert: 'Convert CAD Data',
        loadBefore: 'Load before',
        loadMore: 'Load more',
        jumpTo: 'Jump to',
        initiallyUploaded: 'Uploaded',
        transferringData: 'Transferring data to data center',
        transferComplete: 'Transfer data to data center complete',
        convertingData: 'Converting data',
        deliveringConvertedData: 'Delivering converted data',
        processingFinished: 'Processing finished',
        yourFileIsFinishedProcessing: 'Your data has been processed and is available under "Linked Data"',
        fileProcessingCannotUpload: 'Your file is being processed right now – you will not be able to upload another file until the processing is finished.',
        stepEmailDisclaimer: 'If you want to receive the notifications about the processing to another than your account email, you can enter a different address here.',
        yourFileIsGettingProcessed: 'Your file is being processed now, you will receive an email when the process is finished.',
        stepFileEmail: 'Email',
        stepFile: 'Step file',
        stepFileStatus: 'Step file processing status',
        reUploadFile: 'Upload a different file',
        pleaseWait: 'Please Wait',
        stepFileProcessingDisclaimer: 'When you start the processing, it will take up to 36 hours to process the file depending on the file size and complexity.',
        uploadWaitDisclaimer: 'Please wait – do not close or reload this window until the upload is finished',
        startProcessing: 'Start Processing',
        fileProcessing: 'File Processing',
        Processing: 'Processing',
        removePicture: 'Remove picture',
        changePicture: 'Change picture',
        editPicture: 'Edit picture',
        picture: 'Picture',
        addPicture: 'Add picture',
        helperTextSfxTypes: '<b>vHelper</b><br />\n' +
            'A vHelper is a model which is used as icons or for navigational purposes and so on. Examples are arrows, human figures and so on.\n' +
            '\n' +
            '<br /><br /><b>Model</b><br />\n' +
            'A model is a 3D Model which is used as a single entity like a fire extinguisher that you just want to place in a scene.\n' +
            '\n' +
            '<br /><br /><b>Assembly</b><br />\n' +
            'An assembly is a 3D Model which contains a hierarchy of multiple parts or objects and which will processed in a way that later you will have the ability to link data to certain parts in that hierarchy or show/hide parts of the assembly.',
        app: 'App',
        webapp: 'Webapp',
        chooseMediaType: 'Choose media type',
        nodata: 'There is no data available for this view',
        clearFilter: 'clear filter',
        from: 'from',
        until: 'until',
        searchMode: 'Search Mode',
        standard: 'standard',
        exactMatch: 'exact match',
        similar: 'similar',
        beginsWith: 'begins with',
        doesNotContain: "doesn't contain",
        justWaitAMinute: 'Please wait...',
        ExportAsPDF: 'Export as PDF',
        basicConfiguration: 'Basic Configuration',
        dataPreSelection: 'Data Preselection',
        labelConfigurator: 'Label Configurator',
        additionalConfiguration: 'Additional Configuration',
        chartName: 'Name of the chart',
        chartDescription: 'Chart Description',
        remainingCharacters: 'Remaining characters',
        limitToNCharactersHint: 'The maximal length of the text is {num} characters.',
        maxSelected: 'You can select only {num} item(s).',
        chooseLabelConfiguration: 'Choose the data you want to appear in the tooltip.',
        linkToOriginalTable: 'Go to table',
        instances: 'Local copies',
        addData: 'Add data',
        addrow: 'Add row',
        reloadMappingsWarningLoseChanges: "You will lose all your unsaved work. Continue?",
        url: 'URL',
        billingEmail: 'Billing Email',
        owner: 'Owner',
        visibility: 'Visibility',
        displayName: 'Display Name',
        maintext: 'Main Text',
        editQrcode: 'Edit QR code',
        noAccess: 'You have no permission to access that page',
        noAccessChangeOrg: 'You don\'t have the permission to access that page with the selected organization',
        AccessDenied: 'Access Denied',
        orgVisibilityTypes: {
            '0': 'Private',
            '1': 'Organization Level',
            '2': 'Public',
        },
        teamVisibilityTypes: {
            '0': 'private',
            '1': 'public',
            '2': 'global (new users get added automatically)'
        },
        mediaCategory: {
            '0': 'application',
            '1': 'audio',
            '2': 'font',
            '3': 'image',
            '4': 'message',
            '5': 'model',
            '7': 'pdf',
            '8': 'text',
            '9': 'video',
        },
        filter: {
            stateSelect: 'State',
            minValue: 'From',
            maxValue: 'To',
            search: 'Text search',
            placeholder: 'Enter text here',
            app: 'App',
            webapp: 'Webapp',
            assembly: '3D-Group',
            model: '3D-Element',
            helper: 'vHelper',
            tbl: 'Table',
            obj: 'Object',
            sensor: 'Sensor',
            feed: 'Feed',
            typeSelect: 'Type Selection',
            tagFilter: 'Tags',
            orgFilter: 'Organizations',
            fieldSelector: 'Filter a field',
        },
        fieldname: 'Field Name',
        insertBeforeField: 'Insert before the following field',
        order: 'Order',
        text: 'Text',
        string: 'Text',
        number: 'Number',
        timestamp: 'Timestamp',
        time: 'Time',
        typeToSelect: 'Type to select',
        index: 'Index',
        chooseSfxType: 'Please choose a type',
        chooseAppType: 'Please choose a type',
        choseDataSourceType: 'Please choose a type',
        choseQrcodeType: 'Please choose a type',
        choseFieldType: 'Please choose a type',
        deletePromptQuestion: 'Are you sure you want to delete the selected object?',
        unlinkPromptQuestion: 'Are you sure you want to unlink the selected object and lose all local data from that link?',
        yes: 'Yes',
        abort: 'Cancel',
        addqrcode: 'Create QR Code',
        addQrcode: 'Create QR Code',
        username: 'Username',
        password: 'Password',
        user: {
            role: 'Role',
        },
        data: {
            lastUpdate: 'Last update',
            name: 'Name',
            edit: 'Edit',
        },
        Connect: 'Connect',
        edit: 'Edit',
        description: 'Description',
        media: 'media',
        name: 'Name',
        submit: 'Submit',
        Created: 'Created',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        telnr: 'Phone Number',
        withCountryCode: 'With Country Code (e.g. +41)',
        uploadfor: 'Upload for',
        ['Last change']: 'Last change',
        Contributors: 'Contributors',
        helper: 'vHelper',
        model: '3D-Element',
        assembly: '3D-Group',
        SFXData: '3D-Data',
        sfxdata: "3D data",
        qrcodes: "QR codes",
        materialmappers: "Material Mappers",
        qrcode: "QR Code",
        datasources: 'Datasources',
        nav: {
            saasServices: 'SAAS SERVICES',
            saasAgb: 'SAAS AGB',
            csv_import: 'CSV Import',
            createVirtualMachine: 'Create VM',
            vmOverview: 'VM Overview',
            vmUsageAll: 'VM ALL',
            myVmUsage: 'VM Usage',
            vmUsage: 'VM Usage',
            adminproject: 'General Projects',
            taskmanager: 'Taskmanager',
            scene_general: 'General',
            scenebackbutton: 'Back',
            vmbackbutton: 'Back',
            scnee: 'Scene',
            scenes: 'Scenes',
            service_edit: 'General',
            rolebackbutton: 'Back',
            permissionsbackbutton: 'Back',
            permission_edit: 'General',
            permissions: 'Permissions',
            roles: 'Roles',
            material_general: 'General',
            materials: 'Materials',
            team_general: 'General',
            teams: 'Teams',
            organizing: 'Structure',
            qrcodes: 'QR-Codes',
            materialmappers: 'Material Mappers',
            services: 'Services',
            materialbackbutton: 'Back',
            materialmapbackbutton: 'Back',
            servicebackbutton: 'Back',
            teambackbutton: 'Back',
            sfxdata_export_profiles: 'Export profiles',
            files: 'Files',
            access_denied: 'Access Denied',
            users: 'Users',
            tags: 'Tags',
            admin: 'Admin',
            projects: 'Projects',
            project: 'Projects',
            form_general: 'General',
            forms: 'Forms',
            formbackbutton: 'Back',
            media: 'Media',
            addMedia: 'Create media object',
            uploadMediaFile: 'Upload media file',
            apps: 'apps',
            app: 'apps',
            logout: 'logout',
            SFXData: '3D-Data',
            account: 'Account',
            organizations: 'Organizations',
            project_scene: "Scene",
            project_general: "General",
            project_3ddata: "3D-Data",
            project_media: 'Media',
            report_general: "General",
            sfxdata: "3D-Data",
            sfxdata_general: "General",
            sfxdata_general_assembly: "General",
            project_datasets: "Data Sources",
            project_datasources: "Data Sources",
            reports: "Reports",
            report: "Reports",
            sfxdata_data_sets: "Data Sources",
            sfxdata_media: "Media",
            sfxdata_assembly_update: "Update",
            texts: "Texts",
            text_general: "General",
            app_general: "General",
            media_general: "General",
            user_general: "General",
            app_project: "Project",
            qrcode_general: "General",
            materialmapper_general: "General",
            data: "Data",
            data_tables: "Data Tables",
            data_sources: "Data Sources",
            data_sources_general: "General",
            datasets: "Datasets",
            dataset_general: "General",
            datasources: "Data Sources",
            resources: "Resources",
            library: "Library",
            modelbackbutton: "Back",
            assemblybackbutton: "Back",
            reportbackbutton: "Back",
            projectsbackbutton: "Back",
            projectbackbutton: "Back",
            userbackbutton: "Back",
            appbackbutton: "Back",
            webappbackbutton: "Back",
            sfxbackbutton: "Back",
            mediabackbutton: "Back",
            datasetbackbutton: "Back",
            datasourcebackbutton: "Back",
            metasetbackbutton:  "Back",
            textbackbutton: "Back",
            qrcodebackbutton: "Back",
            materialmapperbackbutton: "Back",
            organizationbackbutton: "Back",
            sfxdata_linked_data: "Linked Data",
            project_linked_data: "Linked Data",
            sfxdata_connection_list: "Linked Data List",
            addnew3ddata: "Create new 3D Object",
            Upload: "Upload",
            Config: "Config",
            metasets: "Metasets",
            metaSets: "Metasets",
            webapp_linked_data: "Linked Data"
        },
        events: {
            instanceUpdated: 'The instance has been updated',
            metaValuesUpdated: 'A meta value has been updated',
            tagUpdated: 'The tag has been updated',
            metaFieldCreated: 'A meta field has been created',
            metaSetCreated: 'A meta set has been created',
            appUpdated: 'The application has been updated.',
            appCreated: 'A new app has been created.',
            appDeleted: 'The app has been deleted.',
            appMetaValuesUpdated: 'The value was updated.',
            dataSetCreated: 'A new data set has been created.',
            dataSetDeleted: 'The data set has been deleted.',
            dataSetUpdated: 'The data set has been updated.',
            dataSourceMetaValuesUpdated: 'The value was updated.',
            dataSourceCreated: 'A new data source has been created.',
            dataSourceDeleted: 'The data source has been deleted.',
            dataSourceUpdated: 'The data source has been updated.',
            datasetMetaValuesUpdated: 'The value was updated.',
            formUpdated: 'The form has been updated.',
            formCreated: 'The form has been created.',
            mediaCreated: 'A new media object has been created.',
            mediaDeleted: 'The media object has been deleted.',
            mediaUpdated: 'The media object has been updated.',
            mediaMetaValuesUpdated: 'The value was updated.',
            metaFieldDeleted: 'The MetaField was deleted.',
            metaFieldUpdated: 'The MetaField has been updated.',
            organizationUpdated: 'The organization has been updated',
            organizationCreated: 'The organization has been created.',
            organizationDeleted: 'The organization has been deleted.',
            pictureRemoved: 'The picture has been removed.',
            projectCreated: 'A new project has been created.',
            projectDeleted: 'The project has been deleted.',
            projectUpdated: 'The project has been updated.',
            projectMetaValuesUpdated: 'The value was updated.',
            qrcodeCreated: 'A new qr code has been created.',
            qrcodeDeleted: 'The qr code has been deleted.',
            qrcodeUpdated: 'The qr code has been updated.',
            qrcodeMetaValuesUpdated: 'The value was updated.',
            reportCreated: 'A new report has been created.',
            reportUpdated: 'The report has been updated.',
            reportMetaValuesUpdated: 'The value was updated.',
            sceneCreated: 'A new scene has been created.',
            sceneDeleted: 'The scene has been deleted.',
            sceneUpdated: 'The scene has been updated.',
            sceneMetaValuesUpdated: 'The value was updated.',
            sfxDataCreated: 'A new 3d object has been created.',
            sfxDataDeleted: 'The 3D object has been deleted.',
            sfxDataUpdated: 'The 3D Object has been updated.',
            sfxDataMetaValuesUpdated: 'The value was updated.',
            textUpdated: 'The text was updated.',
            textCreated: 'The text was created.',
            tagDeleted: 'The tag has been deleted.',
            tagsAdded: 'The tag was added',
            tagsRemoved: 'The tag has been removed.',
            tagCreated: 'The tag was created',
            userUpdated: 'The user data have been updated.',
            userCreated: 'A new user has been created',
        },
        enternamehere: 'Name',
        backtooverview: 'back to overview',
        projectloading: 'Project loading',
        CONTRIBUTORS: 'CONTRIBUTORS',
        STATUS: 'STATUS',
        entertitlehere: 'Enter title here',
        createNewType: 'Create new {type}',
        selectedType: 'Selected {type}',
        addNewType: 'Add new {type}',
        deleteType: 'Delete {type}',
        createNewTypeFile: 'create new {type}',
        downloadType: 'Download {type}',
        updateType: 'Update {type}',
        enterdeschere: 'Enter description here',
        createnewapp: 'Create new app',
        createNewApp: 'Create new app',
        createNewProject: 'Create new project',
        createNewSfxdata: 'Import new 3D-data',
        createNewMedia: 'Create new media',
        createNewUser: 'Create new user',
        createNewMetaField: 'Create new metaField',
        createNewMetaSet: 'Create new metaSet',
        createNewOrganization: 'Create new organization',
        createNewReport: 'Create new report',
        createNewText: 'Create new text',
        createNewQrCode: 'Create QR Code',
        createNewForm: 'Create new form',
        createNewMaterialMapper: 'New material mapper',
        SAVE: 'SAVE',
        REMOVE: 'REMOVE',
        makeCodePretty: 'Pretty Code',
        imageupload: 'Drag file here or click to upload',
        fileupload: 'Drag file here or click to upload',
        Media: "Media",
        Datasets: "Data Sources",
        ChooseProjectForApp: 'Choose a base project for this app',
        deletefield: 'DELETE FIELD',
        Anumberbetween: 'A number between {num} and {num2}',
        duplicateFileError: 'A file with the name {fileName} already exists. Please rename your file or choose another one to upload.',
        addnewfield: 'Add new field',
        addnewcolumn: 'Add new column',
        savefield: 'Save field',
        editfield: 'Edit field "{field}"',
        fieldCannotBeEditedWhenData: 'The field cannot be edited if there is any data filled in the table.',
        NotANumber: 'The entered value is not a number',
        createnew: 'Create new',
        filtering: 'Filtering',
        resources: 'Resources',
        library: "Library",
        addconnection: "Connecting data",
        removeconnection: "Verknüpfung wird entfernt",
        LinkedData: 'Linked Data',
        LocalLinkedItems: 'Linked Data',
        createNewDataSet: 'Create a new table',
        createNewDataSource: 'Create new datasource',
        MetaPanel: 'Meta Information',
        sfxEditFile: '3D File',
        mediaEditFile: 'Media File',
        textEditFile: '.po Translation File',
        localValuesDisclaimer: 'The local values override the global values for this one instance',
        instanceDisclaimer: 'Attention: if you edit these values they will change everyhwere where this asset is in use and its values not locally overwritten',
        uploadDisclaimer: 'Attention: if you upload a new file it will be replaced everywhere where it used',
        onlySingleLinkingDisclaimer: 'These items can only linked once – the instances column shows where it is already linked.',
        errors: {
            mustBeValueBetween: 'The value must be between {min} and {max}',
            metaSetName: 'The meta set cannot start with "sfx." because this is a protected namespace',
            PleaseSelectTheFormatOfYourMetaFile: 'Please select the format of your meta file or remove the meta file (adding meta data only works for the given formats)',
            PleaseSelectARootNode: 'Please select a root node for the given 3D-Data',
            alreadyExists: 'Duplicate name: There exists already a {type} with this name',
            metaSetNameAlreadyExists: 'Duplicate name: There exists already a meta set with this name',
            textNameAlreadyExists: 'Duplicate name: There exists already an asset with this name',
            connectionLimit: 'Only {num} elements can be linked for this content type. Please unlink one or more elements before linking a new one.',
            linkingNotPossible: 'The content could not be linked, there was an error.',
            cantEditRowWhenData: 'Fields cannot be edited if there are data rows containing data.',
            mustbeanumber: 'The value must be a number',
            atLeastCharacters: 'Please enter at least {num} characters',
            duplicateNameForAsset: 'Duplicate name: There exists already an asset with this name',
            duplicateNameForReport: 'Duplicate name: There exists already an report with this name',
            atLeastThreeCharacters: 'Please enter at least 3 characters',
            userNameAlreadyExists: 'A user with this name already exists, please choose another name',
            organizationNameAlreadyExists: 'An organization with this name already exists, please choose another name',
            emailAddressAlreadyExists: 'There already exists an account with this email address',
            mustBeAValidEmailAdress: 'Please enter a valid email address',
            bothPasswordsMustBeTheSame: `The two password fields don/'t match`,
            alphaNumSpaceOnly: 'Allowed characters: letters, numbers and the following . - _ °',
            alphaNumOnly: 'Allowed characters: letters, numbers, - and _ (no spaces)',
            alphaNumDot: 'Allowed characters: letters, numbers and .',
            orgNameOnly: 'Allowed characters: letters, numbers and -, and the - cannot be at either the beginning or the end',
            objectNameAlreadyExists: 'Duplicate name: There exists already an item with this name',
            atMostCharacters: 'Text too long: The maximum length is {num} characters ',
            appNameAlreadyExists: 'Duplicate name: There exists already an app with this name',
            projectNameAlreadyExists: 'Duplicate name: There exists already a project with this name',
            sfxdataNameAlreadyExists: 'Duplicate name: There exists already an asset with this name',
            formNameAlreadyExists: 'Duplicate name: There exists already an asset with this name',
            mediaNameAlreadyExists: 'Duplicate name: There exists already an asset with this name',
            datasetNameAlreadyExists: 'Duplicate name: There exists already a dataset object with this name',
            materialmapperNameAlreadyExists: 'Duplicate name: There exists already a material mapper object with this name',
            datasourceNameAlreadyExists: 'Duplicate name: There exists already an asset with this name',
            reportNameAlreadyExists: 'Duplicate name: There exists already an asset with this name',
            required: 'This field is mandatory',
            fileTypeNotAllowed: 'This file type can\'t be uploaded. Allowed file extensions: {fileTypes}',
            fileTooBig: 'Your file ({{filesize}}) exceeds the limit of {{maxFilesize}} MB',
            userPassOrNameIsWrong: 'Login failed: the username or password were rejected.',
            userIsBlocked: 'The user is blocked due to too many faulty login tries',
            mustBeNumeric: 'The value must be a number',
            mustBeAValidURL: 'Please enter a valid URL in the format https://www.test.ch',
        },
        forms: {
            isDefaultOrganization: 'Is default organization',
            default: 'Is default',
        },
        meta: {
            type: {
                int: 'Integer',
                str: 'String',
                json: 'JSON',
                float: 'Float',
                enum: 'Enumeration',
                bool: 'Boolean',
                clr: 'Color',
                v2: 'Vector 2',
                v3: 'Vector 3',
                v4: 'Vector 4',
                time: 'Time',
                datetime: 'Date and time',
                date: 'Date',
                project: 'Project',
                password: 'Password',
                xform3: 'Xform3',
                xform2: 'Xform2',
                email: 'Email',
                ip: 'IP',
                ipv6: 'Ipv6',
                uri: 'Uri',
                range: 'Range',
                asset: {
                    feed: 'Feed',
                    sensor: 'Sensor'
                },

            }
        }
    },
    de: {
        NoneSelected: 'Nichts ausgewählt',
        addLoggingTable: 'Logging-Tabelle erstellen',
        hasNextButton: 'Hat einen "weiter"-Button',
        addStep: 'Step hinzufügen',
        emphasized: 'hervorgehoben',
        showAsTiles: 'als Kacheln anzeigen',
        isUserIdentifierHint: 'Damit die Daten aus diesem vForm in eine Logtabelle eingetragen werden können, benötigt es exakt ein Feld, das den Nutzer identifiziert. Fügen Sie ein Short Answer Element hinzu und setzen Sie das Häckchen bei "Identifiziert den Nutzer".',
        isUserIdentifier: 'Identifiziert den Nutzer',
        validateCorrectness: 'Korrektheit prüfen',
        isRequired: 'Ist erforderlich',
        projectFilesNotAvailable: 'Die Daten für dieses Projekt sind nicht auf dem Server. Bitte pushen Sie Ihr lokales Projekt auf den Server und versuchen Sie es dann erneut.',
        vFormProjectHint: 'Die Bestandteile eines vForms beziehen sich auf Slides eines Projekts. Daher wird nicht empfohlen, das Projekt zu wechseln oder zu entfernen, ansonsten müssen die Slide-Referenzen manuell angepasst werden. Ein vForm kann nur mit einem einzigen Projekt verknüpft sein.',
        addTranslation: 'Übersetzung hinzufügen',
        teamsNotMatching: 'Achtung, dieses Item ist nicht für alle Teams zugänglich. Fehlendes Team: ',
        editFile: 'bearbeiten',
        duplicateFile: 'kopieren',
        createUpdateDefaultJson: 'create/update default json',
        TileButtons: {
            webapp: 'Webapp',
            app: 'App',
            node: 'Node',
            material: 'Material',
            filter: 'ExportProfile',
            text: 'Text',
            model: '3D-Element',
            form: 'vForm',
            qrcode: 'QR-Code',
            report: 'Report',
            sensor: 'Sensor',
            feed: 'Feed',
            scenery: 'Szene',
            assembly: '3D-Gruppe',
            link: 'Link',
            media: 'Media',
            helper: 'vHelper'
        },
        LoadStats: 'Kennzahlen laden',
        importAssetViaCSV: 'Assets via CSV importieren',
        importLog: 'Import-Log',
        gotIt: 'Verstanden',
        cookieNotification: 'Durch den Klick auf den Login-Button erklären Sie sich mit Cookies einverstanden. Die' +
            ' Webapplikation funktioniert ohne den Einsatz von Cookies derzeit nicht.',
        gdpr_link: 'Datenschutzerklärung',
        Accept: 'Annehmen',
        Deny: 'Ablehnen',
        gdprExplanation: 'Mit Klick auf "annehmen" akzeptiere ich die obenstehende Datenschutzerklärung.<br />Beim' +
            ' Klick auf "ablehnen" werden Sie automatisch ausgeloggt.',
        gdprHint: 'Um vHub nutzen zu können, müssen Sie die folgende Datenschutzerklärung akzeptieren:',
        gdpr: gdpr_de,
        saas_agb: saas_agb_en,
        saas_services: saas_services_en,
        RowLifetime: 'Row Lifetime',
        Days: 'Tage',
        Hours: 'Stunden',
        Minutes: 'Minuten',
        Seconds: 'Sekunden',
        usePicker: 'Picker-Tool verwenden',
        manualEntry: 'Manueller Eintrag',
        noFilterData: 'In der ausgewählten Spalte sind keine Daten vorhanden, um zu filtern.',
        pwdConditions: {
            atLeastOneUppercaseLetter: 'Ein Grossbuchstabe',
            atLeastOneDigit: 'Eine Zahl',
            atLeastOneSpecialCharacter: 'Ein Sonderzeichen: {characters}',
            atLeastCharacters: 'Länge zwischen {min} und {max} Zeichen',
        },
        repeatPassword: 'Passwort wiederholen',
        showPassword: 'Passwort anzeigen',
        vmExplanation: 'Die virtuelle Maschine verarbeitet Ihre Daten',
        selectVM: 'Virtuelle Maschine auswählen',
        StorageUsage: 'Storage-Auslastung',
        LoadStorageUsage: 'Storage-Auslastung anzeigen',
        stopFeed: 'Stop feed',
        startFeed: 'Start feed',
        restartFeed: 'Restart Feed',
        getFeedStatus: 'Feed status',
        uploadvStageProject: 'Laden Sie zuerst ein Projekt von vStage hoch, um es hier zu verlinken.',
        noDataYet: 'Noch keine Objekte vorhanden?',
        permissions: {
            logs: 'Logs',
            organization: 'Organisation',
            general: 'Allgemein',
            form: 'vForm',
            user: 'Benutzer',
            metaset: 'MetaSet',
            team: 'Team'
        },
        a: 'aktiv',
        h: 'verborgen',
        p: 'in Arbeit',
        i: 'inaktiv',
        m: 'in Wartung',
        createNewAssetBundle: 'Neues Asset Bundle',
        UseMetaSetValuesForPivots: 'Verwende für lokale Korrekturen das Feld "Bevorzugte Pivots" im Meta Set sfx.asset.transform (3D Transforms)',
        DefinePivotCorrections: 'Pivot Korrekturen',
        keepOriginalNames: 'Maintain original names',
        centerPivots: 'Pivots zentrieren',
        pivotPlacement: 'Pivots-Position',
        pivotComment: 'Source ist der Standard-Zustand, d.h. die Pivots werden von der Datei übernommen und nicht geändert',
        isPreview: 'Ist das verwendete Vorschaubild',
        setAsPreview: 'Als Vorschaubild verwenden',
        valueCopiedToClipboard: 'Der Wert wurde in Ihre Zwischenablage kopiert',
        New: 'Neu',
        DefineMerging: 'Merging-Gruppen definieren',
        State: 'Status',
        ConnectionWaiting: 'Die Internetverbindung ist langsam, bitte warten.',
        ConnectionError: 'Die Verbindung zum Server konnte nicht aufgenommen werden, versuche zu verbinden.',
        RemoveMetaSetWarning: 'ACHTUNG: Durch das Entfernen des MetaSets werden Sie sämtliche Daten verlieren, die Sie bei diesem MetaSet auf einer FBX-Gruppe gesetzt haben.',
        AttachOrRemoveMetaSet: 'Metasets hinzufügen / entfernen',
        ADD: 'Hinzufügen',
        UpdateProcessStarted: 'Der Aktualisierungsprozess ist gestartet. Sie werden eine Bestätigung erhalten, sobald der Prozess abgeschlossen ist.',
        NoExportProfileAccess: 'Ihre Organisation scheint diesen Service noch nicht freigeschaltet zu haben. Kontaktieren Sie uns für mehr Informationen.',
        WillDeleteAllRows: 'ACHTUNG: Hierdurch werden alle sichtbaren Einträge in dieser Tabelle gelöscht!',
        for: 'für',
        AllowAccess: 'Zugriff freigeben',
        SelectOrganizations: 'Organisationen auswählen',
        UserCreationHint: 'Sie können nur Benutzer für Organisationen erfassen, bei denen Sie Administrator-Rechte besitzen.',
        Role: 'Rolle',
        AddEntry: 'Eintrag hinzufügen',
        limitAccess: 'Zugriff einschränken',
        fullAccessInOrganization: 'Freier Zugriff für<br />Organisations-<br />mitglieder',
        allowTeamAccess: 'Teamzugriff',
        isDefault: 'Ist Standardwert',
        Documentation: 'Documentation',
        SideEffectsDisclaimer: 'Interactive 3D Solutions by <a href="https://sideeffects.ch" target="_blank">Side Effects AG</a>',
        SignIn: 'Sign In',
        frontSloganRight: 'Start now.<br />Create your own<br />3D realtime',
        frontSloganLeft: 'Tomorrow\'s<br />3D Digital Twin<br />Cloud Platform. Now.',
        AssemblyProcessing: 'Für diese 3D-Gruppe läuft gerade ein Verarbeitungsprozess. Updates werden möglich sein, sobald dieser Prozess abgeschlossen ist.',
        UpdateSfxData: '3D-Daten aktualisieren',
        XMLUpdateStart: 'Daten aktualisieren',
        SpecifyColumns: 'Spalten-Auswahl',
        GetEmailNotification: 'Per Email benachrichtigt werden',
        MetaUpdateMode: 'Beim <b>Zurücksetzen</b> werden sämtliche bestehenden Werte des gewählten Meta Sets vorher gelöscht<br />Beim <b>Überschreiben</b> werden bestehende abweichende Werte überschrieben<br />Beim <b>Erweitern</b> werden nur Werte erweitert, wo keine bestehenden Werte vorhanden sind',
        extend: 'erweitern',
        overwrite: 'überschreiben',
        reset: 'zurücksetzen',
        ChooseMaterialColumnIfOverwrite: 'Spalte Materialbezeichnung (überschreibt Standard-Werte, wenn ausgewählt)',
        ChooseColorColumnIfOverwrite: 'Spalte Farbbezeichnung (überschreibt Standard-Werte, wenn ausgewählt)',
        UploadMetaFile: 'Meta-Datei hochladen',
        UpdateMetaData: 'Meta-Daten aktualisieren',
        MetaData: 'Meta-Daten',
        ActiveServices: 'Aktive Services',
        NameIsDefinitive: 'Achtung, der Name kann später nicht mehr geändert werden.',
        downloadAsCsv: 'Als CSV herunterladen',
        type: {
            filter: 'Filter',
            Version: 'Version',
            ExportProfile: 'Exportprofil',
            exportProfile: 'Exportprofil',
            MetaSet: 'Meta Set',
            service: 'Service',
            team: 'Team',
            material: 'Material',
            user: 'User',
            Role: 'Rolle',
            scene: 'Szene',
            project: 'Projekt',
            VirtualMachine: 'Virtuelle Maschine',
            element: 'Element'
        },
        willAlsoBeDeleted: 'Die folgenden verknüpften Elemente werden ebenfalls gelöscht',
        new: 'neu',
        finished: 'abgeschlossen',
        processing: 'in Verarbeitung',
        greatherThan: 'grösser als',
        equals: 'gleich',
        lessThan: 'weniger als',
        contains: 'enthält',
        FileSize: 'Dateigrösse',
        DownloadFbx: 'FBX Datei herunterladen',
        sourceProjectId: 'Projekt-ID',
        SourceCode: 'Quellcode',
        SelectedFile: 'Ausgewählte Datei',
        importSFX: 'Import 3D-Daten',
        PostProcessingForMeta: 'Format der Metadaten-Datei',
        metaSetName: 'Name des Metasets',
        metaSetMaterialField: "Metaset Feld für Material",
        matMapperLoadFromServer: "VOM SERVER LADEN",
        matMapperLoadFromXML: "VOM XML IMPORTIEREN",
        selectMaterialColumn: "Spalte für Material auswählen",
        materialName: "Name des Materials",
        materialRemapWith: "Neu verknüpfen mit",
        loadMaterials: "Materialien laden",
        assignMaterials: "Materialien zuweisen",
        CreateNew: 'Neu erstellen',
        AllConnections: 'Alle Verknüpfungen',
        Disclaimer: 'Hinweis',
        ExportedProfiles: 'Exportierte Dateien',
        Preview: 'Vorschau',
        startProcess: 'Prozess starten',
        ExportProfiles: 'Exportprofile',
        createNewSFXFromExportProfile: 'Export starten',
        DefineProfile:'Profil definieren',
        searchValues: 'Suchwerte',
        chooseType: '{type} auswählen',
        createExportProfile: 'Exportprofil erstellen',
        Mode: 'Modus',
        axixMultiplier: 'Achsenkorrektur',
        axisMultiplierDesc: 'Falls die Achse des Modells korrigiert werden muss, diese hier in Grad angeben (z.B.: x: 90, y: 180, z:0)',
        metaFilterInstructions: 'Für Zahlen:<br /><ul><li>gt (greather than/grösser als)</li><li>lt (less than/kleiner als)</li><li>eq (equal/gleich)</li></ul>Für Text:<br /><ul><li>eq (equal/gleich)</li><li>like (ähnlich wie)</li></ul><b>Beispiele:</b> "gt 5" oder "like *Chrom*" or "eq Metall"',
        globalValues: 'Globale Werte',
        localValues: 'Lokale Werte',
        importStart: 'Import starten',
        importConfig: 'Import-Konfiguration',
        identifierColumn: 'ID Spalte',
        chooseModelIdField: 'Geben Sie die Spalte an, welche die Bauteilbenennungen beinhaltet.',
        filter3DItems: '3D-Elemente Filtern',
        filter3DItemsDescription: 'Wenn Sie nur bestimmte Teile Ihrer CAD-Daten importiert haben möchten, können Sie hier anhand Ihrer Meta-Daten Filtern',
        metaFileDataSet: 'Tabelle Meta-Daten',
        metaFile: 'Meta-Daten Datei',
        File: 'Datei',
        file: 'Datei',
        "File(s)": 'Datei(en)',
        thisCanTakeSeveralSeconds: 'Das kann bis zu {num} Sekunden dauern. Je nach Dateigrösse und Internetverbindung auch länger.',
        selectedFiles: 'Ausgewählte Datei(en)',
        removeFile: 'Datei entfernen',
        rootFile: 'Root-Datei',
        dataDescriptionFile: 'Metadaten-Datei',
        Filter: 'Filtern',
        Search: 'Suchen',
        pleaseChooseRoot: 'Bitte wählen Sie die Root-Komponente. (Hochgeladene Modelle müssen zwingend ein einzelnes Root-Element haben).',
        pleaseChooseDataFile: 'Bitte wählen Sie die Datei, welche Zusatzinformationen zu den einzelnen Elementen beinhaltet. (Derzeit werden nur XML-Dateien unterstützt, welche als Windchill Suchresultat exportiert wurden)',
        Back: 'Zurück',
        Forward: 'Vorwärts',
        folderStructureForUpload: 'Zip-File: es dürfen im Zip keine Ordner vorhanden sein, ausschliesslich Dateien',
        attentionUploadStartsProcess: 'Achtung: Upload startet den Import-Prozess sofort',
        importHierarchy: 'Import Hierarchie',
        cadConvert: 'CAD Daten konvertieren',
        loadBefore: 'Zurück',
        loadMore: 'Mehr laden',
        jumpTo: 'Springe zu',
        initiallyUploaded: 'Datei hochgeladen',
        transferringData: 'Datentransfer zum Datenzentrum',
        transferComplete: 'Transfer zum Datenzentrum komplett',
        convertingData: 'Konvertierung der Daten',
        deliveringConvertedData: 'Auslieferung der konvertierten Daten',
        processingFinished: 'Verarbeitung abgeschlossen',
        yourFileIsFinishedProcessing: 'Ihre Daten wurden verarbeitet und sind nun unter "Verknüpfte Daten" verfügbar.',
        fileProcessingCannotUpload: 'Ihre Datei wird gerade verarbeitet – Sie können erst wieder eine neue Datei hochladen, wenn der Verarbeitungsprozess abgeschlossen ist.',
        stepEmailDisclaimer: 'Wenn Sie die Benachrichtigung an eine andere Email-Adresse als Ihre Account-Adresse gesendet bekommen möchten, können Sie hier eine Adresse angeben.',
        yourFileIsGettingProcessed: 'Ihre Datei wird nun verarbeitet. Sie werden eine Email erhalten, wenn die Verarbeitung abgeschlossen ist. Sie können dieses Browserfenster ohne Probleme schliessen.',
        stepFile: 'Step Datei',
        stepFileStatus: 'Step Datei Status',
        stepFileEmail: 'Email',
        reUploadFile: 'Neue Datei hochladen',
        pleaseWait: 'Bitte warten',
        stepFileProcessingDisclaimer: 'Wenn Sie die Verarbeitung starten, kann es je nach Dateigrösse bis zu 36h dauern, bis die Datei konvertiert wurde.',
        uploadWaitDisclaimer: 'Bitte warten – bitte NICHT das Fenster schliessen oder neu laden, bis der Upload beendet ist.',
        startProcessing: 'Verarbeitung starten',
        fileProcessing: 'Dateiverarbeitung',
        Processing: 'In Bearbeitung',
        removePicture: 'Bild entfernen',
        changePicture: 'Bild ändern',
        editPicture: 'Bild bearbeiten',
        picture: 'Bild',
        addPicture: 'Bild hinzufügen',
        helperTextSfxTypes: '<b>vHelper</b><br />\n' +
            'Ein vHelper ist ein Modell, welches als Icon, für Navigationszwecke oder als Pointer eingesetzt wird, z.B. Pfeile, menschliche Figuren und so weiter.\n' +
            '\n' +
            '<br /><br /><b>Model</b><br />\n' +
            'Ein Model ist ein 3D Modell welches als einzelne Komponente behandelt wird, z.B. ein Feuerlöscher oder einen Mülleimer, den Sie in der Szene platzieren möchten.\n' +
            '\n' +
            '<br /><br /><b>Baugruppe</b><br />\n' +
            'Eine Baugruppe ist ein 3D-Modell welches aus mehreren Komponenten besteht und so verarbeitet wird, dass später mit einzelnen Unterkomponenten Daten verlinkt werden oder einzelne Komponenten ein- und ausgeblendet werden können.',
        app: 'App',
        webapp: 'Webapp',
        chooseMediaType: 'Medien-Typ auswählen',
        nodata: 'Für diese Ansicht sind derzeit keine Daten vorhanden',
        clearFilter: 'Filter leeren',
        from: 'von',
        until: 'bis',
        searchMode: 'Suchmodus',
        standard: 'Standard',
        exactMatch: 'Exakt gleich',
        similar: 'Ähnlich',
        beginsWith: 'Beginnt mit',
        doesNotContain: "Enthält nicht",
        justWaitAMinute: 'Bitte warten...',
        ExportAsPDF: 'Als PDF exportieren',
        basicConfiguration: 'Basis-Konfiguration',
        dataPreSelection: 'Daten-Vorauswahl',
        labelConfigurator: 'Beschriftungs-Konfigurator',
        additionalConfiguration: 'Weitere Konfigurationen',
        chartName: 'Name des Charts',
        chartDescription: 'Beschreibung',
        remainingCharacters: 'Verbleibende Zeichen',
        limitToNCharactersHint: 'Der Text darf maximal {num} Zeichen lang sein.',
        maxSelected: 'Es können nur {num} Element(e) ausgewählt werden.',
        chooseLabelConfiguration: 'Wählen Sie aus, welche Daten im Tooltip angezeigt werden sollen.',
        linkToOriginalTable: 'Verknüpfte Tabelle',
        instances: 'Lokale Kopien',
        addData: 'Daten hinzufügen',
        addrow: 'Zeile hinzufügen',
        reloadMappingsWarningLoseChanges: "Sie werden alle ungespeicherten Änderungen verlieren. Fortfahren?",
        url: 'URL',
        owner: 'BesitzerIn',
        billingEmail: 'Rechnungs-Email',
        visibility: 'Sichtbarkeit',
        displayName: 'Display Name',
        maintext: 'Haupttext',
        editQrcode: 'QR Code bearbeiten',
        AccessDenied: 'Kein Zugriff',
        noAccess: 'Sie haben nicht die Berechtigung, auf diese Seite zuzugreifen',
        noAccessChangeOrg: 'Sie haben für die ausgewählte Organisation nicht die Berechtigung, auf diese Seite zuzugreifen',
        filter: {
            stateSelect: 'Status',
            minValue: 'Von',
            maxValue: 'Bis',
            search: 'Textsuche',
            placeholder: 'Suchtext eingeben',
            app: 'App',
            webapp: 'Webapp',
            assembly: '3D Gruppe',
            model: '3D Element',
            helper: 'vHelper',
            tbl: 'Tabelle',
            obj: 'Objekt',
            sensor: 'Sensor',
            feed: 'Feed',
            typeSelect: 'Typ',
            tagFilter: 'Tags',
            orgFilter: 'Organisationen',
            fieldSelector: 'Feld filtern',
        },
        fieldname: 'Feldbezeichnung',
        insertBeforeField: 'Vor dem folgenden Feld einfügen',
        order: 'Reihenfolge',
        text: 'Text',
        string: 'Text',
        number: 'Zahl',
        timestamp: 'Zeitstempel',
        time: 'Zeit',
        typeToSelect: 'Tippen um zu filtern',
        index: 'Index',
        chooseSfxType: 'Bitte wählen Sie einen Typus aus.',
        chooseAppType: 'Bitte wählen Sie einen Typus aus.',
        choseFieldType: 'Bitte wählen Sie einen Typus aus.',
        choseDataSourceType: 'Bitte wählen Sie einen Typus aus.',
        choseQrcodeType: 'Bitte wählen Sie einen Typus aus.',
        deletePromptQuestion: 'Sind Sie sicher, dass Sie das gewählte Objekt löschen möchten?',
        unlinkPromptQuestion: 'Sind Sie sicher, dass Sie die gewählte Verknüpfung aufheben möchten? Lokale Daten, die mit dieser Verknüpfung zusammenhängen, gehen dabei möglicherweise verloren.',
        yes: 'Ja',
        abort: 'Abbrechen',
        addQrcode: 'QR Code erstellen',
        addqrcode: 'QR Code erstellen',
        username: 'Benutzername',
        password: 'Passwort',
        user: {
            role: 'Rolle',
        },
        data: {
            lastUpdate: 'Letzte Aktualisierung',
            name: 'Name',
            edit: 'Bearbeiten',
        },
        enternamehere: 'Name',
        Connect: 'Verknüpfen',
        edit: 'Edit',
        description: 'Beschreibung',
        media: 'Medien',
        name: 'Name',
        submit: 'Speichern',
        logout: 'abmelden',
        Created: 'Erstellt am',
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'Email',
        telnr: 'Telefon-Nummer.',
        withCountryCode: 'Mit Länder-Vorwahl (z.B. +41)',
        uploadfor: 'Datei hochladen für',
        ['Last change']: 'Letzte Änderung',
        Contributors: 'Beteiligte',
        sfxdata: "3D-Daten",
        qrcodes: "QR Codes",
        qrcode: "QR Code",
        datasources: 'Datenquellen',
        MetaPanel: 'Meta Informationen',
        sfxEditFile: '3D-Datei',
        mediaEditFile: 'Media-Datei',
        textEditFile: '.po Übersetzungs-Datei',
        localValuesDisclaimer: 'Lokale Werte überschreiben die globalen Werte für diese eine Instanz',
        instanceDisclaimer: 'Achtung: wenn Sie hier Werte überschreiben, werden diese überall dort ersetzt, wo dieses Asset in Verwendung und nicht durch lokale Werte überschrieben ist',
        uploadDisclaimer: 'Achtung: wenn Sie hier eine neue Datei hochladen, wird sie überall ersetzt, wo dieses Asset in Verwendung ist',
        onlySingleLinkingDisclaimer: 'Die untenstehenden Objekte können nur einmalig verlinkt werden.',
        errors: {
            mustBeValueBetween: 'Der Wert muss zwischen {min} und {max} sein',
            metaSetName: 'Der Name des Metasets darf nicht mit "sfx." beginnen - das ist ein reservierter Namespace',
            alreadyExists: 'Doppelter Name: Es existiert bereits ein {type} mit diesem Namen.',
            PleaseSelectTheFormatOfYourMetaFile: 'Bitte wählen Sie das Format Ihrer Meta-Daten oder entfernen Sie die Auswahl der Meta-Datei (die Anreicherung der Meta-Daten funktioniert nur für die angegebenen Formate)',
            PleaseSelectARootNode: 'Bitte wählen sie das Root-Element Ihrer 3D-Daten',
            metaSetNameAlreadyExists: 'Doppelter Name: Es existiert bereits ein Metaset mit diesem Namen.',
            reportNameAlreadyExists: 'Doppelter Name: Es existiert bereits eine Statistik mit diesem Namen.',
            textNameAlreadyExists: 'Doppelter Name: Es existiert bereits ein Asset mit diesem Namen.',
            connectionLimit: 'Es können nur {num} Elemente verlinkt werden. Bitte entfernen Sie ein oder mehrere Elemente und versuchen Sie es erneut.',
            linkingNotPossible: 'Der ausgewählte Inhalt konnte nicht verlinkt werden.',
            cantEditRowWhenData: 'Felder können nicht mehr editiert werden, wenn bereits Datenzeilen vorhanden sind.',
            mustbeanumber: 'Der angegebene Wert muss eine Zahl sein',
            duplicateNameForAsset: 'Doppelter Name: Es existiert bereits ein Objekt mit diesem Namen',
            duplicateNameForReport: 'Doppelter Name: Es existiert bereits ein Report mit diesem Namen',
            atLeastThreeCharacters: 'Der Suchbegriff muss mindestens 3 Zeichen lang sein',
            userNameAlreadyExists: 'Ein Nutzer mit diesem Namen existiert bereits, bitte wählen Sie einen anderen Namen',
            organizationNameAlreadyExists: 'Eine Organisation mit diesem Namen existiert bereits, bitte wählen Sie einen anderen Namen',
            emailAddressAlreadyExists: 'Ein Account mit dieser Email-Adresse existiert bereits',
            atLeastCharacters: 'Die Bezeichnung muss mindestens {num} Zeichen lang sein',
            mustBeAValidEmailAdress: 'Bitte geben Sie eine gültige Email-Adresse ein',
            bothPasswordsMustBeTheSame: 'Die beiden Passwort-Felder stimmen nicht überein',
            alphaNumSpaceOnly: 'Erlaubte Zeichen: Buchstaben, Zahlen und . - _ °',
            alphaNumOnly: 'Erlaubte Zeichen: Buchstaben, Zahlen, - und _ (keine Leerschläge)',
            alphaNumDot: 'Erlaubte Zeichen: Buchstaben, Zahlen und .',
            orgNameOnly: 'Erlaubte Zeichen: Buchstaben, Zahlen und -, wobei der - nicht am Anfang oder Ende sein darf',
            objectNameAlreadyExists: 'Doppelter Name: Es existiert bereits ein Objekt mit diesem Namen',
            atMostCharacters: 'Die maximale Textlänge von {num} Zeichen wurde überschritten',
            appNameAlreadyExists: 'Doppelter Name: Es existiert bereits eine App mit diesem Namen',
            projectNameAlreadyExists: 'Doppelter Name: Es existiert bereits ein Projekt mit diesem Namen',
            sfxdataNameAlreadyExists: 'Doppelter Name: Es existiert bereits ein Asset mit diesem Namen',
            formNameAlreadyExists: 'Doppelter Name: Es existiert bereits ein Asset mit diesem Namen',
            mediaNameAlreadyExists: 'Doppelter Name: Es existiert bereits ein Asset mit diesem Namen',
            datasetNameAlreadyExists: 'Doppelter Name: Es existiert bereits eine Tabelle mit diesem Namen',
            materialmapperNameAlreadyExists: 'Doppelter Name: Es existiert bereits eine Material Mapper mit diesem Namen',
            datasourceNameAlreadyExists: 'Doppelter Name: Es existiert bereits ein Asset mit diesem Namen',
            required: 'Dieses Feld muss ausgefüllt werden',
            fileTypeNotAllowed: 'Dieser Dateityp kann hier nicht hochgeladen werden. Erlaubte Dateiendungen: {fileTypes}',
            fileTooBig: 'Ihre Datei ({{filesize}}) übersteigt die Maximalgrösse von {{maxFilesize}} MB',
            userPassOrNameIsWrong: 'Der Benutzername oder das Passwort wurden nicht akzeptiert.',
            userIsBlocked: 'Der Nutzer ist aufgrund zu vieler Fehlversuche blockiert worden. Bitte wenden Sie sich' +
                ' an den Administrator',
            mustBeNumeric: 'Der Wert muss eine Zahl sein',
            mustBeAValidURL: 'Bitte geben Sie eine gültige URL im Format https://www.test.ch ein',
        },
        nav: {
            saasServices: 'SAAS SERVICES',
            saasAgb: 'SAAS AGB',
            csv_import: 'CSV Import',
            createVirtualMachine: 'VM erstellen',
            vmOverview: 'VM Übersicht',
            vmUsageAll: 'VM ALL',
            myVmUsage: 'VM Nutzung',
            vmUsage: 'VM Nutzung',
            adminproject: 'General Projects',
            taskmanager: 'Taskmanager',
            scene_general: 'Allgemein',
            scenebackbutton: 'Zurück',
            vmbackbutton: 'Zurück',
            scene: 'Szene',
            scenes: 'Szenen',
            service_edit: 'Allgemeines',
            rolebackbutton: 'Zurück',
            permissionsbackbutton: 'Zurück',
            permission_edit: 'Allgemeines',
            permissions: 'Berechtigungen',
            roles: 'Rollen',
            material_general: 'Allgemeines',
            materials: 'Materialien',
            team_general: 'Allgemeines',
            teams: 'Teams',
            organizing: 'Struktur',
            qrcodes: 'QR-Codes',
            materialmappers: 'Material-Mapper',
            services: 'Services',
            materialbackbutton: 'Zurück',
            materialmapbackbutton: 'Zurück',
            servicebackbutton: 'Zurück',
            teambackbutton: 'Zurück',
            sfxdata_export_profiles: 'Exportprofile',
            files: 'Dateien',
            access_denied: 'Kein Zugriff',
            users: 'Benutzer',
            tags: 'Tags',
            admin: 'Admin',
            form_general: 'Allgemeines',
            formbackbutton: 'Zurück',
            projects: 'Projekte',
            project: 'Projekte',
            media: 'Medien',
            forms: 'Formulare',
            addMedia: 'Medien-Objekt erstellen',
            uploadMediaFile: 'Medien-Datei hochladen',
            logout: 'abmelden',
            apps: 'Applikationen',
            app: 'Applikationen',
            account: 'Benutzerkonto',
            organizations: 'Organisationen',
            Organization: 'Organisation',
            project_scene: "Szene",
            project_general: "Allgemeines",
            project_3ddata: "3D-Daten",
            project_media: 'Medien',
            project_datasets: "Datenquellen",
            project_datasources: "Datenquellen",
            report_general: "Allgemeines",
            sfxdata_general: "Allgemeines",
            sfxdata_general_assembly: "Allgemeines",
            sfxdata_data_sets: "Datenquellen",
            sfxdata_media: "Medien",
            sfxdata: "3D-Daten",
            app_general: "Allgemeines",
            media_general: "Allgemeines",
            user_general: "Allgemeines",
            app_project: "Projekt",
            data: "Daten",
            data_tables: "Tabellen",
            data_sources: "Datenquellen",
            datasources: "Datenquellen",
            data_sources_general: "Allgemeines",
            datasets: "Tabellen",
            dataset_general: "Allgemeines",
            resources: "Resourcen",
            library: "Bibliothek",
            reports: "Statistiken",
            report: "Statistiken",
            modelbackbutton: "Zurück",
            assemblybackbutton: "Zurück",
            reportbackbutton: "Zurück",
            projectsbackbutton: "Zurück",
            projectbackbutton: "Zurück",
            appbackbutton: "Zurück",
            webappbackbutton: "Zurück",
            sfxbackbutton: "Zurück",
            mediabackbutton: "Zurück",
            userbackbutton: "Zurück",
            datasetbackbutton: "Zurück",
            datasourcebackbutton: "Zurück",
            metasetbackbutton: "Zurück",
            textbackbutton: "Zurück",
            organizationbackbutton: "Zurück",
            qrcodebackbutton: "Zurück",
            materialmapperbackbutton: "Zurück",
            sfxdata_linked_data: "Verknüpfte 3D-Daten",
            texts: "Texte",
            text_general: "Allgemein",
            qrcode_general: "Allgemein",
            materialmapper_general: "Allgemein",
            project_linked_data: "Verknüpfte Daten",
            sfxdata_connection_list: "Alle Verknüpfungen",
            sfxdata_assembly_update: "Update",
            addnew3ddata: "Neues 3D-Objekt",
            Upload: "Datei hochladen",
            Config: "Konfiguration",
            metasets: "Metasets",
            metaSets: "Metasets",
            webapp_linked_data: "Verknüpfte Daten",
        },
        events: {
            instanceUpdated: 'Die Instanz wurde aktualisiert',
            metaValuesUpdated: 'Ein Meta-Wert wurde aktualisiert',
            tagUpdated: 'Der Tag wurde aktualisiert',
            metaFieldCreated: 'Ein Meta-Feld wurde erstellt',
            metaSetCreated: 'Ein Metaset wurde erstellt',
            appUpdated: 'Die Applikation wurde aktualisiert.',
            appCreated: 'Eine neue App wurde erstellt.',
            appDeleted: 'Die App wurde gelöscht',
            appMetaValuesUpdated: 'Der Wert wurde aktualisiert.',
            dataSetCreated: 'Das Dataset wurde erstellt.',
            dataSetDeleted: 'Das Dataset wurde gelöscht',
            dataSetUpdated: 'Das Dataset wurde aktualisiert.',
            dataSourceMetaValuesUpdated: 'Der Wert wurde aktualisiert.',
            dataSourceCreated: 'Die Datenquelle wurde erstellt.',
            dataSourceDeleted: 'Die Datenquelle wurde gelöscht',
            dataSourceUpdated: 'Die Datenquelle wurde aktualisiert.',
            datasetMetaValuesUpdated: 'Der Wert wurde aktualisiert.',
            formUpdated: 'Das Formular wurde aktualisiert.',
            formCreated: 'Ein neues Formular wurde erstellt.',
            mediaCreated: 'Ein neues Medien-Objekt wurde erstellt.',
            mediaDeleted: 'Das Medien-Objekt wurde gelöscht',
            mediaUpdated: 'Das Medien-Objekt wurde aktualisiert.',
            mediaMetaValuesUpdated: 'Der Wert wurde aktualisiert.',
            metaFieldDeleted: 'Das MetaField wurde gelöscht',
            metaFieldUpdated: 'Das MetaField wurde aktualisiert',
            organizationUpdated: 'Die Organisation wurde aktualisiert',
            organizationCreated: 'Die Organisation wurde erstellt.',
            organizationDeleted: 'Die Organisation wurde gelöscht.',
            pictureRemoved: 'Das Bild wurde entfernt.',
            projectCreated: 'Ein neues Projekt wurde erstellt.',
            projectDeleted: 'Das Projekt wurde gelöscht',
            projectUpdated: 'Das Projekt wurde aktualisiert.',
            projectMetaValuesUpdated: 'Der Wert wurde aktualisiert.',
            qrcodeCreated: 'Ein neuer QR-Code wurde erstellt.',
            qrcodeDeleted: 'Der QR-Code wurde gelöscht',
            qrcodeUpdated: 'Der QR-Code wurde aktualisiert.',
            qrcodeMetaValuesUpdated: 'Der Wert wurde aktualisiert.',
            reportCreated: 'Ein neuer Report wurde erstellt.',
            reportUpdated: 'Der Report wurde aktualisiert.',
            reportMetaValuesUpdated: 'Der Wert wurde aktualisiert.',
            sceneCreated: 'Eine neue Szene wurde erstellt.',
            sceneDeleted: 'Die Szene wurde gelöscht',
            sceneUpdated: 'Die Szene wurde aktualisiert.',
            sceneMetaValuesUpdated: 'Der Wert wurde aktualisiert.',
            sfxDataCreated: 'Ein neues 3D-Objekt wurde erstellt.',
            sfxDataDeleted: 'Das 3D-Objekt wurde gelöscht',
            sfxDataUpdated: 'Das 3D-Objekt wurde aktualisiert.',
            sfxDataMetaValuesUpdated: 'Der Wert wurde aktualisiert.',
            tagDeleted: 'Der Tag wurde gelöscht.',
            tagsAdded: 'Der Inhalt wurde getaggt',
            tagCreated: 'Der Tag wurde erstellt',
            tagsRemoved: 'Der Tag wurde entfernt',
            textUpdated: 'Der Text wurde aktualisiert.',
            textCreated: 'Der Text wurde erstellt.',
            userUpdated: 'Die Nutzerdaten wurden aktualisiert',
            userCreated: 'Ein neuer Nutzer wurde angelegt',
        },
        backtooverview: 'Zurück zur Übersicht',
        projectloading: 'Projekt lädt',
        apploading: 'Applikation lädt',
        STATUS: 'STATUS',
        CONTRIBUTORS: 'ERSTELLT VON',
        entertitlehere: 'Titel hier eingeben',
        enterdeschere: 'Beschreibung hier eingeben',
        downloadType: '{type} herunterladen',
        createNewType: '{type} erstellen',
        selectedType: 'Ausgewähltes {type}',
        addNewType: '{type} hinzufügen',
        deleteType: '{type} löschen',
        createNewTypeFile: '{type} erstellen',
        updateType: '{type} aktualisieren',
        createNewApp: 'Neue App erstellen',
        createNewReport: 'Neue Statistik anlegen',
        createNewProject: 'Neues Projekt erstellen',
        createNewSfxdata: 'Neue 3D-Daten importieren',
        createNewMedia: 'Neue Medien-Datei erstellen',
        createNewUser: 'Neuen Benutzer anlegen',
        createNewMetaField: 'Neues MetaField anlegen',
        createNewMetaSet: 'Neues MetaSet anlegen',
        createNewOrganization: 'Neue Organisation erstellen',
        createNewText: 'Neuen Textblock erstellen',
        createNewQrCode: 'Neuen QR Code erstellen',
        createNewMaterialMapper: 'Neuer Material-Mapper',
        createNewForm: 'Neues Formular erstellen',
        SAVE: 'SPEICHERN',
        REMOVE: 'ENTFERNEN',
        makeCodePretty: 'Code formatieren',
        items: 'Objekte',
        imageupload: 'Bilddatei hierher ziehen oder klicken',
        fileupload: 'Datei hierher ziehen oder klicken',
        Media: "Medien",
        Datasets: "Datenquellen",
        ChooseProjectForApp: 'Wählen Sie ein Basis-Projekt für diese App',
        deletefield: 'FELD LÖSCHEN',
        Anumberbetween: 'Eine Zahl zwischen {num} und {num2}',
        duplicateFileError: 'Eine Datei mit dem Namen {fileName} existiert bereits. Bitte benennen Sie Ihre Datei um oder wählen sie eine andere Datei aus.',
        addnewfield: 'Neues Feld hinzufügen',
        addnewcolumn: 'Neue Spalte hinzufügen',
        savefield: 'Feld speichern',
        editfield: 'Feld "{field}" bearbeiten',
        fieldCannotBeEditedWhenData: 'Das Feld kann nicht bearbeitet werden, wenn in der Tabelle bereits Daten dazu befüllt sind.',
        NotANumber: 'Der angegebene Wert ist keine Zahl',
        createnew: 'Neu hinzufügen',
        filtering: 'Filtern',
        resources: 'Resourcen',
        library: "Bibliothek",
        addconnection: "Verknüpfung wird hinzugefügt",
        removeconnection: "Verknüpfung wird entfernt",
        LinkedData: 'Verknüpfte Daten',
        LocalLinkedItems: 'Verknüpfte Daten',
        createNewDataSet: 'Neue Tabelle erstellen',
        createNewDataSource: 'Neue Datenquelle erstellen',
        forms: {
            isDefaultOrganization: 'Ist die Standardorganisation',
            default: 'Standard',
        },
        meta: {
            type: {
                int: 'Ganzzahl',
                str: 'Zeichenkette',
                json: 'JSON',
                float: 'Gleitkommazahl',
                enum: 'Aufzählung',
                dataset: 'Tabelle',
                datarow: 'Daten-Zeile',
                bool: 'Wahrheitswert',
                clr: 'Farbe',
                v2: 'Vektor 2',
                v3: 'Vektor 3',
                v4: 'Vektor 4',
                time: 'Zeit',
                datetime: 'Zeit und Datum',
                date: 'Datum',
                project: 'Projekt',
                password: 'Passwort',
                repeatPassword: 'Passwort wiederholen',
                xform3: 'Xform3',
                xform2: 'Xform2',
                email: 'Email',
                ip: 'IP',
                ipv6: 'Ipv6',
                uri: 'Uri',
                range: 'Bereich',
                asset: {
                    feed: 'Feed',
                    sensor: 'Sensor'
                },
            }
        },
        'Legacy project id': 'Legacy Projekt-ID',
        Type: 'Typ',
        'Last Update': 'Mut. Datum',
        Edit: 'Aktionen',
        delete: 'Löschen',
        assembly: '3D Gruppe',
        helper: 'vHelper',
        model: '3D Element',
        orgVisibilityTypes: {
            '0': 'Privat',
            '1': 'Öffentlich',
            '2': 'Organisationsübergreifend',
        },
        teamVisibilityTypes: {
            '0': 'privat',
            '1': 'öffentlich',
            '2': 'global (neue User werden autom. hinzugefügt)'
        },
        mediaCategory: {
            '0': 'Applikation',
            '1': 'Audio',
            '2': 'Schriftart',
            '3': 'Bild',
            '5': 'Message',
            '6': 'Model',
            '7': 'MultiPart',
            '8': 'Text',
            '9': 'Video'
        }
    }
};



const i18n = new VueI18n({
    locale: 'de', // set locale
    messages, // set locale messages
    silentTranslationWarn: true, //remove the annying messages about strings not being strings when in fact they ARE strings
});
export default i18n;