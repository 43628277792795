<template>
    <div class="orange-switch">
        <select class="custom-select"
                ref="orgswitch"
                v-model="updateValue">
            <option v-for="(langItem, langKey, index) in languages"
                    v-if="langItem.active && (!exclude || !exclude.includes(langKey))"
                    :key="index"
                    :value="langKey">
                {{ langItem.name }}
            </option>
        </select>
    </div>
</template>

<script>
    import {Languages, PlainLanguages} from '@/enum';

    export default {
        name: "LanguageSelector",
        components: {
            // eslint-disable-next-line vue/no-unused-components
            Languages,
          // eslint-disable-next-line vue/no-unused-components
            PlainLanguages,
        },
        props: {
            value: { type: String, default: null},
            usePlainLanguage: { type: Boolean, default: false},

            /*
            * Add language keys to exclude
            * **/
            exclude: {type: Array, default: null}
        },
        data() {
            return {
                updateValue: '',
                languages: {},
            };
        },
        watch: {
            value: function() {
                this.updateValue = this.value;
            },
            updateValue() {
              if(this.updateValue && (this.updateValue !== this.value)) {
                this.$emit('update', this.updateValue);
              }
            }
        },
        beforeMount() {
            this.updateValue = this.value;
            this.languages = this.usePlainLanguage ? PlainLanguages : Languages;
        },

    }
</script>

<style scoped>

</style>