<template>
<div class="d-flex">
  <preview
    v-if="getImage(config, lang)"
    :preview-id="getImage(config, lang)"
    source="asset"
    :asset-id="getImage(config, lang)"
    class="mini mr-2 align-self-end"
     />
  <div>
    <label>{{ getLabel(config, lang) }}{{ getRequired(config) ? "*" : "" }}</label>
  <input v-model="value" :class="['form-text', markAsMissing ? 'vform-error' : '']" type="text" @change="$emit('setValue', value)" />
  </div>
  </div>
</template>

<script>
import UtilsMixinJs from "@/components/vForm/viewer/UtilsMixin.js";
import Preview from "@/components/preview/Preview";

export default {
  name: "SHORT_ANSWER",
  mixins: [UtilsMixinJs],
  components: {
    Preview
  },
  props: {
    config: {type: Object, required: true},
    lang: {type: String, required: true}
  },
  data() {
    return {
      value: '',
      markAsMissing: false
    };
  },
  methods: {
    validate() {
        return this.validateOptions();
    },
    checkRequired() {
      this.markAsMissing = !this.value;
      return !!this.value;
    },
    getLogEntries() {
        return [];
    }
  }
}
</script>

<style scoped>
  .form-text {
    border: 1px solid #908b7f;
  }
  label {
    margin-bottom:0;
    font-weight: bold;
  }
</style>