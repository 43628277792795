<template>
    <div class="dataset">
        <popup
            @close="
                () => {
                    showDeletePrompt = false;
                }
            "
            v-if="showDeletePrompt"
        >
            <delete-prompt
                slot="popupMainContent"
                @abort="
                    () => {
                        showDeletePrompt = false;
                        indexToDeleteConfirm = null;
                    }
                "
                @confirm="
                    () => {
                        showDeletePrompt = false;
                        deleteDataRowFinally();
                    }
                "
            />
        </popup>
        <popup
            @close="
                () => {
                    showLeavePagePrompt = false;
                }
            "
            v-if="showLeavePagePrompt"
        >
            <delete-prompt
                custom-message="reloadMappingsWarningLoseChanges"
                slot="popupMainContent"
                @abort="
                    () => {
                        showLeavePagePrompt = false;
                    }
                "
                @confirm="
                    () => {
                        showLeavePagePrompt = false;
                        loadMappings();
                    }
                "
            />
        </popup>
        <div class="row">
            <div class="cell col-3 pl-0">
                <h3 class="sourceTitle">{{ $t("matMapperLoadFromServer") }}</h3>
                <span>{{ $t("type.MetaSet") }}</span>
                <select
                    class="custom-select col-12 mb-2"
                    v-model="selectedMetaSetId"
                    v-on:change="loadMetaFields()"
                >
                    <option
                        :key="index"
                        v-for="(item, index) in $store.getters.getMetaSets"
                        :value="item.id"
                    >
                        {{ item.description ? item.description : item.name }}
                    </option>
                </select>

                <span>{{ $t("metaSetMaterialField") }}</span>
                <select class="custom-select col-12" v-model="selectedMetaFieldId">
                    <option
                        :key="index"
                        v-for="(item, index) in $store.getters.getMetaFieldsByListName(
                            this.selectedMetaSetId
                        )"
                        :value="item.id"
                    >
                        {{ item.description ? item.description : item.name }}
                    </option>
                </select>
                <Button
                    slot="buttonsRight"
                    class="mb-3 mt-3"
                    @click="loadMaterialNamesFromMetaSets"
                    :deactivated="disableLoadFromMetaSets"
                >
                    <icon type="" />{{ $t("loadMaterials") }}
                </Button>
            </div>

            <div class="cell col-3">
                <h3 class="sourceTitle">{{ $t("matMapperLoadFromXML") }}</h3>
                <div class="tiny-dropzone">
                    <upload-drop-zone
                        :auto-upload="false"
                        :organization-id="this.organizationId"
                        @data="parseXmlBytes"
                        ref="uploadComponent"
                        target-type="XMLAsset"
                        form-id="XMLUpload"
                        accepted-file-types=".xml"
                        :removable="false"
                        :make-chunks="true"
                        :auto-update-preview="false"
                    />
                </div>
                <span class="lighter mt-4">{{ $t("PostProcessingForMeta") }}</span>
                <select class="custom-select mb-2" v-model="xmlFormat">
                    <option
                        :key="key"
                        v-for="(key, value) in xmlFormatOptions"
                        :value="value"
                    >
                        {{ $t(key.name) }}
                    </option>
                </select>
                <label v-if="validationError" class="form-error error"
                    >{{ this.validationError }}
                </label>
                <span>{{ $t("selectMaterialColumn") }}</span>
                <select class="custom-select col-12" v-model="xmlMaterialColumnIndex">
                    <option
                        :key="index"
                        v-for="(item, index) in this.xmlColumns"
                        :value="index"
                    >
                        {{ item }}
                    </option>
                </select>
                <Button
                    slot="buttonsRight"
                    class="mb-3 mt-3"
                    @click="loadMaterialNamesFromXml"
                    :deactivated="disableLoadFromXml"
                >
                    <icon type="" />{{ $t("loadMaterials") }}
                </Button>
            </div>
        </div>
        <div class="col-8">
            <div class="row">
                <Button @click="reload" type="reload" icon="redo" class="icon-button" />
                <!-- <Button
                        slot="buttonsRight"
                        class="mb-3 mt-3 mr-3"
                        @click="cancelChanges"
                        :deactivated="!hasUnsavedItems"
                    > -->
                <pagination
                    slot="mainContentButtons"
                    :limit="limit"
                    :total="mappings.length"
                    :id="MaterialList"
                    @setActive="showPageItems"
                    :localPagination="true"
                    ref="pagination"
                />
            </div>

            <div class="row table-header">
                <div class="cell col-5 center-vertical pl-3">
                    {{ $t("materialName") }}
                </div>
                <div class="cell col-5 center-vertical pl-1">
                    {{ $t("materialRemapWith") }}
                </div>
                <div class="cell col-2 center-vertical"></div>
            </div>
            <div
                class="row pl-3 table-row"
                v-if="item.visible"
                v-for="(item, index) in mappings"
                :key="index"
            >
                <div class="cell col-5 pr-2">
                    <div
                        v-if="!item.editMode"
                        class="edit-trigger mr-3 center-vertical"
                        @click="editRow(index)"
                    >
                        <icon type="edit" />
                    </div>

                    <span class="center-vertical" v-if="!item.editMode">{{
                        item.data[0]
                    }}</span>
                    <div
                        v-if="item.editMode"
                        class="edit-trigger mr-3 center-vertical"
                        @click="cancelEdit(index)"
                    >
                        <icon type="close" />
                    </div>
                    <input
                        v-if="item.editMode"
                        class="form-text"
                        :id="item.id"
                        type="text"
                        v-model="item.data[0]"
                        v-on:change="markRowAsDirty(index)"
                        :ref="'entry'"
                    />
                </div>
                <div class="cell col-5">
                    <select
                        v-model="item.data[1]"
                        class="custom-select"
                        v-on:change="markRowAsDirty(index)"
                        v-bind:class="{ dirty: item.dirty === true }"
                    >
                        <option
                            class="form-text mb-2"
                            v-for="(material, matIndex) in materials"
                            :key="matIndex"
                            :value="material.id"
                        >
                            {{ material.displayName }}
                        </option>
                    </select>
                </div>
                <div class="col-2">
                    <Button slot="buttonsRight" class="" @click="deleteRow(index)">
                        <icon type="" />{{ $t("Delete") }}
                    </Button>
                </div>
            </div>
            <div class="row">
                <div class="col-5">
                    <Button
                        slot="buttonsLeft"
                        class="mb-3 mt-3 mr-3"
                        @click="addMappingRow"
                    >
                        <icon type="plus" />{{ $t("addrow") }}
                    </Button>
                </div>
                <div class="col-7 pr-0 flex-right">
                    <Button
                        slot="buttonsRight"
                        class="mb-3 mt-3 mr-3"
                        @click="cancelChanges"
                        :deactivated="!hasUnsavedItems"
                    >
                        <icon type="" />{{ $t("Cancel") }}
                    </Button>
                    <Button
                        slot="buttonsRight"
                        class="mb-3 mt-3"
                        @click="saveAllRows"
                        :deactivated="!hasUnsavedItems"
                    >
                        <icon type="" />{{ $t("assignMaterials") }}
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "../../forms/Button";
import Popup from "../../Popup";
import Icon from "../../Icon";
import DeletePrompt from "@/components/forms/DeletePrompt";
import { getXMLColumns } from "@/components/fileProcessors/xmlWindchillSearchRes";
import { getXMLObject } from "@/components/fileProcessors/xmlWindchillSearchRes";
import UploadDropZone from "@/components/files/UploadDropZone";
import { metaDataFileFormats } from "@/enum";
import Pagination from "../../Pagination";
import ArrayMixin from "@/components/mixins/ArrayMixin.js";
//import { onBeforeRouteLeave } from 'vue-router'
// import { saveAs } from 'file-saver';
// import {SpecialUuids} from "@/enum";
// import LoadingSpinner from "@/components/LoadingSpinner";

export default {
    // setup() {
    //     // same as beforeRouteLeave option with no access to `this`
    //     onBeforeRouteLeave((to, from) => {
    //         const answer = window.confirm(
    //             "Do you really want to leave? you have unsaved changes!" + to + from
    //         );
    //         // cancel the navigation and stay on the same page
    //         if (!answer) return false;
    //     });
    // },

    name: "MaterialMapper",
    components: {
        Button,
        Popup,
        Icon,
        DeletePrompt,
        Pagination,
        UploadDropZone,
        //ArrayMixin,
        // DatasetRowTaskmanager,
        // LoadingSpinner
    },
    mixins: [ArrayMixin],
    props: {
        id: { type: String, required: true },
        limit: { type: Number, required: false, default: 10 },
    },
    data() {
        return {
            mappings: [],
            materials: [],
            forceReRenderKey: 1,
            materialListName: "MaterialList",
            selectedMetaSetId: "",
            selectedMetaFieldId: "",
            showDeletePrompt: false,
            indexToDeleteConfirm: -1,
            organizationId: "",
            xmlColumns: [],
            xmlMaterialColumnIndex: null,
            xmlRows: [],
            xmlFormat: "none",
            xmlFormatOptions: {
                // none: {
                //     name: "none",
                // },
                ...metaDataFileFormats,
            },
            validationError: "",
            showLeavePagePrompt: false,
        };
    },
    computed: {
        disableLoadFromXml: function () {
            return this.validationError || !this.xmlMaterialColumnIndex;
        },
        disableLoadFromMetaSets: function () {
            return !this.selectedMetaSetId || !this.selectedMetaFieldId;
        },
        hasUnsavedItems: function () {
            return this.mappings.some((x) => x.dirty === true);
        },
        filteredMappings: function () {
            return this.mappings;
        },
    },
    watch: {},

    beforeMount() {
        this.xmlFormat = Object.keys(this.xmlFormatOptions)[0];
        this.organizationId = this.$store.getters.getMaterialmapperOrganization(this.id);
        this.loadMetaSets();
        this.loadMaterials();
        this.loadMappings();
    },
    methods: {
        loadMetaSets() {
            let args = { add: {} };
            args.add.filter = { default: "organizationId eq " + this.organizationId };
            this.$store.dispatch("loadMetaSets", args);
        },
        loadMetaFields() {
            this.$store.dispatch("loadMetaFields", {
                keep: {
                    id: this.selectedMetaSetId,
                },
                listName: this.selectedMetaSetId,
            });
        },
        loadMaterialNamesFromMetaSets() {
            this.$store
                .dispatch("getMetaValues", {
                    id: this.selectedMetaSetId,
                    cid: this.selectedMetaFieldId,
                })
                .then((data) => {
                    let rowsToAdd = [];
                    for (let index = 0; index < data.length; index++) {
                        const element = data[index];
                        if (
                            !this.mappings.find((m) => m.data[0].toLowerCase() === element.value.toLowerCase()) &&
                            !rowsToAdd.find((m) => m.data[0].toLowerCase() === element.value.toLowerCase())
                        ) {
                            var row = {
                                id: 0,
                                data: { 0: element.value, 1: "" },
                                editMode: false,
                                initialClientMaterialName: element.value,
                                dirty: true,
                            };
                            rowsToAdd.push(row);
                        }
                    }

                    rowsToAdd.sort(this.objectComparer("initialClientMaterialName"));
                    rowsToAdd.forEach((element) => {
                        this.mappings.push(element);
                    });

                    this.$refs.pagination.goToLastPage();
                });
        },
        loadMaterials() {
            let args = {
                listName: this.materialListName,
            };
            this.$store.dispatch("loadMaterials", args).then((data) => {
                data.sort(this.objectComparer("name"));
                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    this.materials.push({
                        id: element.id,
                        displayName: element.name,
                    });
                }
            });
        },
        showPageItems(offset) {
            for (let index = 0; index < this.mappings.length; index++) {
                this.mappings[index].visible = false;
            }

            for (let index = offset; index < offset + this.limit; index++) {
                this.mappings[index].visible = true;
            }
        },
        reload() {
            if (this.hasUnsavedItems) {
                this.showLeavePagePrompt = true;
            } else {
                this.loadMappings();
            }
        },
        cancelChanges() {
            if (this.hasUnsavedItems) {
                this.showLeavePagePrompt = true;
            }
        },
        loadMappings() {
            let args = {
                listName: this.id,
                keep: {
                    id: this.id,
                    datasetId: this.id,
                    limit: 100,
                },
                add: {
                    sort: {
                        [this.id]: "createdAt",
                    },
                },
            };

            this.$store.dispatch("loadDatasetRows", args).then((data) => {
                var rows = [];

                data.forEach(function (item) {
                    var row = {
                        id: 0,
                        data: {},
                        editMode: false,
                        initialClientMaterialName: item.data[0],
                        visible: false,
                        dirty: false,
                    };
                    row.id = item.id;
                    Object.assign(row.data, item.data);
                    rows.push(row);
                });

                rows.sort(this.objectComparer("initialClientMaterialName"));
                this.mappings = rows;
                this.showPageItems(0);
                this.$refs.pagination.setActive(0);
            });
        },
        markRowAsDirty(index) {
            this.mappings[index].dirty = true;
        },
        editRow(index) {
            this.mappings[index].editMode = true;
        },
        deleteRow(index) {
            this.showDeletePrompt = true;
            this.indexToDeleteConfirm = index;
        },
        deleteDataRowFinally() {
            if (this.indexToDeleteConfirm > -1) {
                var dataRow = this.mappings[this.indexToDeleteConfirm];
                this.$store
                    .dispatch("deleteDatasetRow", {
                        id: this.id,
                        args: [dataRow.id],
                        listName: this.id,
                    })
                    .then(() => {
                        this.mappings.splice(this.indexToDeleteConfirm, 1);
                        this.indexToDeleteConfirm = -1;
                    });
            }
        },
        async saveAllRows() {
            for (let index = 0; index < this.mappings.length; index++) {
                const row = this.mappings[index];
                if (row.dirty || !row.id) {
                    //save dirty or new
                    await this.saveRow(index);
                }
            }

            this.loadMappings();
        },
        async saveRow(index) {
            var row = this.mappings[index];
            if (row.id) {
                //update
                let params = {
                    id: this.id,
                    data: { materialName: row.data[0], mappedMaterial: row.data[1] }, //replace by complete datarow
                    datarowId: row.id,
                    listName: this.id,
                };
                return this.$store.dispatch("updateDatasetRow", params).then(() => {});
            } else {
                //create
                return this.$store
                    .dispatch("createDatasetRow", {
                        id: this.id,
                        data: row.data,
                    })
                    .then(() => {});
            }
        },
        cancelEdit(index) {
            this.mappings[index].data[0] = this.mappings[index].initialClientMaterialName;
            this.mappings[index].editMode = false;
        },
        addMappingRow() {
            let el = {
                data: {
                    0: "",
                    1: "",
                },
                editMode: true,
                initialClientMaterialName: "",
                visible: false,
                dirty: true,
            };
            this.mappings.push(el);
            this.$refs.pagination.goToLastPage();
        },

        async parseXmlBytes(xmlBytes) {
            const text = new TextDecoder("utf-8").decode(xmlBytes);
            this.validationError = null;
            try {
                const columns = await getXMLColumns(text);
                if (columns) {
                    this.xmlColumns = columns;
                }

                const objects = await getXMLObject(text);
                if (objects) {
                    this.xmlRows = objects;
                }
            } catch (e) {
                this.xmlColumns = [];
                this.validationError = "The xml is not in a valid format";
            }
        },
        loadMaterialNamesFromXml() {
            let rowsToAdd = [];
            for (let index = 0; index < this.xmlRows.length; index++) {
                const element = this.xmlRows[index];
                var materialName = element.Attribute[this.xmlMaterialColumnIndex];
                if (materialName) {
                    if (
                        !this.mappings.find(
                            (m) => m.data[0].toLowerCase() === materialName.toLowerCase()
                        ) &&
                        !rowsToAdd.find(
                            (m) => m.data[0].toLowerCase() === materialName.toLowerCase()
                        )
                    ) {
                        let row = {
                            data: { 0: materialName, 1: "" },
                            editMode: false,
                            initialClientMaterialName: materialName,
                            dirty: true,
                            visible: false,
                        };
                        rowsToAdd.push(row);
                    }
                }
            }

            rowsToAdd.sort(this.objectComparer("initialClientMaterialName"));
            rowsToAdd.forEach((element) => {
                this.mappings.push(element);
            });

            this.$refs.pagination.goToLastPage();
        },
    },
};
</script>

<style lang="scss">
.tiny-dropzone {
  .square-image {
    padding-top: 150px !important;
  }
  .dropzone-wrapper {
    height: 150px;
  }
  .dropzone-outer {
    height: 150px;
  }
}
</style>

<style lang="scss" scoped>
.center-vertical {
    margin-top: auto;
    margin-bottom: auto;
}
.custom-select {
    height: 40px;
}
label {
    display: block;
}
.cell {
    width: 400px;
    display: block;
}

.table-row .cell {
    display: flex;
}
.row {
    display: flex;
    margin: 10px 0 0 0;
    width: 100%;
}

.select {
    height: 40px;
    width: 100%;
    margin-bottom: 8px;
}

div.content {
    background-color: red !important;
}

.dirty {
    background-color: #ddd;
}

.col-5 {
    padding: 0px;
}

.sourceTitle {
    background-color: black;
    padding: 7px;
}
.error {
    max-width: 100%;
}
.table-header {
    height: 40px;
    background-color: #000;
}
.table-row {
    display: flex;
    background: #222;
    padding: 5px;
}
.flex-right {
    justify-content: right;
    display: flex;
}
</style>
