<template>
<div>
  {{ getLabel(config, lang) }}
  <qrcode-stream v-if="isActive" @decode="onDecode"></qrcode-stream>
</div>
</template>

<script>
import UtilsMixinJs from "@/components/vForm/viewer/UtilsMixin.js";
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
  name: "QrCode",
  props: {
    config: {type: Object, required: true},
    lang: {type: String, required: true},
    isActive: {type: Boolean, default: false},
    projectId: {type: String, required: true},
  },
  components: {
    QrcodeStream
  },
  data() {
    return {
      compareString: ""
    };
  },
  mixins: [UtilsMixinJs],
  mounted() {
  },
  methods: {
    onDecode (decodedString) {
      console.log(decodedString);
      const split = decodedString.split(':');
      console.log(split);
      const action = split[0];
      const parameter = split[1];
      if(action === 'asset') {
        console.log(this.projectId);
        this.$store.dispatch('clientGetCrossProjectInstances', {id: this.projectId, filter: 'assetId eq ' + parameter, include:'metaValues'}).then(asset => {
            console.log(asset);
            asset = asset[0];
            const metaValues = asset.metaValues;
            console.log(metaValues);
            if(metaValues) {
              let c;
              let a;
              const compareStringArr = metaValues.filter(item => {return item.metaFieldId === "5163a871-3c59-4f84-a9eb-7a001e192b47"});
              if(compareStringArr[0]) {
                c = compareStringArr[0].value;
              }
              const actionArr = metaValues.filter(item => {return item.metaFieldId === "dea518f2-1daa-4daf-816a-1cf39d9fef5e"});
              if(actionArr[0]) {
                a = actionArr[0].value;
              }

              console.log('comparing ' + c);
              console.log('action: ' + a);
              this.compareString = c;
              if(this.validateQRCode(this.config, c) && a) {
                //this.$emit('goToSlide', );
                const todo = a.split(':');
                console.log('going to slide!!' + todo[1])
                this.$emit('goToSlideNo', todo[1]);
              }
            }
        })
      }
    },
    validate() {
        return this.validateQRCode(this.config, this.compareString);
    },
    checkRequired() {
       if(this.getRequired(this.config)) {
        return false;
       }
    },
    getLogEntries() {
        return [];
    }
  }
}
</script>

<style scoped>

</style>