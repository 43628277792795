<template>
  <div>
    <preview
      v-if="getImage(config, lang)"
      :preview-id="getImage(config, lang)"
      source="asset"
      :asset-id="getImage(config, lang)"
      :removable="false"
     />
  </div>
</template>

<script>
import UtilsMixinJs from "@/components/vForm/viewer/UtilsMixin.js";
import Preview from "@/components/preview/Preview";

export default {
  name: "IMAGE",
  components: {Preview},
  mixins: [UtilsMixinJs],
  props: {
    config: {type: Object, required: true},
    lang: {type: String, required: true}
  },
  methods: {
    validate() {
        return true;
    },
    checkRequired() {
      return true;
    },
    getLogEntries() {
        return [];
    }
  }
}
</script>

<style scoped>

</style>