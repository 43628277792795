<template>
  <div>
    <popup
        @close="() => {showDeletePrompt = false;}"
        v-if="showDeletePrompt"
    >
      <delete-prompt slot="popupMainContent"
                     @abort="() => {showDeletePrompt = false;}"
                     @confirm="() => {deleteEntry(); showDeletePrompt = false;}"
      />
    </popup>
        <!--<div class="mb-3"><span class="lighter">Status:</span> {{ getState(entry.state).toLowerCase() }}</div>-->

      <div class="p-2 pt-3 mb-1 bg-dark">
        <Button class="icon-button float-right" @click="showDeletePrompt = true" v-if="$store.getters.isSuperAdmin"><icon type="trash-alt" /></Button>
        <div class="float-right col-5 text-right" style="margin-top:-11px; margin-bottom:8px;"><span class="lighter">Billing state:</span>
          <b v-if="!billingStateEditable">{{ getBillingState(entry.billingState).toLowerCase() }}</b>
          <div v-else>
             <select @change="setBillingState" class="custom-select d-inline-block col-12 col-md-4" v-model="billingState">
                <option :value="value" :key="index" v-for="(value, key, index) in BillingStates">
                  {{ value }}
                </option>
            </select>
          </div>
        </div>
         <div class="p-1 mb-1 darker d-inline-block">{{ $store.getters.getOrganizationDisplayName(entry.organizationId) }}</div>
         <h2 class="pl-1 mt-1 d-inline-block">{{ entry.asset && entry.asset.name ? entry.asset.name + ':'
        : (entry.instance ? 'instance ' + entry.instance.name + ' / ' + entry.instance.project.name + ':' : '') }} {{ dateTimeFromISO(entry.startedAt) }} {{ entry.stats }}</h2>
        <!--{{ timeDifferenceFromTwoISOs(entry.stoppedAt, entry.startedAt).string }}-->
        <table>
          <tr>
            <th>{{ $t('Started') }}</th>
            <th>{{ $t('StartedVM') }}</th>
            <th>{{ $t('StoppedVM') }}</th>
            <th>{{ $t('Stopped') }}</th>
            <th>{{ $t('VMTime') }}</th>
            <th>{{ $t('PricePerHour') }}</th>
            <th>{{ $t('TotalPrice') }}</th>
          </tr>
          <tr>
            <td>{{ dateTimeFromISO(entry.startedAt) }}</td>
            <td>{{ entry.vmStartedAt ? dateTimeFromISO(entry.vmStartedAt) : '-' }}</td>
            <td>{{ entry.vmStoppedAt ? dateTimeFromISO(entry.vmStoppedAt) : '-' }}</td>
            <td>{{ entry.stoppedAt ? dateTimeFromISO(entry.stoppedAt) : '-' }}</td>
            <td>{{ entry.vmStoppedAt ? timeDifferenceFromTwoISOs(entry.vmStoppedAt, entry.vmStartedAt).string : '-'}}</td>
            <td>{{ entry.clientPricePerMinute }}.- CHF</td>
            <td><b v-if="entry.vmStoppedAt">{{ entry.clientPricePerMinute *  timeDifferenceFromTwoISOs(entry.vmStoppedAt, entry.vmStartedAt).minutes}} CHF</b></td>
          </tr>
        </table>
      </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import { VMLogStates, BillingStates } from "@/enum"
import DateMixin from "@/components/mixins/DateMixin.js";
import Button from "@/components/forms/Button";
import Popup from "@/components/Popup";
import DeletePrompt from "@/components/forms/DeletePrompt";
export default {
  name: "LogEntry",
  components: {
    Icon,
    Button,
    Popup,
    DeletePrompt
  },
  mixins: [DateMixin],
  props: {
    entry: {type: Object, required: true},
    billingStateEditable: {type: Boolean, default: false},
  },
  data() {
    return {
      showDeletePrompt: false,
      VMLogStates: VMLogStates,
      BillingStates: BillingStates,
      fields: [
          'errors',
          'notes'
      ],
      billingState: '',
      tableFields: [
        {
          name: '',
          id:'',
          type: 'image',
        },
        {
          name: 'Name',
          id:'name',
          type: 'string',
        },
      ],
    }
  },
  beforeMount() {
    this.billingState = this.entry.billingState;
  },
  methods: {
    getState(state) {
      let targetState = '';
      Object.keys(this.VMLogStates).map(s => {
        if(VMLogStates[s] === state) {
          targetState = s;
        }
      })
      return targetState;
    },
    setBillingState() {
      this.$store.dispatch('updateVMLog', {id: this.entry.id, billingState: this.billingState});
    },
    getBillingState(state) {
      let targetState = '';
      Object.keys(this.BillingStates).map(s => {
        if(BillingStates[s] === state) {
          targetState = s;
        }
      })
      return targetState;
    },
    deleteEntry() {
       this.$store.dispatch('deleteVMLog', {
        args: [this.entry.id]
      }).then(() => {
        this.$emit('entryDeleted', this.entry.id);
      })
    }
  }
}
</script>

<style scoped>

</style>