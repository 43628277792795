<template>
<div :class="['vform-header', config.emphasized ? 'emphasized' : '']">
  <h2>{{ getLabel(config, lang) }}</h2>
</div>
</template>

<script>
import UtilsMixinJs from "@/components/vForm/viewer/UtilsMixin.js";

export default {
  name: "Headline",
  props: {
    config: {type: Object, required: true},
    lang: {type: String, required: true}
  },
  mixins: [UtilsMixinJs],
  methods: {
    validate() {
        return true;
    },
    checkRequired() {
        return true;
    },
    getLogEntries() {
      return [];
    }
  }
}
</script>

<style lang="scss" scoped>
  .vform-header {
    &.emphasized {
      background-color: $highlight-color;
    }
  }
</style>