<template>
  <div class="">
    <slide-show-display
      :project-id="$route.params.id"
      :lang="lang ? lang : 'Unknown'"
      :show-images="true"
    />
  </div>
</template>

<script>
import SlideShowDisplay from "@/components/vForm/viewer/SildeShowDisplay";
export default {
  name: "SlideShowDetail",
  components: {
    SlideShowDisplay
  },
  props: {
    //lang: {type: String, required: true}
  },
  data() {
    return {
      lang: 'Unknown'
    };
  }
  }
</script>

<style scoped>

</style>