var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"special-select-outer row",style:({ 'z-index': _vm.showSelect ? 80 : 0 })},[(_vm.selected)?_c('div',{staticClass:"selected d-flex col-10",staticStyle:{"flex-wrap":"wrap"}},[_vm._l((this.selected),function(uuid,index){return _c('div',{key:index,staticClass:"d-flex mb-2 ml-1"},[(_vm.selected.length > 1)?_c('div',{class:[
                    'drop-zone slide-drop-zone',
                    _vm.highlightDropZones ? 'highlight' : '' ],attrs:{"id":'drop-zone' + index},on:{"drop":function($event){return _vm.onDrop($event, index)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){return _vm.dragenter($event)},"dragleave":function($event){return _vm.dragleave($event)}}}):_vm._e(),_c('div',{},[(_vm.thumbs[uuid])?_c('img',{class:[
                        'drag-el slide-img small',
                        _vm.selected.length === 1 ? 'ml-3' : '' ],attrs:{"id":'img' + index,"draggable":"","src":_vm.thumbs[uuid]},on:{"dragstart":function($event){return _vm.startDrag($event, uuid)},"dragend":function($event){return _vm.dragend($event)}}}):_vm._e(),_c('div',{staticStyle:{"position":"relative"}},[(!_vm.thumbs[uuid])?_c('img',{class:[
                            'drag-el slide-img small slide-not-found',
                            _vm.selected.length === 1 ? 'ml-3' : '' ],attrs:{"id":'img' + index,"draggable":"","alt":"Not Found"},on:{"dragstart":function($event){return _vm.startDrag($event, uuid)},"dragend":function($event){return _vm.dragend($event)}}}):_vm._e(),(!_vm.thumbs[uuid])?_c('Button',{staticClass:"icon-button",staticStyle:{"position":"absolute","left":"58px","top":"25px"},on:{"click":function($event){return _vm.removeSlide(uuid)}}},[_c('icon',{attrs:{"type":"trash-alt"}})],1):_vm._e()],1)])])}),(_vm.selected.length > 1)?_c('div',{class:[
                'drop-zone slide-drop-zone mb-2',
                _vm.highlightDropZones ? 'highlight' : '' ],attrs:{"id":""},on:{"drop":function($event){return _vm.onDrop($event, 2000)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){return _vm.dragenter($event)},"dragleave":function($event){return _vm.dragleave($event)}}}):_vm._e()],2):_vm._e(),_c('div',{staticClass:"col-2 text-right"},[_c('div',{staticClass:"clickable d-inline-block mr-3",on:{"click":_vm.toggleSelect}},[_c('icon',{attrs:{"type":_vm.showSelect ? 'times' : 'edit'}})],1)]),(_vm.showSelect)?_c('popup',{on:{"close":function () {
                _vm.showSelect = false;
            }}},[(_vm.showSelect)?_c('div',{attrs:{"slot":"popupMainContent"},slot:"popupMainContent"},[_c('div',{staticClass:"d-flex",staticStyle:{"flex-wrap":"wrap"}},_vm._l((_vm.slides.filter(function (x) { return x.id !== -1; })),function(slide,index){return _c('div',{key:index,class:[
                        'option ml-2 mb-2',
                        _vm.isSlideSelected(slide) ? 'active' : '',
                        _vm.isSlideLinkedToStep(slide) ? 'slide-locked' : 'clickable' ],on:{"click":function($event){return _vm.selectSlide(slide)}}},[_c('div',{staticClass:"slide-container"},[_c('div',{staticClass:"ml-1 mb-1"},[_vm._v(" Slide "+_vm._s(slide.id !== -1 ? slide.id : "none")+" ")]),_c('div',[(_vm.thumbs[slide.uuid])?_c('img',{key:index + _vm.forceReRenderKey,class:['slide-img m-1'],attrs:{"src":_vm.thumbs[slide.uuid]}}):_vm._e()])])])}),0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('Button',{on:{"click":_vm.clearSelection}},[_vm._v(_vm._s(_vm.$t("Reset")))])],1),_c('div',{staticClass:"col-6",staticStyle:{"text-align":"end"}},[_c('Button',{on:{"click":_vm.toggleSelect}},[_vm._v(_vm._s(_vm.$t("Ok")))])],1)])]):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }