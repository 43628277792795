<template>
    <div class="p-2 mb-3">
        <!--ADD DIX TRANSLATION-->
        <div class="col-12 dflex checkbox-wrapper">
            <input
                :id="'chkshowastiles' + $vnode.key"
                type="checkbox"
                v-model="element.showastiles"
            />
            <label :for="'chkshowastiles' + $vnode.key">{{ $t("showAsTiles") }}</label>
        </div>
        <div class="col-12 dflex checkbox-wrapper">
            <input
                :id="'chkEmphasized' + $vnode.key"
                type="checkbox"
                v-model="element.emphasized"
            />
            <label :for="'chkEmphasized' + $vnode.key">{{ $t("emphasized") }}</label>
        </div>
        <div class="col-12 dflex checkbox-wrapper">
            <input
                :id="'chkisRequired' + $vnode.key"
                type="checkbox"
                v-model="element.isRequired"
            />
            <label :for="'chkisRequired' + $vnode.key">{{ $t("isRequired") }}</label>
        </div>
        <!--DIX-->
        <!--FOR EACH TRANSLATION ONE FIELD-->
        <div
            v-if="element.currentLang === key"
            class="col-12 dflex"
            :key="index2 + 1000000000 + forceReRenderKey"
            v-for="(key, index2) in Object.keys(element.label.dix)"
        >
            <div class="mt-4 fit-content">
                <v-form-image-dix-cell
                    :element="element"
                    :image-dix="element.label.imageDix"
                />
            </div>
            <div class="col p-0">
                <span class="lighter">{{ key === "Unknown" ? "Default" : key }}</span>
                <!--QUESTION-->
                <span class="lighter ml-1">{{ $t("text") }}</span>
                <textarea
                    rows="300"
                    class="form-text v-form-label"
                    type="text"
                    v-model="element.label.dix[key]"
                />

                <!--ANSWERS-->
                <div
                    class="col-12 dflex"
                    :key="index4 + 98239283"
                    v-for="(item, index4) in element.options"
                >
                    <div class="w-40">
                        <span class="lighter">{{ $t("Go to Step") }}</span>
                        <select
                            class="custom-select"
                            ref="stepSelector"
                            v-model="item.targetStep"
                        >
                            <option
                                v-for="(step, indexStep) in steps"
                                :key="indexStep"
                                :value="step.uuid"
                            >
                                {{ step.step + " - " + step.name }}
                            </option>
                        </select>
                        <!-- <slide-selector
                            :initial-selected="item.targetSlide"
                            @selected="
                                (uuid, id) => {
                                    item.targetSlide = { uuid, id };
                                }
                            "
                            :slides="slides"
                            :thumbs="thumbs"
                            class="col-12"
                            :display-all-slides="true"
                        /> -->
                    </div>
                    <div class="w-50">
                        <textarea
                            rows="300"
                            placeholder="linktext"
                            class="form-text v-form-label"
                            type="text"
                            v-model="item.text.dix[key]"
                        />
                    </div>
                    <div class="w-10 text-right mt-5">
                        <Button class="icon-button" @click="removeOption(index4)">
                            <icon type="trash-alt" />
                        </Button>
                    </div>
                    <div
                        class="w-100 mt-1 text-right"
                        v-if="index4 === element.options.length - 1"
                    >
                        <Button class="icon-button" @click="addOption(key)">
                            <icon type="plus" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <slot />
        </div>
    </div>
</template>

<script>
import Icon from "@/components/Icon";
import Button from "@/components/forms/Button";
import { vFormControls } from "@/enum";
//import SlideSelector from "@/components/vForm/SlideSelector";
import vFormImageDixCell from "@/components/vForm/vFormImageDixCell";

export default {
    name: "vFormSTEP_CONTROL",
    components: {
        Icon,
        Button,
        //SlideSelector,
        vFormImageDixCell,
    },
    props: {
        element: { type: Object, required: true },
        stepNo: { type: Number, required: true },
        thumbs: { type: Object, default: null },
        slides: { type: Array, default: null },
        steps: { type: Array, default: null },
    },
    data() {
        return {
            forceReRenderKey: 0,
            editImage: "",
            vFormControls: vFormControls,
        };
    },
    beforeMount() {
        if (!this.element.options) {
            this.addOption("Unknown");
        }
        this.element.formElementType = vFormControls["STEP_CONTROL"];
    },
    methods: {
        removeOption(index) {
            this.element.options.splice(index, 1);
        },
        addOption(lang) {
            if (!this.element.options) {
                this.element.options = [];
            }
            this.element.options.push({
                text: {
                    dix: {
                        [lang]: "",
                    },
                    imageDix: {
                        [lang]: "",
                    },
                },
            });
            this.forceReRenderKey++;
        },
        addTranslation(lang) {
            this.element.label.dix[lang] = {
                isRequired: false,
                emphasized: false,
                showastiles: false,
            };
            this.addOption(lang);

            this.forceReRenderKey++;
        },
    },
};
</script>
