var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((Object.keys(_vm.imageDix)),function(key,index3){return (_vm.element.currentLang === key)?_c('div',{key:index3 + 1000000000 + _vm.forceReRenderKey,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.selectImage(_vm.stepNo, index3)}}},[_c('preview',{class:_vm.previewSize + '-thumbnail mb-1',attrs:{"asset-or-project-id":_vm.imageDix[key],"preview-uri":_vm.$store.getters['getMediaPreviewUri'](_vm.imageDix[key]),"type":'image',"preview-id":_vm.imageDix[key],"removable":false}})],1):_vm._e()}),(_vm.showSelect)?_c('popup',{on:{"close":function () {
                _vm.showSelect = false;
            }}},[(_vm.showSelect)?_c('div',{attrs:{"slot":"popupMainContent"},slot:"popupMainContent"},[_c('div',{staticClass:"col row mt-4"},_vm._l((Object.keys(_vm.imageDix)),function(key,index3){return (_vm.element.currentLang === key)?_c('div',{key:index3 + 1000000000 + _vm.forceReRenderKey,staticClass:"col-12"},[_c('div',{staticClass:"dflex"},[_c('div',[_c('content-display',{attrs:{"list-name":"generalList","type":"media","value":_vm.imageDix[key],"show-preview":true}})],1),_c('div',{staticClass:"ml-2 mt-4"},[_c('Button',{staticClass:"icon-button",on:{"click":function($event){_vm.imageDix[key] = '';
                                    _vm.forceReRenderKey++;
                                    _vm.showSelect = false;}}},[_c('icon',{attrs:{"type":"trash-alt"}})],1)],1),(_vm.editImage === _vm.stepNo + '/' + index3)?_c('content-list',{attrs:{"mediaCategory":3,"type":"media","organization-id":_vm.organizationId,"listName":"vformImages","show-preview":true,"thumbnail-view":true},on:{"save":function (id) {
                                    _vm.imageDix[key] = id;
                                    _vm.editImage = null;
                                    _vm.showSelect = false;
                                    _vm.loadImage();
                                }}}):_vm._e()],1)]):_vm._e()}),0)]):_vm._e()]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }