<template>
    <one-column-sidebar-left
      sub-menu-selector="librarypanel"
      back-button-tab-selector="sfxbackbutton"
      tab-selector="addsfxtab"
      :id="$route.params.id"
    >
<div slot="buttonsRight">
            <Button
                    class="mb-3 mt-5"
                    :deactivated="!enableSaving"
                    @click="save">
                <icon v-if="enableSaving" type="save"/><icon v-else type="ban" /> {{ $t('SAVE') }}
            </Button>
        </div>

        <div slot="mainContent">
            <h1><icon class="mr-2" type="assembly" size="0.8" /> | <icon class="mr-2 ml-2" type="model" size="0.8" /> | <icon class="mr-2 ml-2" type="helper" size="0.8" /><br /><div class="mt-1">{{ $t('createNewSfxdata') }}</div></h1>
            <organization-switcher
                @setOrganization="id => {organizationId = id}"
                @setTeam="id => {teamId = id}"
                @unsetTeam="teamId = ''"
                class="mb-2 mt-4" />
            <input
                    type="text"
                    :class="['form-text mb-3 mt-3', $v.name.$dirty && $v.name.$error ? 'form-group--error' : '']"
                    :id="'name'"
                    v-model="name"
                    :placeholder="$t('enternamehere')"
                    @input="delayTouch($v.name)"
            >

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.required && $v.name.$error">{{ $t('errors.required') }}</div>
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.alphaNumSpace">{{ $t('errors.alphaNumSpaceOnly') }}</div>

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>

            <div class="form-error" v-if="$v.name.$dirty && !$v.name.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
            <div class="form-error" v-if="$v.name.$dirty && !$v.name.isUnique">{{ $t('errors.sfxdataNameAlreadyExists') }}</div>
            <div class="form-error" v-if="error">{{ $t(error) }}</div>
        <div>
            <div class="inline-edit-label">
                <b>{{ $t('description') }}</b>
            </div>

            <textarea
                    :class="['form-text mb-3', $v.description.$dirty && $v.description.$error ? 'form-group--error' : '']"
                    :id="'description'"
                    v-model="description"
                    :placeholder="$t('enterdeschere')"
                    @input="delayTouch($v.description)"
            />
            <div class="form-error" v-if="$v.description.$dirty && !$v.description.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>
            </div>
            <div v-if="!assetId">
                <div class="inline-edit-label mb-1">
                    {{ $t('chooseSfxType') }}
                </div> <!--<info-helper text="helperTextSfxTypes" />-->

                <div class="col-12 col-md-12 col-lg-4 neutralize-margin-left">
                    <tile-button ref="tiles" :rows="3" :icons="true" :initial-active-tiles="['assembly']" :tiles="tiles" :values="values" @trigger="updateType" />
                </div>
            </div>
            <div class="row">
              <div class="col-12 mt-3 mb-3">
                <component
                    v-if="uploadComponent"
                    :is="uploadComponent"
                    :asset-id="assetId"
                    :asset-type="type"
                    target-type="Asset"
                    :auto-upload="!!assetId"
                    :asset-name="name"
                    :asset-description="description"
                    :organization-id="organizationId"
                    :team-id="teamId"
                    @finished="finish"
                    @queueEmpty="hasFile = false; checkSaving();"
                    @filesInQueue="hasFile = true; checkSaving();"
                    @enableSaving="checkSaving"
                    ref="uploadComponent"
                    :make-chunks="true"
                />
                <Button
                    v-if="!processing"
                    class="mb-3 mt-5"
                    :deactivated="!enableSaving"
                    @click="save">
                  <icon v-if="enableSaving" type="save"/><icon v-else type="ban" /> {{ $t('SAVE') }}
                </Button>
                <loading-panel
                    v-if="processing"
                    class="col-12 col-md-6 mt-5"
                    message="pleaseWait"
                    :time-in-seconds="20"
                    color-scheme="bright"
                />
              </div>
            </div>
        </div>
    </one-column-sidebar-left>
</template>

<script>
    import Button from "../components/forms/Button";
    import { mapState } from 'vuex';
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import Icon from "../components/Icon";
    import { required, minLength, maxLength } from 'vuelidate/lib/validators';
    import {alphaNumSpace} from '../customValidators';
    import OrganizationSwitcher from "../components/organizations/OrganizationSwitcher";
    import TileButton from "../components/forms/TileButton";
    import InfoHelper from "../components/InfoHelper";
    import fbxUpload from "@/components/assemblies/fbxUpload";
    import LoadingSpinner from "@/components/LoadingSpinner";
    import LoadingPanel from "@/components/LoadingPanel";
    import VuelidateMixin from "@/components/mixins/VuelidateMixin.js"
    import assemblyUpload from "@/components/assemblies/assemblyUpload";
    export default {
        name: 'SFXAdd',
        components: {
            OrganizationSwitcher,
            Button,
            OneColumnSidebarLeft,
            Icon,
            TileButton,
            InfoHelper,
            assemblyUpload,
            fbxUpload,
            LoadingSpinner,
            LoadingPanel
        },
        mixins: [VuelidateMixin],
        data() {
            return {
                hasFile: false,
                assetId: '',
                enableSaving: false,
                enableSubSaving: true,
                name: '',
                description: '',
                type: 'assembly',
                error: null,
                tiles: ['vhelper', 'assembly'],
                values: ['helper', 'assembly'],
                uploadComponent: null,
                uploadConfig: {
                  'assembly': 'assemblyUpload',
                  'helper': 'fbxUpload',
                  'model': 'fbxUpload',
                },
              organizationId: null,
              teamId: '',
              processing: false
            };
        },
        computed: mapState({sfxdataTmp: state => state.sfxdata.sfxdataTmp,}),
        watch: {
            name() {
                this.error = null;
            },
            '$v.$invalid': function() {
                this.checkSaving();
            },
            type() {
              //load the corresponding dropzone to the type
              this.uploadComponent = this.uploadConfig[this.type];
              this.hasFile = false;
              this.checkSaving();
            },
            organizationId() {
              this.checkPipelineAccess();
            },
            teamId() {
              this.checkPipelineAccess();
            }
        },
        beforeMount() {
          this.uploadComponent = this.uploadConfig[this.type];
          if(this.$store.getters.isSuperAdmin) {
            this.addTile('model','model');
          }
        },
        validations: {
            name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(127),
                alphaNumSpace,
                async isUnique(value) {
                    // standalone validator ideally should not assume a field is required
                    if (value === '') {
                        return true;
                    }
                    let bool = true;
                    await this.$store.dispatch('checkIfSfxdataNameExists', {type: 'Asset', name: value, organizationId: this.organizationId,}).then(data => {
                        bool = data.length === 0;
                    });
                    return bool;
                },
            },
            description: {
                maxLength: maxLength(1024),
            },
        },
        methods: {
            addTile(tile, value) {
              if(!this.tiles.includes(tile)) {
                this.tiles.push(tile);
                this.values.push(value);
              }
            },
            removeTile(tile, value) {
                this.tiles = this.tiles.filter(item => {return item !== tile});
                this.values = this.values.filter(item => {return item !== value});
            },
            async checkPipelineAccess() {
              console.log('checking');
              const hasAssemblyAccess = await this.$store.dispatch('checkTeamOrgPermission', {
                op: 'writeMetaSet',
                organizationId: this.organizationId,
                teams: [{id: this.teamId}],
              });
              console.log(this.organizationId);
              if(hasAssemblyAccess) {
                this.addTile('assembly', 'assembly');
              } else {
                this.removeTile('assembly', 'assembly');
                this.$refs.tiles.trigger('helper', 'vhelper')
              }
            },
            updateType: function(args) {
                this.type = args.value;
            },
            checkSaving: function(bool) {
              if(bool && Object.keys(bool).length) {
                this.enableSubSaving = bool.value;
              }
              this.enableSaving = this.name && this.hasFile && !this.$v.$invalid && this.enableSubSaving;
            },
            save: async function() {
              this.processing = true;
                if (this.enableSaving) {
                  this.$refs.uploadComponent.resumeUpload();
                }
            },
            finish(assetId, push = false) {
              if(push) {
                this.$router.push(`/library/3d-data/${this.type}/${assetId}/general`);
              }
            },
        },
    }
</script>
