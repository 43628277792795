<template>
<div></div>
</template>

<script>
export default {
  name: "UtilsMixin.js.vue",
  data() {
    return {
      selected: {},
      forceReRenderKey: 0,
      validationIsCorrect: true
    };
  },
  methods: {
    getSlideList(form, slides) {
      console.log('form steps:' + form.steps.length);
      console.log('slides:' + slides.length);

    },
    getLabel(config, lang) {
      return config.label.dix[lang];
    },
    getImage(config, lang) {
      if(config.label.imageDix) {
        const defaultImg = config.label.imageDix.Unknown;
        const langImg = config.label.imageDix[lang];
        return langImg ? langImg : defaultImg;
      }
    },
    getText(config, lang) {
      return this.decodeEncode(config.text.dix[lang]);
    },
    getTargetSlide(config) {
      return config && config.targetSlide && config.targetSlide.uuid ? config.targetSlide.uuid : null;
    },
    getTargetStep(config) {
      return config && config.targetStep ? config.targetStep : null;
    },
    getTargetSlides(config) {
     return config && config.targetSlides && config.targetSlides.length ? config.targetSlides : null;
    },
    getRequired(config) {
      return config.isRequired;
    },
     /**
    * Decodes or encodes the string
    * (for saving it must be encoded)
    * */
    decodeEncode(string, decode = true) {
       return decode ? decodeURIComponent(string.replace(/\+/g, ' ')) : encodeURIComponent(string);
    },
    isCheckForCorrectness(config) {
      return !!config.validateCorrectness;
    },
    getOptions(config) {
      return config.options ? config.options : [];
    },
    validateQRCode(config, compareString) {
    console.log('qrode config')
      console.log(config);
      return config.label.dix.Unknown === compareString;
    },
    validateOptions() {
        this.validationIsCorrect = true;
        if(this.isCheckForCorrectness(this.config)) {
          const options = this.getOptions(this.config);
          for(let i = 0; i < options.length; i++) {
            const option = options[i];
            if((option.isCorrectAnswer && !option.isSelected) || (!option.isCorrectAnswer && option.isSelected)) {
              this.validationIsCorrect = false;
              break;
            }
          }
        }
        return this.validationIsCorrect;
      }
  }
}
</script>

<style scoped>

</style>