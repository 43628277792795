<template>
    <div class="p-2 mb-3">
        <!--ADD DIX TRANSLATION-->
        <div class="col-12 dflex checkbox-wrapper">
            <input
                :id="'chkshowastiles' + $vnode.key"
                type="checkbox"
                v-model="element.showastiles"
            />
            <label :for="'chkshowastiles' + $vnode.key">{{ $t("showAsTiles") }}</label>
        </div>
        <!--FOR EACH TRANSLATION ONE FIELD-->
        <div
            v-if="element.currentLang === key"
            class="col-12 dflex"
            :key="index2 + 1000000000 + forceReRenderKey"
            v-for="(key, index2) in Object.keys(element.label.dix)"
        >
            <div class="fit-content">
                <v-form-image-dix-cell
                    :element="element"
                    :image-dix="element.label.imageDix"
                />
            </div>
            <div class="col p-0">
                <universal-selector
                    class="ml-1"
                    @addItem="
                        (project) => {
                            element.linkedProjectId = project.id;
                            element.linkedProjectName = project.name;
                            forceReRenderKey++;
                        }
                    "
                    list-name="formProjectList"
                    store-name="Project"
                    filter-attribute="name"
                    :limit-by-organization-id="
                        $store.getters.getFormOrganization($route.params.id)
                    "
                    tag-icon="folder"
                    :show-organization="true"
                />
                <tag
                    class="mt-1 ml-1"
                    :tag="element.linkedProjectName"
                    :is-removable="false"
                    :name="element.linkedProjectName"
                ></tag>
            </div>
        </div>
        <div class="w-100" />
        <div class="col-12">
            <slot />
        </div>
    </div>
</template>

<script>
import vFormImageDixCell from "@/components/vForm/vFormImageDixCell";
import UniversalSelector from "@/components/organizations/UniversalSelector";
import Tag from "@/components/Tag";
import { vFormControls } from "@/enum";

export default {
    name: "vFormPROJECT_SWITCH",
    components: {
        vFormImageDixCell,
        UniversalSelector,
        Tag,
    },
    props: {
        element: { type: Object, required: true },
        stepNo: { type: Number, required: true },
    },
    data() {
        return {
            forceReRenderKey: 0,
            editImage: "",
            vFormControls: vFormControls,
        };
    },
    beforeMount() {
        this.loadProject();
        this.element.formElementType = vFormControls["PROJECT_SWITCH"];
    },
    methods: {
        loadProject() {
            this.$store
                .dispatch("loadProject", {
                    id: this.element.linkedProjectId,
                })
                .then((data) => {
                    this.element.linkedProjectName = data.name;
                });
        },
        addProject(element, projectId) {
            element.linkedProjectId = projectId;
        },
        langVisible(element, key) {
            return element.currentLang === key;
        },
        save() {
            this.$emit("save", this.element);
        },
    },
};
</script>

<style scoped></style>
