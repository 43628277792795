export const MetaFieldTypes = Object.freeze({
    Boolean: 'bool',
    String: 'str',
    Uuid: 'uuid',
    Integer: 'int',
    Float: 'float',
    Color: 'clr',
    Vector2: 'v2',
    Vector3: 'v3',
    Vector4: 'v4',
    xForm2: 'xform2',
    xForm3: 'xform3', // position, rotation, scale (3x v3)
    Enum: 'enum',
    Date: 'date',
    Time: 'time',
    DateTime: 'datetime',
    Binary: 'binary',
    Uri: 'uri',
    Email: 'email',
    Range: 'range',
    Flags: 'flags',
    Password: 'password',
    Ip: 'ip',
    Ipv6: 'ipv6',
    List: 'lst',
    Text: 'txt',
    Json: 'json',
    DataSet: 'dataset',
    DataRow: 'datarow',
    Asset: 'asset',
    Project: 'project'
});

export const MetaFieldStores = Object.freeze({
    feed: {
        name: 'Datasource',
        subType: 'feed'
    },
    table: 'Dataset',
    dataset: 'Dataset',
    project: 'Project',
    app: 'App',
    scene: 'Scene',
    model: 'Sfxdata',
    assembly: 'Sfxdata',
    node: 'Sfxdata',
    media: 'Media',
    user: 'User',
    organization: 'Organization',
    form: 'Form',
    qrcode: 'Qrcodes',
    material: 'Material',
});

export const VectorConfigs = Object.freeze({
    v2: {
        fieldsets: [['x', 'y']],
    },
    v3: {
        fieldsets: [['x', 'y', 'z']],
    },
    v4: {
        fieldsets: [['x', 'y', 'z', 'w']],
    },
    xform2: {
        fieldsets: [['x', 'y'], ['x', 'y'], ['x', 'y']],
    },
    xform3: {
        fieldsets: [['x', 'y', 'z'], ['x', 'y', 'z'], ['x', 'y', 'z']],
    },
});

export const contentTypes = Object.freeze({
    app:  'Project',
    webapp: 'Project',
    model: 'Asset',
    helper: 'Asset',
    assembly: 'Asset',
    media: 'Asset',
    project: 'Project',
    material: 'Material',
    filter: 'Asset',
    team: 'Team',
    scene: 'Asset',
    sfxdata: 'Asset'
});

export const ProjectTypes = Object.freeze({
    vStage:  'vstage',
    WebApp: 'webapp',
    App: 'app',
    Assembly: 'assembly',
    Library: 'lib'
});

export const UserRoles = Object.freeze({
    Viewer: 'viewer',
    Editor:  'editor',
    Manager: 'manager',
    Admin: 'admin',
    None: 'none'
});

export const OrganizationVisibility = Object.freeze({
    0: 'private',
    1:  'organizationLevel',
    2: 'public',
});

export const PlainLanguages = Object.freeze({
    'Unknown': {name: 'Default', active: true },
    'English': {name: 'English', active: true },
    'German': {name: 'German', active: true },
    'French': {name: 'French', active: true },
    'Italian': {name: 'Italian', active: true },
    'Spanish': {name: 'Spanish', active: true },
    'Portuguese': {name: 'Portuguese', active: true },
    'Greek': {name: 'Greek', active: true },
    'Dutch': {name: 'Dutch', active: true },
    'Swedish': {name: 'Swedish', active: true },
    'Norwegian': {name: 'Norwegian', active: true },
    'Polish': {name: 'Polish', active: true },
    'SerboCroatian': {name: 'SerboCroatian', active: true },
    'Romanian': {name: 'Romanian', active: true },
    'Turkish': {name: 'Turkish', active: true },
    'Russian': {name: 'Russian', active: true },
    'Ukrainian': {name: 'Ukrainian', active: true }
});

export const Languages = Object.freeze({
    'af': { name: 'Afrikaans', active: false},
    'af-ZA': { name: 'Afrikaans - South Africa', active: false},
    'ar': { name: 'Arabic', active: false},
    'ar-AE': { name: 'Arabic - United Arab Emirates', active: false},
    'ar-BH': { name: 'Arabic - Bahrain', active: false},
    'ar-DZ': { name: 'Arabic - Algeria', active: false},
    'ar-EG': { name: 'Arabic - Egypt', active: false},
    'ar-IQ': { name: 'Arabic - Iraq', active: false},
    'ar-JO': { name: 'Arabic - Jordan', active: false},
    'ar-KW': { name: 'Arabic - Kuwait', active: false},
    'ar-LB': { name: 'Arabic - Lebanon', active: false},
    'ar-LY': { name: 'Arabic - Libya', active: false},
    'ar-MA': { name: 'Arabic - Morocco', active: false},
    'ar-OM': { name: 'Arabic - Oman', active: false},
    'ar-QA': { name: 'Arabic - Qatar', active: false},
    'ar-SA': { name: 'Arabic - Saudi Arabia', active: false},
    'ar-SY': { name: 'Arabic - Syria', active: false},
    'ar-TN': { name: 'Arabic - Tunisia', active: false},
    'ar-YE': { name: 'Arabic - Yemen', active: false},
    'az': { name: 'Azeri', active: false},
    'az-Cyrl': { name: 'Azeri (Cyrillic)', active: false},
    'az-Cyrl-AZ': { name: 'Azeri (Cyrillic) - Azerbaijan', active: false},
    'az-Latn': { name: 'Azeri (Latin)', active: false},
    'az-Latn-AZ': { name: 'Azeri (Latin) - Azerbaijan', active: false},
    'be': { name: 'Belarusian', active: false},
    'be-BY': { name: 'Belarusian - Belarus', active: false},
    'bg': { name: 'Bulgarian', active: false},
    'bg-BG': { name: 'Bulgarian - Bulgaria', active: false},
    'bs': { name: 'Bosnian', active: false},
    'bs-Cyrl': { name: 'Bosnian (Cyrillic)', active: false},
    'bs-Cyrl-BA': { name: 'Bosnian (Cyrillic) - Bosnia and Herzegovina', active: false},
    'bs-Latn': { name: 'Bosnian (Latin)', active: false},
    'bs-Latn-BA': { name: 'Bosnian (Cyrillic) - Bosnia and Herzegovina', active: false},
    'ca': { name: 'Catalan', active: false},
    'ca-ES': { name: 'Catalan - Catalan', active: false},
    'cs': { name: 'Czech', active: false},
    'cs-CZ': { name: 'Czech - Czech Republic', active: false},
    'da': { name: 'Danish', active: false},
    'da-DK': { name: 'Danish - Denmark', active: false},
    'de': { name: 'German', active: true},
    'de-AT': { name: 'German - Austria', active: false},
    'de-CH': { name: 'German - Switzerland', active: false},
    'de-DE': { name: 'German - Germany', active: false},
    'de-LI': { name: 'German - Liechtenstein', active: false},
    'de-LU': { name: 'German - Luxembourg', active: false},
    'dv': { name: 'Dhivehi', active: false},
    'dv-MV': { name: 'Dhivehi - Maldives', active: false},
    'el': { name: 'Greek', active: false},
    'el-GR': { name: 'Greek - Greece', active: false},
    'en': { name: 'English', active: true},
    'en-AU': { name: 'English - Australia', active: false},
    'en-BZ': { name: 'English - Belize', active: false},
    'en-CA': { name: 'English - Canada', active: false},
    'en-029': { name: 'English - Caribbean', active: false},
    'en-GB': { name: 'English - United Kingdom', active: false},
    'en-IE': { name: 'English - Ireland', active: false},
    'en-JM': { name: 'English - Jamaica', active: false},
    'en-NZ': { name: 'English - New Zealand', active: false},
    'en-PH': { name: 'English - Philippines', active: false},
    'en-TT': { name: 'English - Trinidad and Tobago', active: false},
    'en-US': { name: 'English - United States', active: false},
    'en-ZA `': { name: 'English - South Africa', active: false},
    'en-ZW': { name: 'English - Zimbabwe', active: false},
    'es': { name: 'Spanish', active: false},
    'es-AR': { name: 'Spanish - Argentina', active: false},
    'es-BO': { name: 'Spanish - Bolivia', active: false},
    'es-CL': { name: 'Spanish - Chile', active: false},
    'es-CO': { name: 'Spanish - Colombia', active: false},
    'es-CR': { name: 'Spanish - Costa Rica', active: false},
    'es-DO': { name: 'Spanish - Dominican Republic', active: false},
    'es-EC': { name: 'Spanish - Ecuador', active: false},
    'es-ES': { name: 'Spanish - Spain', active: false},
    'es-GT': { name: 'Spanish - Guatemala', active: false},
    'es-HN': { name: 'Spanish - Honduras', active: false},
    'es-MX': { name: 'Spanish - Mexico', active: false},
    'es-NI': { name: 'Spanish - Nicaragua', active: false},
    'es-PA': { name: 'Spanish - Panama', active: false},
    'es-PE': { name: 'Spanish - Peru', active: false},
    'es-PR': { name: 'Spanish - Puerto Rico', active: false},
    'es-PY': { name: 'Spanish - Paraguay', active: false},
    'es-SV': { name: 'Spanish - El Salvador', active: false},
    'es-UY': { name: 'Spanish - Uruguay', active: false},
    'es-VE': { name: 'Spanish - Venezuela', active: false},
    'et': { name: 'Estonian', active: false},
    'et-EE': { name: 'Estonian - Estonia', active: false},
    'eu': { name: 'Basque', active: false},
    'eu-ES': { name: 'Basque - Basque', active: false},
    'fa': { name: 'Farsi', active: false},
    'fa-IR': { name: 'Farsi - Iran', active: false},
    'fi': { name: 'Finnish', active: false},
    'fi-FI': { name: 'Finnish - Finland', active: false},
    'fo': { name: 'Faroese', active: false},
    'fo-FO': { name: 'Faroese - Faroe Islands', active: false},
    'fr': { name: 'French', active: true},
    'fr-BE': { name: 'French - Belgium', active: false},
    'fr-CA': { name: 'French - Canada', active: false},
    'fr-CH': { name: 'French - Switzerland', active: false},
    'fr-FR': { name: 'French - France', active: false},
    'fr-LU': { name: 'French - Luxembourg', active: false},
    'fr-MC': { name: 'French - Monaco', active: false},
    'gl': { name: 'Galician', active: false},
    'gl-ES': { name: 'Galician - Galician', active: false},
    'gu': { name: 'Gujarati', active: false},
    'gu-IN': { name: 'Gujarati - India', active: false},
    'he': { name: 'Hebrew', active: false},
    'he-IL': { name: 'Hebrew - Israel', active: false},
    'hi': { name: 'Hindi', active: false},
    'hi-IN': { name: 'Hindi - India', active: false},
    'hr': { name: 'Croatian', active: false},
    'hr-HR': { name: 'Croatian - Croatia', active: false},
    'hu': { name: 'Hungarian', active: false},
    'hu-HU': { name: 'Hungarian - Hungary', active: false},
    'hy': { name: 'Armenian', active: false},
    'hy-AM': { name: 'Armenian - Armenia', active: false},
    'id': { name: 'Indonesian', active: false},
    'id-ID': { name: 'Indonesian - Indonesia', active: false},
    'is': { name: 'Icelandic', active: false},
    'is-IS': { name: 'Icelandic - Iceland', active: false},
    'it': { name: 'Italian', active: true},
    'it-CH': { name: 'Italian - Switzerland', active: false},
    'it-IT': { name: 'Italian - Italy', active: false},
    'iu': { name: 'Inuktitut', active: false},
    'iu-Latn': { name: 'Inuktitut (Latin)', active: false},
    'iu-Latn-CA': { name: 'Inuktitut (Latin) - Canada', active: false},
    'ja': { name: 'Japanese', active: false},
    'ja-JP': { name: 'Japanese - Japan', active: false},
    'ka': { name: 'Georgian', active: false},
    'ka-GE': { name: 'Georgian - Georgia', active: false},
    'kk': { name: 'Kazakh', active: false},
    'kk-KZ': { name: 'Kazakh - Kazakhstan', active: false},
    'kn': { name: 'Kannada', active: false},
    'kn-IN': { name: 'Kannada - India', active: false},
    'ko': { name: 'Korean', active: false},
    'kok': { name: 'Konkani', active: false},
    'kok-IN': { name: 'Konkani - India', active: false},
    'ko-KR': { name: 'Korean - Korea', active: false},
    'ky': { name: 'Kyrgyz', active: false},
    'ky-KG': { name: 'Kyrgyz - Kyrgyzstan', active: false},
    'lt': { name: 'Lithuanian', active: false},
    'lt-LT': { name: 'Lithuanian - Lithuania', active: false},
    'lv': { name: 'Latvian', active: false},
    'lv-LV': { name: 'Latvian - Latvia', active: false},
    'mk': { name: 'Macedonian', active: false},
    'mk-MK': { name: 'Macedonian - Former Yugoslav Republic of Macedonia', active: false},
    'mn': { name: 'Mongolian', active: false},
    'mn-MN': { name: 'Mongolian - Mongolia', active: false},
    'mr': { name: 'Marathi', active: false},
    'mr-IN': { name: 'Marathi - India', active: false},
    'ms': { name: 'Malay', active: false},
    'ms-BN': { name: 'Malay - Brunei', active: false},
    'ms-MY': { name: 'Malay - Malaysia', active: false},
    'nb-NO': { name: 'Norwegian (Bokm?l) - Norway', active: false},
    'nl': { name: 'Dutch', active: false},
    'nl-BE': { name: 'Dutch - Belgium', active: false},
    'nl-NL': { name: 'Dutch - The Netherlands', active: false},
    'nn-NO': { name: 'Norwegian (Nynorsk) - Norway', active: false},
    'no': { name: 'Norwegian', active: false},
    'pa': { name: 'Punjabi', active: false},
    'pa-IN': { name: 'Punjabi - India', active: false},
    'pl': { name: 'Polish', active: false},
    'pl-PL': { name: 'Polish - Poland', active: false},
    'pt': { name: 'Portuguese', active: false},
    'pt-BR': { name: 'Portuguese - Brazil', active: false},
    'pt-PT': { name: 'Portuguese - Portugal', active: false},
    'ro': { name: 'Romanian', active: true},
    'ro-RO': { name: 'Romanian - Romania', active: false},
    'ru': { name: 'Russian', active: true},
    'ru-RU': { name: 'Russian - Russia', active: false},
    'sa': { name: 'Sanskrit', active: false},
    'sa-IN': { name: 'Sanskrit - India', active: false},
    'sk': { name: 'Slovak', active: false},
    'sk-SK': { name: 'Slovak - Slovakia', active: false},
    'sl': { name: 'Slovenian', active: false},
    'sl-SI': { name: 'Slovenian - Slovenia', active: false},
    'sq': { name: 'Albanian', active: false},
    'sq-AL': { name: 'Albanian - Albania', active: false},
    'mn-Cyrl': { name: 'Mongolian (Cyrillic)', active: false},
    'sr': { name: 'Serbian', active: false},
    'sr-Cyrl': { name: 'Serbian (Cyrillic)', active: false},
    'sr-Cyrl-BA': { name: 'Serbian (Cyrillic) - Bosnia and Herzegovina', active: false},
    'sr-Cyrl-CS': { name: 'Serbian (Cyrillic) - Serbia', active: false},
    'sr-Latn': { name: 'Serbian (Latin)', active: false},
    'sr-Latn-BA': { name: 'Serbian (Latin) - Bosnia and Herzegovina', active: false},
    'sr-Latn-CS': { name: 'Serbian (Latin) - Serbia', active: false},
    'sv': { name: 'Swedish', active: true},
    'sv-FI': { name: 'Swedish - Finland', active: false},
    'sv-SE': { name: 'Swedish - Sweden', active: false},
    'sw': { name: 'Swahili', active: false},
    'sw-KE': { name: 'Swahili - Kenya', active: false},
    'syr': { name: 'Syriac', active: false},
    'syr-SY': { name: 'Syriac - Syria', active: false},
    'ta': { name: 'Tamil', active: false},
    'ta-IN': { name: 'Tamil - India', active: false},
    'te': { name: 'Telugu', active: false},
    'te-IN': { name: 'Telugu - India', active: false},
    'th': { name: 'Thai', active: false},
    'th-TH': { name: 'Thai - Thailand', active: false},
    'tr': { name: 'Turkish', active: false},
    'tr-TR': { name: 'Turkish - Turkey', active: false},
    'tt': { name: 'Tatar', active: false},
    'tt-RU': { name: 'Tatar - Russia', active: false},
    'uk': { name: 'Ukrainian', active: false},
    'uk-UA': { name: 'Ukrainian - Ukraine', active: false},
    'ur': { name: 'Urdu', active: false},
    'ur-PK': { name: 'Urdu - Pakistan', active: false},
    'uz': { name: 'Uzbek', active: false},
    'uz-Cyrl': { name: 'Uzbek (Cyrillic)', active: false},
    'uz-Latn': { name: 'Uzbek (Latin)', active: false},
    'uz-Cyrl-UZ': { name: 'Uzbek (Cyrillic) - Uzbekistan', active: false},
    'uz-Latn-UZ': { name: 'Uzbek (Latin) - Uzbekistan', active: false},
    'vi': { name: 'Vietnamese', active: false},
    'vi-VN': { name: 'Vietnamese - Vietnam', active: false},
    'zh': { name: 'Chinese', active: false},
    'zh-CHT': { name: 'Chinese (Traditional)', active: false},
    'zh-CHS': { name: 'Chinese (Simplified)', active: false},
    'zh-CN': { name: 'Chinese - China', active: false},
    'zh-HK': { name: 'Chinese - Hong Kong SAR', active: false},
    'zh-MO': { name: 'Chinese - Macao SAR', active: false},
    'zh-SG': { name: 'Chinese - Singapore', active: false},
    'zh-TW': { name: 'Chinese - Taiwan', active: false},
    'zh-Hans': { name: 'Chinese (Simplified, Hans)', active: false},
    'zh-Hant': { name: 'Chinese (Traditional, Hans)', active: false},
});

export const AdminData = {
    vHubOrganizationId: '7bc33721-622f-4b87-b9a4-333170f9fa93',
    vHubAdminUserId: '8a26c307-0b45-4420-b3cc-16db98bbfe7d'
};

export const stepFileStati = [
    'initiallyUploaded',
    'transferringData',
    'transferComplete',
    'convertingData',
    'deliveringConvertedData',
    'processingFinished'
];

/**
 * The allowed formats for meta data enrichment via XML or similar file which will be saved
 * in the assembly's instances metaSets
 * The key of the object must match the name of the pre or postprocessor file in vhub
 * */
export const metaDataFileFormats = Object.freeze({
    'xmlWindchillSearchRes': {
        format: 'xml',
        name: 'Windchill Search Result'
    },
});

/***
 *
 * */
export const typeMatching = Object.freeze({
    'mp4': 'video',
    //'mov': 'video',
    'png' : 'image',
    'jpg' : 'image',
    'gif' : 'image',
    'jpeg' : 'image',
    'pdf' : 'pdf',
    'fbx' :  'model',
    'docx': 'word',
    'doc' : 'word',
    'webm' : 'video',
    'mp3' : 'audio',
    'aiff' : 'audio',
    'aif' : 'audio',
    'ogg' : 'audio',
    'wav' : 'audio',
    'step' : 'model',
    'stp': 'model',
});

export const previewTypes = Object.freeze({
    'model': 'Three',
    'pdf': 'PDFPreview',
    'image': 'ImagePreview',
    'audio': 'AudioPreview',
    'video': 'VideoPreview',
});

export const mimeTypes = Object.freeze({
    ['image/jpeg']: 'image',
    ['image/jpg']: 'image',
    ['image/png']: 'image',
    ['image/gif']: 'image',
    ['application/pdf']: 'multipart',
    ['video/mp4']: 'video',
    ['video/webm']: 'video',
    ['audio/webm']: 'audio',
    ['audio/mp3']: 'audio',
    ['audio/ogg']: 'audio',
    ['audio/wav']: 'audio',
    ['audio/aiff']: 'audio',
    ['audio/aif']: 'audio',
});

export const defaultKeys = Object.freeze({
    'png': 'preview.png',
    'jpg': 'preview.jpg',
    'jpeg': 'preview.jpg',
    'pdf': 'pdfdoc.pdf',
    'fbx' : 'lod0.fbx',
});

export const allowPreviewsFor = [
    'mp4', 'png', 'jpg', 'gif', 'jpeg',
    'pdf', 'fbx', 'mp3', 'wav'
];

export const textFileTypes = [
    'json', 'txt'
];

export const mediaCategories = {
    'audio': 1,
        'font': 2,
        'image': 3,
        'message': 4,
        'model': 5,
        'multipart': 6,
        'pdf': 6,
        'text': 7,
        'video': 8,
};

export const metaSetUpdateModes = [
    'extend',
    'overwrite',
    'reset'
];

export const available3DFiles = Object.freeze({
    fbx: "Autodesk",
    '3ds': "3D Studio Mesh",
    abc: "Alembic",
    dae: "Autodesk Collada",
    dwg: "AutoCAD Drawing",
    ige: "IGES",
    ipt: "Autodesk Inventor",
    iam: "Autodesk Inventor",
    jt: " Siemens JT",
    obj: "Wavefront OBJ",
    asm: "ProE",
    prt: "ProE",
    skp: "SketchUp",
    sldasm: "SolidWorks",
    sldprt: "SolidWorks",
    stl: "StereoLitho",
    stp: "Step",
    step: "Step",
    wrl: "VRML",
    max: '3DS Max',
    zip: 'ZIP'
});

export const importable3DFiles = Object.freeze({
    asm: "ProE",
    fbx: "Autodesk",
    jt: "Siemens JT",
    max: '3DS Max',
    prt: "ProE",
    step: "Step",
    stp: "Step",
    zip: 'ZIP'
});

/**
* which files can be used as root files
* */
export const importableRootFiles = Object.freeze({
    asm: "ProE",
    fbx: "Autodesk",
    jt: "Siemens JT",
    max: '3DS Max',
    prt: "ProE",
    step: "Step",
    stp: "Step",
});

export const SpecialUuids = Object.freeze({
    NODE: 'b2ecddce-ea35-41b1-96be-305f8b88d3d0',
    VHUB_ORGANIZATION: '7bc33721-622f-4b87-b9a4-333170f9fa93',
    ASSEMBLY_METASET: '0ff675f7-d539-497b-921b-29d865d9c936',
    VSTAGE_WINDOWS: 'acf22edb-6c80-4d17-b43a-05ef1a9001ac',
    VSTAGE_IOS: 'b5d25df4-3b21-4a45-8689-0f20681e5270',
    VSTAGE_ANDROID: '9c8140be-6658-43b2-9827-62dad412d497',
    VSTAGE_OSX: 'e6d8d676-4b1b-4d71-8511-864deed212c0',
    VSTAGE_WINDOWS_NO_INSTALLER: '10f4fbec-b6a8-43a6-bfeb-8a2f3f660971',
    VSTAGE_OSX_NO_INSTALLER: '27412dc3-0161-40e5-a439-2e6a3adff4c8',
    TASKMANAGER_DATASET: '6ec87ac9-1074-4579-a9df-083ab8ff53c8',
    LOGGER_DATASET: '23d1648c-3afe-4fe8-8da2-4b0900247ba9',
});

export const PivotPositions = Object.freeze({
    SOURCE: 'source',
    TOP: 'top',
    LEFT: 'left',
    RIGHT: 'right',
    CENTER: 'center',
    BASE: 'base',
    FRONT: 'front',
    BACK: 'back',
});

/**
 * Project state
 * @enum {string}
 * @property ACTIVE - Project is active (`a`)
 * @property INACTIVE - Project is inactive (`i`)
 * @property PROCESSING - Project is processing (`p`)
 * @property HIDDEN - Project is hidden but still usable (`h`)
 */
export const ProjectStates = Object.freeze({
    ACTIVE:   'a',
    INACTIVE: 'i',
    PROCESSING: 'p',
    HIDDEN: 'h'
});

export const VMStates = Object.freeze({
    ACTIVE: 'a',
    MAINTENANCE: 'm'
});

export const VMLogStates = Object.freeze({
    STARTED: 's',
    FINISHED: 'f',
    ERRORED: 'e',
});

export const BillingStates = Object.freeze({
    NONE: 'none',
    BILLED: 'billed',
    PAYED: 'payed',
    DEMAND: 'demand', // Mahnung
    DROPPED: 'dropped',
});

/**
 * Asset state
 * @enum {string}
 * @property ACTIVE - Asset is active (`a`)
 * @property INACTIVE - Asset is inactive (`i`)
 * @property PROCESSING - Asset is processing (`p`)
 * @property HIDDEN - Asset is hidden but still usable (`h`)
 */
export const AssetStates = Object.freeze({
    ACTIVE: 'a',
    INACTIVE: 'i',
    PROCESSING: 'p',
    HIDDEN: 'h'
});

/**
 * Those are the simplified steps for the pipeline (EXTRACT) which will be shown to the user in the progress section
 * in the control center
 * (used for vmlog)
 * **/
export const PipelineSimplifiedExtractSteps = Object.freeze({
    STARTED: 'Started',
    DATA_ARRIVED_ON_VM: 'ArrivedOnVm',
    CAD_IMPORT: 'Importing',
    SINGLE_EXTRACT: 'SingleExtractProgress',
    GROUP_EXTRACT: 'GroupExtractProgress',
    TRANSFER_DATA_TO_VHUB: 'TransferDataToVHub',
    CREATING_ASSETS: 'CreatingAssets',
    CREATING_INSTANCES: 'CreatingInstances',
    ATTACHING_METADATA: 'AttachingMetadata',
    SETTING_METAVALUES: 'SettingMetaValues',
    SETTING_CUSTOM_META_VALUES: 'SettingCustomMetaValues',
    FINISHED: 'Finished',
});

/**
 * Those are the simplified steps for the pipeline (FILTER) which will be shown to the user in the progress section
 * in the control center
 * (used for vmlog)
 * **/
export const PipelineFilterSteps = Object.freeze({
    STARTED: 'Started',
    DATA_ARRIVED_ON_VM: 'ArrivedOnVm',
    CAD_IMPORT: 'Importing',
    MODIFY_3D_DATA: 'Modify3dData',
    TRANSFER_DATA_TO_VHUB: 'TransferDataToVHub',
    FINISHED: 'Finished',
});

/**
 * Asset types
 * @enum {string}
 * @property UNKNOWN - Unknown asset type, only used for temp. creation
 * @property MEDIA - Media asset like image, docs, video, audio
 * @property TEXT - Text asset
 * @property FEED - Feed
 * @property WEBAPP - Web application package
 * @property SENSOR - Senor asset
 * @property HELPER - vHelper
 * @property SCENERY - vStage scenery
 * @property NODE - 3D node
 * @property SKELETON - 3D blueprint
 * @property MODEL - 3D object
 * @property ASSEMBLY - 3D assembly artifact
 * @property FORM - vStage Form
 * @property MATERIAL - Material asset
 * @property QRCODE - QR code asset
 * @property LINK - External data link
 * @property REPORT - vReport object
 */
export const AssetTypes = {
    MEDIA:    'media',
    TEXT:     'text',
    FEED:     'feed',
    WEBAPP:   'webapp',
    SENSOR:   'sensor',
    HELPER:   'helper',
    SCENERY:  'scenery',
    NODE:     'node',
    MODEL:    'model',
    ASSEMBLY: 'assembly',
    FORM:     'form',
    MATERIAL: 'material',
    QRCODE:   'qrcode',
    LINK:     'link',
    REPORT:   'report',
    FILTER:   'filter'
};

export const SchemaNames = Object.freeze({
    MATERIAL_MAPPER: 'sfx.materialMapper',
});

export const vFormControls = Object.freeze({
    SHORT_ANSWER: 'Short Answer',
    CHECKBOXES: 'Checkboxes',
    MULTIPLE_CHOICE: 'Multiple-Choice',
    SEPARATOR: 'Separator',
    HEADLINE: 'Headline',
    SLIDE_CONTROL: 'SlideControl',
    QR_CODE: 'QR-Code',
    TEXT: 'Text',
    PROJECT_SWITCH: 'Project-Switch',
    IMAGE: 'Image',
    STEP_CONTROL: 'StepControl',
    WEBSITE: 'Website',
    META_LIST_FILTER: 'Meta-List-Filter',
    MEDIA_LOADER: 'Media-Loader'
});

export const vFormEventTypes = Object.freeze({
    INPUTFIELD: 0,
    CHECKBOX: 1,
    RADIOBUTTON: 2,
    SEPARATOR: 3,
    HEADLINE: 4,
    SLIDECONTROL: 5,
    QRCODE: 6,
    TEXTDISPLAY: 7,
});