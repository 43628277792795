<template>
  <div class="row">
    <div :class="[classes]">
      <tile-button
          v-if="showToggler && values.length > 1 && (!assetId || $store.getters.getSfxdataPipelineStatus($route.params.id) !== 'processing')"
          :initial-active-tiles="[tiles[0]]"
          :rows="2"
          :icons="true"
          :tiles="tiles"
          :values="values"
          @trigger="updateAction"
      />
    </div>
    <div class="w-100 mt-3 mb-3" />

    <upload-drop-zone
        v-if="!assetId || $store.getters.getSfxdataPipelineStatus($route.params.id) !== 'processing'"
        :class="[!file ? classes : 'col-6 col-md-6 col-lg-4']"
        :asset-id="assetId"
        :image-editable="true"
        asset-type="assembly"
        :target-type="targetConfig[action]"
        form-id="uploadSfx"
        :accepted-file-types="fileConfig[action]"
        :max-file-size="1000000"
        :auto-upload="autoUpload"
        @ready="showToggler = true"
        @data="setData"
        @fileReceived="fileReceived"
        @filesInQueue="filesInQueue"
        @queueEmpty="queueEmpty"
        :ref="refId"
        :asset-name="assetName"
        :asset-description="assetDescription"
        :organization-id="organizationId"
        :team-id="teamId"
        :removable="false"
        :downloadable="false"
        :is-loading="isLoading"
        :auto-update-preview="false"
        :make-chunks="makeChunks"
    >
    <div class="mb-3" v-if="action === 'uploadImport'">
      **{{ $t('attentionUploadStartsProcess') }}
    </div>
    </upload-drop-zone>
    <div class="w-100" />
    <div class="col-12 col-md-8 col-lg-6 mt-1" v-if="file && !hideForm && isZip && action === 'pipeline'">
      <zip-file-selector
          class="mt-5"
          :zip="file"
          label="rootFile"
          description="pleaseChooseRoot"
          @selected="(id) => {setProperty(id, 'rootNodeFile'); checkSaving();}"
          @unselected="setProperty(null, 'rootNodeFile'); setProperty(null, 'clientMetaFile'); checkSaving();"
          :allowed-file-types="zipSelectablerootNodeFiles"
      />
      <zip-file-selector
          class="mt-5"
          :zip="file"
          label="dataDescriptionFile"
          description="pleaseChooseDataFile"
          @selected="(id) => {setProperty(id, 'clientMetaFile'); checkSaving();}"
          @unselected="unsetMetaFile(); setProperty(null, 'clientMetaFile'); checkSaving();"
          :allowed-file-types="['xml', 'json', 'txt']"
          :file-stream-on-selection="true"
          @getFileAsString="getMetaFile"
      />
      <div class="form-error mt-3" v-if="validationError">{{ $t(validationError) }}</div>
    </div>
    <div class="col-12 mt-3" v-if="rootNodeFile || clientMetaFile">
      <div class="row" v-if="clientMetaFile">
        <div class="col-12 col-md-4 mb-5">
          <label class="lighter mt-2">{{ $t('PostProcessingForMeta') }}</label><br >
          <select @change="checkSaving" class="custom-select" v-model="vhubPostProcessor">
            <option :key="key" v-for="(key, value) in vhubPostProcessorOptions" :value="value">{{ $t(key.name) }}</option>
          </select>
        </div>
      </div>
      <div class="lighter" v-if="rootNodeFile">{{ $t('rootFile') }}</div> {{ rootNodeFile }}<br >
      <div class="lighter mt-2" v-if="clientMetaFile">{{ $t('metaFile') }}</div> {{ clientMetaFile }}
    </div>
    <div class="col-12 col-md-8" v-if="assetId && $store.getters.getSfxdataPipelineStatus(this.$route.params.id) === 'processing'">
      {{ $t('fileProcessingCannotUpload') }}
    </div>
    <div class="col-12">
      <div class="form-error mt-3" v-if="error">{{ $t(error) }}</div>
    </div>
  </div>
</template>

<script>
import UploadDropZone from "@/components/files/UploadDropZone";
import TileButton from "@/components/forms/TileButton";
import ZipFileSelector from "@/components/files/ZipFileSelector";
import {getXMLColumns} from "../fileProcessors/xmlWindchillSearchRes";
import FileTypeMixin from "@/components/files/FileTypeMixin.js.vue";
import {importable3DFiles, metaDataFileFormats, available3DFiles}  from '@/enum';

export default {
  name: "AssemblyUpload",
  components: {
    UploadDropZone,
    TileButton,
    ZipFileSelector
  },
  mixins: [
    FileTypeMixin
  ],
  props: {
    /**
     * @assetId Text the assetId
     */
    assetId: {type: String, default: null},
    /**
     * @organizationId String the organizationId
     */
    organizationId: {type: String, default: null},

    teamId: {type: String, default: null},
    /**
     * @autoUpload Boolean Whether or not to upload the file right after drag and drop event
     */
    autoUpload: {type: Boolean, default: true},
    /**
     * If an asset needs to be created: type, name and description can be set
     * @assetName String a name
     * @assetDescription String an optional description
     */
    assetName: {type: String, default: ''},
    assetDescription: {type: String, default: ''},

    classes: {type: String, default: 'col-12 col-md-8 col-lg-6 mt-1'},

    /**
     * @makeChunks Whether or not to upload the file in chunks
     * */
    makeChunks: {type: Boolean, default: false},
  },
  data() {
    return {
      error: '',
      validationError: '',
      vhubPostProcessorOptions: {
        'none': {
          name: 'none'
        },
        ...metaDataFileFormats
      },
      vhubPostProcessor: 'none',
      refId: Date.now() + 'ref',
      enableSaving: false,
      previewUri: '',
      description: '',
      content: '',
      tiles: ['cadConvert'],
      values: ['pipeline'],
      action: 'pipeline',
      fileConfig: {
        'pipeline': Object.keys(this.$store.getters.isSuperAdmin ? available3DFiles : importable3DFiles).map(item => {return '.' + item}).join(','),
        'uploadImport': '.zip',
      },
      targetConfig: {
        'pipeline': 'Asset',
        'uploadImport': 'assemblyZipImport',
      },
      showToggler: false,
      file: null,
      isLoading: false,
      rootNodeFile: null,
      clientMetaFile: null,
      hideForm: false,
      metaFileText: '',
      metaFileName: '',
      isZip: false,
      zipSelectablerootNodeFiles: []
    };
  },
  beforeMount() {
    this.zipSelectablerootNodeFiles = Object.keys(importable3DFiles);
    if(this.$store.getters.isSuperAdmin) {
      this.tiles.push('importHierarchy');
      this.values.push('uploadImport');
    }
  },
  methods: {
    checkSaving: function() {
      this.error = '';
      console.log('metafilecheck: ' + this.checkMetaFile())
      console.log('rootNodecheck: ' + this.checkRootNode())
      this.enableSaving = this.checkMetaFile() && this.checkRootNode() && !this.validationError;
      this.$emit('enableSaving', {value: this.enableSaving});
    },
    checkMetaFile() {
      if(this.validationError) {
        this.vhubPostProcessor = 'none';
      }
      if(this.validationError && !this.clientMetaFile) {
        this.validationError = '';
        this.vhubPostProcessor = 'none';
      }
      if(!this.clientMetaFile || this.vhubPostProcessor !== 'none') {
        return true;
      }
      this.error = this.$t('errors.PleaseSelectTheFormatOfYourMetaFile');
      return false;
    },
    checkRootNode() {
       if(!this.isZip || (this.isZip && this.action !== 'pipeline') || (this.isZip && this.action === 'pipeline' && this.rootNodeFile)) {
         return true;
       }
       this.error = this.$t('errors.PleaseSelectARootNode');
      return false;
    },
    unsetMetaFile() {
      this.metaFileText = '';
      this.metaFileName = '';
    },
    async getMetaFile(text, fileName) {
      this.metaFileText = text;
      this.metaFileName = fileName;
      this.validationError = '';
      try {
        const columns = await getXMLColumns(this.metaFileText);
        if(columns) {
          this.columns = columns;
        }
      } catch (e) {
         this.validationError = 'The xml is not in a valid format';
      }

      this.checkSaving();
    },
    updateAction(args) {
      this.action = args.value;
      this.checkSaving();
    },
    //CASE 1: pipeline
    async preparePipeline(assetId, fileName) {
      let args = {
        pipeline: {
          files: [],
          status: 'initiallyUploaded',
          lang: this.$i18n.locale ? this.$i18n.locale : 'en'
        }
      };
      args.pipeline.files.push(`${assetId}/${fileName}`);
      if(!this.isZip && !this.isMultiple) {
        args.pipeline.rootNodeFile = `${fileName}`;
      }
      if(this.rootNodeFile) {
        args.pipeline.rootNodeFile = this.rootNodeFile;
      }
      if(this.clientMetaFile) {
        args.pipeline.meta = {};
        args.pipeline.meta.clientMetaFile = this.clientMetaFile;
      }
      if(this.metaFileText) {
        if(this.columns) {
          args.pipeline.meta.columns = this.columns;
        }
        /*const datasetId = await parseXMLToDataset(this.metaFileText, this.metaFileName, this.organizationId);
        if(datasetId) {
          args.pipeline.meta.metaFileDataSet = datasetId;
        }*/
      }
      if(this.vhubPostProcessor && this.vhubPostProcessor !== 'none') {
        args.pipeline.vhubPostProcessors = [];
        args.pipeline.vhubPostProcessors.push({name: this.vhubPostProcessor});
      }
      await this.$store.dispatch('loadSfxdata', {id: assetId}).then(async () => {
        await this.$store.dispatch('updateSfxdata', {
          id: assetId,
          state: 'i',
          content: JSON.stringify(args),
        })
      })
      .then(() => {
        this.$emit('finished', assetId, true);
      });
    },
    //CASE 1: uploadImport / importHierarchy
    async prepareFBXImport(assetId, fileName) {
      let args = {
        pipeline: {
          files: [],
          status: 'initiallyUploaded',
          lang: this.$i18n.locale ? this.$i18n.locale : 'en'
        }
      };
      args.pipeline.files.push(`${assetId}/${fileName}`);
      args.pipeline.vhubPostProcessors = [];
      args.pipeline.vhubPostProcessors.push({name: 'hierarchyOnly'});
      await this.$store.dispatch('loadSfxdata', {id: assetId}).then(async () => {
        await this.$store.dispatch('updateSfxdata', {
          id: assetId,
          state: 'i',
          content: JSON.stringify(args),
        })
      }).then(async () => {
            await this.$store.dispatch('clientFinishPipelineTask', {id: assetId})
            this.$emit('finished', assetId, true);
      });
    },
    fileReceived: function(assetId, fileName){
      if(this.action === 'pipeline') {
        this.preparePipeline(assetId, fileName);
      }
      else {
        this.prepareFBXImport(assetId, fileName);
      }
    },
    resumeUpload: function() {
    console.log('resume upload');
      this.$refs[this.refId].resumeUpload();
      this.hideForm = true;
    },
    setData(data) {
      console.log('got SET DATA');
      this.file = data;
      this.isLoading = false;
    },
    filesInQueue: function(queue) {
      this.$emit('filesInQueue', queue);
      const fileName = queue[0].name;
      this.isZip = this.getExtension(fileName).toLowerCase() === 'zip';
      if(this.data) {
        this.isLoading = true;
      }
      this.checkSaving();
    },
    queueEmpty: function() {
      this.$emit('queueEmpty');
      this.file = null;
      this.checkSaving();
    },
    setProperty(id, propertyName) {
      this[propertyName] = id;
      this.checkSaving();
    }
  }
}
</script>