<template>
<div>
  RADIOBUTTON
  {{ getLabel(config, lang) }}
  <div class="clickable slide-control-element mb-2" :key="index + forceReRenderKey" v-for="(option, index) in getOptions(config)">
    <div
      @click="selectUnselectItem(option)"
      :class="['checkbox', option.isFaulty ? 'faulty' : '', option.isSelected ? 'selected' : 'unselected']"></div>
      {{ getText(option, lang) }} {{option.isCorrectAnswer}}
  </div>
  <div @click="validateOptions" v-if="isCheckForCorrectness(config)">
    validate correctness
  </div>
</div>
</template>

<script>
import UtilsMixinJs from "@/components/vForm/viewer/UtilsMixin.js";

export default {
  name: "CheckBox",
  props: {
    config: {type: Object, required: true},
    lang: {type: String, required: true}
  },
  mixins: [UtilsMixinJs],
  data() {
    return {
      selected: {},
    };
  },
  methods: {
      selectUnselectItem(option) {
        const options = this.getOptions(this.config);
        options.forEach(opt => {
          opt.isSelected = false;
        })
        option.isSelected = !option.isSelected;
        this.forceReRenderKey++;
      },
      validate() {
        return this.validateOptions();
      },
      checkRequired() {
       return true;
      },
      getLogEntries() {
        return [];
      }
  }
}
</script>

<style lang="scss" scoped>
  .checkbox {
      width: 1.5rem;
      height: 1.5rem;
      background-color: lighten($highlight-color, 10%);
      float:left;
      margin-right: 8px;
      border: 1px solid transparent;
      border-radius: 50%;
      &.selected, &:hover {
        background-color: darken($highlight-color, 10%);
      }
      &.faulty {
        border: 1px solid red;
      }
  }
</style>