<template>
  <div>
    <popup
        @close="() => {showDeletePrompt = false;}"
        v-if="showDeletePrompt"
    >
      <delete-prompt slot="popupMainContent"
                     @abort="() => {showDeletePrompt = false; toBeDeleted = null;}"
                     @confirm="() => {showDeletePrompt = false; deleteFile();}"
      />
    </popup>
    <Button
           slot="mainContent"
           @click="loadFiles"
           type="reload"
           icon="redo"
           class="icon-button"
       />
       {{ createFileName }}
    <Button
      class="mt-3 icon-rotate-animation make-longer-animation"
      @click="createFileName = 'default.txt'; createFileContent = 'hello world'; creating = true;">{{ $t('createNewTypeFile', {type: 'text'}) }} <icon type="cube" />
    </Button>
    <div v-if="creating">
       <input type="text" class="form-text" v-model="createFileName" />
      <component
        v-if="createFileName && createFileContent"
        :is="'codemirror'"
        :value="createFileContent"
        @save="saveFileContent"
        @abort="createFileName = ''; createFileContent = ''; creating = false;"
       />
    </div>


    <div v-if="files" :key="index" class="form-section row" v-for="(file, index) in files">
      <preview
          v-if="editFileName !== file.key"
          class="col-12 col-md-3 align-self-start"
          :preview-uri="`${targetId}/${file.key}`"
          :removable="false"
          :source="storeName"
          :downloadable="true"
          :preview-id="`${targetId}/${file.key}`"
      />
      <div :class="[editFileName === file.key ? 'col-12 col-md-12' : 'col-12 col-md-8']">
        <h4 class="bg-beige p-4">
          <div style="margin-top: -10px;" class="darker float-right p-2 pseudo-button ml-2" v-if="previewUri && previewUri.split('/')[0] === $route.params.id && previewUri.split('/')[1] === file.key"><icon type="eye" /> {{ $t('isPreview') }}</div>
          <div v-else-if="['png', 'jpg', 'jpeg'].includes(getExtension(file.key).toLowerCase())">
            <Button style="margin-top: -10px;" class="float-right" @click="setPreview(file.key)"><icon type="camera-retro" />{{ $t('setAsPreview') }}</Button>
          </div>
           <div v-if="['json', 'txt'].includes(getExtension(file.key).toLowerCase())">
            <Button v-if="!editFileName" style="margin-top: -10px;" class="float-right ml-2" @click="editFileName = file.key; loadFileContent(file.key)"><icon type="camera-retro" />{{ $t('editFile') }}</Button>
            <component
              v-if="editFileName === file.key && editFileContent"
              :is="'codemirror'"
              :value="editFileContent"
              @save="saveFileContent"
              @abort="editFileName = ''; editFileContent = ''"
              />
          </div>
            {{ file.key }}
          <div>
          </div>
        </h4>

        <span class="lighter">{{ $t('lastModified') }}:</span> {{ dateTimeFromISO(file.lastModified) }}<br />

        <span class="lighter">{{ $t('original filename') }}:</span> {{ file.metaData.filename }}<br />
        <span class="lighter">{{ $t('key') }}:</span> {{ file.key }}<br />
        <span class="lighter">{{ $t('size') }}:</span> {{ file.size / 1000000 }} MB<br />
        <span class="lighter">{{ $t('content-type') }}:</span> {{ file.metaData['content-type'] }}<br />

        <span v-if="file.metaData && file.metaData.attributes" class="lighter">{{ $t('attributes') }}:</span>
        <div v-if="file.metaData && file.metaData.attributes">
          <div
               class="slightly-darker p-2 col-12 mt-1"
              v-for="(val, key) in file.metaData.attributes"
              :key="key"
          >
            {{ key }}: {{ val }}
          </div>
        </div><br />
        <div class="d-flex">
          <Button
            class="mt-3 mr-1 icon-rotate-animation make-longer-animation"
            @click="deleteFile(file.key)">{{ $t('delete') }} <icon type="cube" /></Button>
          <Button v-if="['fbx', 'FBX'].includes(getExtension(file.key).toLowerCase()) && storeName === 'Asset' && ($store.getters.isSFXMember || $store.getters.isSuperAdmin)" class="mt-3 mr-1 make-longer-animation" @click="createDefaultJSON(file.key)"><icon type="camera-retro" />{{ $t('createUpdateDefaultJson') }}</Button>
          <Button v-if="copying !== file.key" class="mt-3 mr-1 make-longer-animation" @click="copying = file.key"><icon type="copy" />{{ $t('duplicateFile') }}</Button>
          <div class="mt-3" v-else-if="copying === file.key"><div class="d-flex position-relative"><input style="padding-right: 53px" type="text" class="form-text" v-model="copyFileName" /><div class="center-horizontally" style="right:5px; padding-left: 5px;">.{{ getExtension(file.key) }}</div></div>
          <div class="edit-trigger mr-2" @click="copyFileName = ''; copying = ''">
                <icon :type="'times'"/>
            </div>
            <div v-if="copyFileName && (copyFileName + '.' + getExtension(file.key)) !== file.key" class="edit-trigger" @click="copy(file.key)">
                <icon :type="'save'"/>
            </div>
            </div>
        </div>
      </div>

    </div>
    <portal to="sidebarLeft">
      <div class="row special">
        <upload-drop-zone
            class="col-12"
            :asset-id="storeName === 'Asset' ? targetId : null"
            :project-id="storeName === 'Project' ? targetId : null"
            :downloadable="false"
            :removable="true"
            form-id="uploadAnything"
            :target-type="storeName"
            :auto-update-preview="false"
            :attributes="attributes"
            :auto-upload="false"
            @fileReceived="loadFiles"
            ref="uploader"
            :key="forceReRenderKey"
            :use-default-key="false"
            :make-chunks="true"
        />
        <codemirror
            class="col-12"
            slot="info"
            :value="attributes ? attributes : '{}'"
            :show-save-button="false"
            :key="forceReRenderKey + 1"
            ref="attributes"
        />
        <div class="col-12">
          <Button
              class="mt-3 icon-rotate-animation make-longer-animation"
              @click="startUpload">{{ $t('upload') }} <icon type="cube" />
          </Button>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import UploadDropZone from "@/components/files/UploadDropZone";
import Codemirror from "@/components/widgets/editors/Codemirror";
import Button from "@/components/forms/Button";
import Icon from "@/components/Icon";
import DateMixin from "@/components/mixins/DateMixin.js";
import Popup from "@/components/Popup";
import DeletePrompt from "@/components/forms/DeletePrompt";
import Preview from "@/components/preview/Preview";
import FileTypeMixin from "@/components/files/FileTypeMixin.js";

export default {
  name: "FileBrowser",
  components: {
    UploadDropZone,
    Codemirror,
    Button,
    Icon,
    Popup,
    DeletePrompt,
    Preview
  },
  mixins: [DateMixin, FileTypeMixin],
  props: {
    storeName: {type: String, required: true},
    targetId: {type: String, required: true},
  },
  data() {
    return {
      files: [],
      attributes: '',
      showDeletePrompt: false,
      forceReRenderKey: 0,
      previewUri: '',
      copying: '',
      copyFileName: '',
      editFileName: '',
      editFileContent: '',
      createFileContent: '',
      creating: false
    };
  },
  beforeMount() {
    this.loadFiles();
    this.loadItem();
    //this.$store.dispatch();
  },
  methods: {
    loadFiles() {
      this.$store.dispatch(`load${this.storeName}Files`, {id: this.targetId})
          .then(data => {
            this.files = data;
            this.forceReRenderKey++;
            this.attributes = {};
          })
    },
    loadItem() {
       this.$store.dispatch(`clientLoad${this.storeName}`, {id: this.targetId})
          .then(data => {
            //console.log(data);
            this.previewUri = data.previewUri;
          })
    },
    startUpload() {
      this.attributes = this.$refs.attributes.save();
      //console.log(this.attributes);
      setTimeout(()=>{
        this.$refs.uploader.resumeUpload();
      }, 400);
    },
    deleteFile(key = '') {
      if(this.toBeDeleted) {
        this.$store.dispatch(`clientDelete${this.storeName}Part`, {id: this.targetId, key: this.toBeDeleted}).then(() => {
          this.loadFiles();
          this.toBeDeleted = null;
        })
      }
      else {
        this.toBeDeleted = key;
        this.showDeletePrompt = true;
      }
    },
    setPreview(key) {
       this.$store.dispatch(`clientSave${this.storeName}`, {id: this.targetId, previewUri: `${this.targetId}/${key}`})
          .then(data => {
            this.previewUri = data.previewUri;
          })
    },
    createDefaultJSON(key) {
      this.$store.dispatch('clientCreateDefaultJson', {
        id: this.targetId,
        jsonFileName: 'default.json',
        transformFileName: key
      })
      .then(() => {
        const $this = this;
        setTimeout(function() {
          $this.loadFiles()},
          3000);
      })
    },
    loadFileContent(key) {
      this.$store.dispatch(`clientDownload${this.storeName}`, {
        id: this.targetId,
        key: key
      }).then(data => {
        //console.log(data);
        this.editFileContent = JSON.stringify(data.body ? data.body : data.text);
      })
    },
    saveFileContent(value) {
      this.$store.dispatch(`create${this.storeName}TextFile`, {
        id: this.targetId,
        fileName: this.creating ? this.createFileName : this.editFileName,
        fileContent: value
      }).then(() => {
        this.editFileContent = '';
        this.editFileName = '';
        this.creating = false;
        this.createFileContent = '';
        this.createFileName = '';
      })
    },
    copy(originalFileName) {
      this.$store.dispatch(`clientDuplicate${this.storeName}File`, {
        id: this.targetId,
        fileName: originalFileName,
        newFileName: this.copyFileName + '.' + this.getExtension(originalFileName),
      }).then(() => {
        this.copyFileName = '';
        this.copying = '';
        const $this = this;
        setTimeout(function() {
          $this.loadFiles()},
          3000);
      })
    }
  },
}
</script>