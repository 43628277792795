import Vue from 'vue';

const state = {
    currentState: '',
    browserType: 'default',
    osType: 'default',
    log: [],
    debug: false,
    login: {
        param1: null
    },
};
const getters = {
    getLogin() {
        return state.login;
    },
    getvStageModule() {
        return true;
    },
    getFullState() {
        return state;
    },
    getvStageCommunications() {
        return state;
    },
    getvStageProjectId() {
        return state.project && state.project.param1 ? state.project.param1 : null;
    },
    getvStageLoginStatus() {
        return state.app && state.app.command === 'login' && state.app.status ? state.app.status : false;
    },
    getvStageAppStatus() {
        return state.app && state.app.status ? state.app.status : false;
    },
    getvStageMenuStatus() {
        return state['app.menu'] && state['app.menu'].status ? state['app.menu'].status : false;
    },
    getvStageDownloadStatus() {
        return state['app.menu'] && state['app.menu'].status && state['app.menu'].command === 'download' ? state['app.menu'].status : false;
    },
    getvStageStartStatus() {
        return state['app.menu'] && state['app.menu'].command === 'start' && state['app.menu'].status ? state['app.menu'].status : false;
    },
    getBlockAppsStatus() {
        return state.blockApps ? state.blockApps : false;
    },
    getDebugActive() {
        return state.debug;
    },
    getBrowserType() {
        return state.type;
    },
    getOSType() {
        return state.osType;
    },
    getLog() {
        //return state.log;
        return state.log.length ? state.log.join('<br />') : '';
    }
};
const mutations = {
    vStageIncomingCommunicateMutation(state, args) {
        if(args.command === 'debug') {
            Vue.set(state, 'debug', args.param1);
        }
        else {
            let object = JSON.parse(JSON.stringify(args));
            delete object.realm;
            
            Vue.set(state, args.command, {});
            const keys = Object.keys(object);
            for(let i = 0; i < keys.length; i++) {
                const key = keys[i];
                Vue.set(state[args.command], key, object[key]);
            }
        }
    },
    // eslint-disable-next-line no-unused-vars
    terminateMutation(state) {
        state = {};
    },
    blockAppsMutation(state, blockApps) {
        Vue.set(state, 'blockApps', blockApps);
    },
    setBrowserTypeCommit(state, type) {
        Vue.set(state, 'browserType', type);
    },
    setOSTypeCommit(state, type) {
        Vue.set(state, 'osType', type);
    },
    logEventMutation(state, string) {
        let log = state.log;
        log.push(string);
        Vue.set(state, 'log', log);
    },
};
const actions = {
    logEvent: ({commit}, string) => {
        commit('logEventMutation', string);
    },
    //incoming communication
    vStageCommunicate: ({commit, dispatch}, args) => {
        console.log('cc light received communication from vStage...' + JSON.stringify(args));
        
        dispatch('logEvent', 'received communication from vStage...' + args);
        
        const json = JSON.parse(args);
        if(json.realm === "login") {
            dispatch('setAuthToken', json.param1);
            dispatch('checkLogin', {authToken: json.param1});
            dispatch('autoRefreshToken');
        }
        commit('vStageIncomingCommunicateMutation', json);
    },
    terminate: ({commit}) => {
        commit('terminateMutation');
    },
    blockApps: ({commit}, blockApps = true) => {
        commit('blockAppsMutation', blockApps);
    },
    setBrowserType: ({commit}, type) => {
        commit('setBrowserTypeCommit', type);
    },
    setOSType: ({commit}, type) => {
        commit('setOSTypeCommit', type);
    },
    communicateUserLogin: ({dispatch}, args) => {
        //console.log('sending: app.login with password');
        dispatch('logEvent', 'sending: app.login with password');
        let userName = args.userName;
        let password = args.password;
        let json = JSON.stringify({
            "realm": "app",
            "command": "login",
            "param1": userName,
            "param2": password
        });
        dispatch('communicateToOutside', json);
    },
    //status can either be true or false
    communicateOverlayStatus: ({dispatch}, status) => {
        //console.log('sending: overlay status (true or false)');
        dispatch('logEvent', 'sending: overlay status (true or false)');
        let json = JSON.stringify({
            "realm": "app",
            "command": "webglOverlay",
            "param1": status,
        });
        dispatch('communicateToOutside', json);
    },
    //required: args.appId, args.externalId, args.baseProjectId
    communicateDownloadProject: ({dispatch}, args) => {
        //console.log('sending: download project');
        dispatch('logEvent', 'sending: download project: ' + JSON.stringify(args));
        //console.log(JSON.stringify(args));
        const json = JSON.stringify({
            "realm": "app.menu",
            "command": "download",
            "param1": args.baseProjectId,
        });
        dispatch('communicateToOutside', json);
    },
    communicateLogout: ({dispatch}) => {
        const json = JSON.stringify({
            "realm": "app",
            "command": "logout",
        });
        dispatch('communicateToOutside', json);
    },
    communicateStartProject: ({dispatch}, args) => {
        //console.log('sending: start project' + JSON.stringify(args));
        const json = JSON.stringify({
            "realm": "app.menu",
            "command": "start",
            "param1": args.baseProjectId,
        });
       dispatch('communicateToOutside', json);
    },
    communicateReadyState: ({dispatch}) => {
        //console.log('sending: start project' + JSON.stringify(args));
        const json = JSON.stringify({
            "realm": "app",
            "command": "ready",
        });
       dispatch('communicateToOutside', json);
    },
    // eslint-disable-next-line no-empty-pattern
    communicateWithIFrame: ({}, args) => {
          const {host, message} = args;
          console.log("Sending to webapp: " + host)
          console.log(message);
          if(window.parent) {
             window.parent.postMessage(JSON.stringify(message), host)
          }
    },
    // eslint-disable-next-line no-unused-vars
   communicateToOutside: ({state}, json) => {
        //const browserType = state.browserType;
        console.log(json);
        //const stringified = JSON.stringify(json);
        /*if(browserType === 'defaultBrowser') {
            window.vueUnityInstance.SendMessage("API", "Receive", stringified);
        }
        else if(browserType === "vuplex") {*/
            // check if vuplex is initalized before sending the message
            if (window.vuplex) {
                console.log("sending post message to vuplex directly")
                // The window.vuplex object already exists, so go ahead and send the message.
                window.vuplex.postMessage(json);
            } else {
                console.log("sending post message to vuplex by event listener")
                // The window.vuplex object hasn't been initialized yet because the page is still
                // loading, so add an event listener to send the message once it's initialized.
                window.addEventListener('vuplexready', () => {window.vuplex.postMessage(json)});
            }
            
            
        /*}*/
    }
};
export default {
    state,
    getters,
    mutations,
    actions
}
