<template>
    <div :class="[showInline ? 'inline-block' : '']" :key="forceReRenderKey">
        <div v-if="storeName && $store.getters['get' + storeName + 'ById'](value) && $store.getters['get' + storeName + 'ById'](value).name">
            {{ storeName && $store.getters['get' + storeName + 'ById'](value).name }}
        </div>
        <div v-else-if="!showPreview">
            {{ contentId }}
        </div>
        <div v-if="showTeams">{{ $store.getters['get' + storeName + 'ById'](value).teams }}</div>
        <preview
                class="medium-thumbnail mb-1"
                :asset-or-project-id="value"
                v-if="showPreview && $store.getters['get' + storeName + 'PreviewUri'](value)"
                :preview-uri="$store.getters['get' + storeName + 'PreviewUri'](value)"
                :type="'image'"
                :preview-id="value"
                :removable="false"
        >
            <div slot="afterpreview" class="preview-label" v-if="$store.getters['get' + storeName + 'ById'](value) && $store.getters['get' + storeName + 'ById'](value).name">
                <h4>{{ $store.getters['get' + storeName + 'ById'](value).name }}</h4>
                <div v-if="teams" class="bg-highlight">
                 <div class="mt-1" :key="index + 100000" v-if="checkTeamsError($store.getters['get' + storeName + 'ById'](value).teams, team)" v-for="(team, index) in teams">
                 <span class="lighter">{{ $t('teamsNotMatching') }}</span>
                {{ team.displayName }}
                </div>
                </div>
            </div>
        </preview>
    </div>
</template>

<script>
    import {MetaFieldStores} from '../../../enum';
    import Preview from "../../preview/Preview";
    /**
     * The quick display of the title and (optional) preview of an item
     * @displayName Content Display
     */
    export default {
        name: "ContentDisplay",
        components: {
            Preview,
        },
        props: {
            /**
             * The selected item id
             * @values item-ID
             */
            value: {type: String, required: true},
            /**
             * The type to be listed
             * @values feed, table, dataset, project, app, scene, model, assembly, node, media
             */
            type: {type: String, default: null},
            /**
             * Whether to display a preview or not (if the content type doesn't have a preview property, it will be black)
             * @values true or false
             */
            showPreview: {type: Boolean, default: false,},
            /**
             * Whether to display it as an inline-element or not
             * @values true or false
             */
            showInline: {type: Boolean, default: false,},
            /**
             *
             * */
            mediaCategory: {type: Number, default: null,},

            teams: {type: Array, default: null,},
        },
        data() {
            return {
                showData: false,
                storeName: '',
                subType: '',
                contentId: this.value,
                forceReRenderKey: 0,
            };
        },
        watch: {
            value: function () {
                this.loadData();
            }
        },
        beforeMount()  {
            this.loadData();
        },
        methods: {
            /**
            * Checks whether the contents teams contains all of the parents teams or not
            * **/
            checkTeamsError(contentTeams, parentTeam) {
              if(contentTeams) {
                if(!contentTeams.length) {
                  return true;
                }
                return contentTeams.filter(item => { return item.id === parentTeam.id}).length === 0;
              }
              return false;
            },
            setStore() {
              if(MetaFieldStores[this.type].name) {
                this.subType = MetaFieldStores[this.type].subType;
                this.storeName = MetaFieldStores[this.type].name;
              } else {
                this.storeName = MetaFieldStores[this.type];
              }
            },
            loadData: function () {
                this.setStore();
                if (this.value && this.value !== '(empty)' && this.storeName) {
                  let args = {
                    id: this.value,
                    include: 'teams'
                  };
                  if(this.subType) {
                    args.add = {
                        filter: {
                          default: 'type eq ' + this.subType
                        }
                    }
                  }
                  if(this.showTeams) {
                    args.keep = {include: ['teams']}
                  }
                  console.log(args);
                  this.$store.dispatch('load' + this.storeName, args);
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .preview-label {
        width: 100%;
        padding: 15px;
        background-color: $background;
    }
    .item-chooser {
        padding:15px;
        background: $panel-background-color;
        cursor:pointer;
        &:hover, &.selected {
            background: $highlight;
        }
    }
</style>

<docs>
    Vue component examples:

    A media content display with preview:
    ```jsx
    <content-display
            class="mt-1"
            v-if="mediaLink"
            :list-name="id"
            type="media"
            :value="mediaLink"
            :show-preview="true"
    />
    ```
</docs>