<template>
    <div
        class="fancy-scrollbar pr-1"
        style="height: calc(100% - 120px); overflow-y: scroll; overflow-x: hidden"
    >
        <div v-for="(step, index) in config.steps" :key="index + forceReRenderKey">
            <div
                :class="['drop-zone', highlightDropZones ? 'highlight' : '']"
                @drop="onDrop($event, index)"
                @dragover.prevent
                @dragenter="dragenter($event)"
                @dragleave="dragleave($event)"
            ></div>
            <div
                draggable
                @dragstart="startDrag($event, step.uuid)"
                @dragend="dragend($event)"
                :class="[
                    'pr-1 drag-el d-flex',
                    step.uuid === activeStep ? 'active' : '',
                    //step.warnings ? 'step-warning' : '',
                ]"
            >
                <div
                    @click="setActive(step.uuid)"
                    class="pl-2"
                    style="
                        margin-top: auto;
                        margin-bottom: auto;
                        width: 15px;
                        cursor: pointer;
                    "
                >
                    {{ step.step }}
                </div>
                <div class="col-xxl-9 col-8 pl-2 pr-0">
                    <input
                        @click="setActive(step.uuid)"
                        class="mt-1 mb-1 form-text v-form-label form-text-dark"
                        type="text"
                        v-model="step.name"
                    />
                </div>
                <div class="col-xxl-3 col-4 p-0 d-flex">
                    <div v-if="step.warnings"
                        style="margin: auto; color: #da5e06; cursor: pointer"
                        @click="validate"
                    >
                        <icon type="exclamation-triangle" />
                    </div>
                    <div
                        style="margin: auto; cursor: pointer"
                        @click="deleteStep(step.uuid)"
                    >
                        <icon type="trash-alt" />
                    </div>
                    <div style="margin: auto">
                        <icon class="mr-2 drag-grip" type="grip-vertical" />
                    </div>
                </div>
            </div>
        </div>
        <div
            :class="['drop-zone', highlightDropZones ? 'highlight' : '']"
            style="min-height: 20px"
            @drop="onDrop($event, 2000)"
            @dragover.prevent
            @dragenter="dragenter($event)"
            @dragleave="dragleave($event)"
        ></div>
    </div>
</template>

<script>
import $ from "jquery";
import Icon from "@/components/Icon";
//import Button from "@/components/forms/Button";
export default {
    name: "vFormIndexSteps",
    components: {
        //Button,
        Icon,
    },
    props: {
        config: { type: Object, required: true },
        thumbs: { type: Object, default: null },
        slides: { type: Array, default: null },
        initialActiveStep: { type: String, default: null },
    },
    data() {
        return {
            forceReRenderKey: 0,
            activeStep: null,
            tmpScrollLeft: 0,
            highlightDropZones: false,
        };
    },
    watch: {
        thumbs: {
            deep: true,
            handler() {},
        },
        slides: {
            deep: true,
            handler() {
                this.forceReRenderKey++;
            },
        },
    },
    beforeMount() {
        this.activeStep = this.initialActiveStep
            ? this.initialActiveStep
            : this.config.steps[0].uuid;

        this.validate();
    },
    mounted() {},
    methods: {
        validate() {
            this.config.steps.forEach((step) => {
                if (step.elements) {
                    step.warnings = null;
                    step.elements.forEach((element) => {
                        element.warnings = null;
                        if (element.options) {
                            element.options.forEach((option) => {
                                if (option.targetStep) {
                                    if (
                                        !this.config.steps.find(
                                            (s) => s.uuid === option.targetStep
                                        )
                                    ) {
                                        step.warnings = true;
                                        element.warnings = true;
                                    }
                                }
                            });
                        }
                    });
                }
            });
        },
        dragenter(evt) {
            evt.target.classList.add("drop-active");
        },
        dragleave(evt) {
            evt.target.classList.remove("drop-active");
            evt.srcElement.classList.remove("drop-active");
        },
        startDrag(evt, uuid) {
            this.highlightDropZones = true;
            evt.srcElement.classList.add("drag-active");
            evt.dataTransfer.dropEffect = "move";
            evt.dataTransfer.effectAllowed = "move";
            evt.dataTransfer.setData("uuid", uuid);
        },
        dragend(evt) {
            this.highlightDropZones = false;
            evt.srcElement.classList.remove("drag-active");
        },
        onDrop(evt, targetIndex) {
            const uuid = evt.dataTransfer.getData("uuid");
            var sourceIndex = this.config.steps.findIndex((x) => x.uuid === uuid);
            let el = this.config.steps[sourceIndex];
            if (targetIndex > this.config.steps.length) {
                targetIndex = this.config.steps.length + 1;
            }
            //avoid move blow one item when not necessary
            if (targetIndex != sourceIndex + 1) {
                if (targetIndex < this.config.steps.length) {
                    this.config.steps.splice(sourceIndex, 1);
                    this.config.steps.splice(
                        targetIndex > sourceIndex ? targetIndex - 1 : targetIndex,
                        0,
                        el
                    );
                } else {
                    this.config.steps.splice(sourceIndex, 1);
                    this.config.steps.push(el);
                }
            }
            evt.srcElement.classList.remove("drop-active");
            for (let index = 0; index < this.config.steps.length; index++) {
                const element = this.config.steps[index];
                element.step = index + 1;
            }

            this.highlightDropZones = false;
            this.forceReRenderKey++;
        },
        setActive(uuid) {
            this.activeStep = uuid;
            this.$emit("selected", uuid);
            this.validate();
        },
        deleteStep(uuid) {
            this.$emit("deleteStep", uuid);
        },
        // eslint-disable-next-line no-unused-vars
        scrollToThumbnail(pos) {
            if (!this.tmpScrollLeft) {
                this.tmpScrollLeft = $("#v-form-outer").offset().left;
            }

            $("#v-form-outer")
                .stop()
                .animate(
                    {
                        scrollLeft: pos - $("#v-form-outer").width() / 2,
                        easing: "linear",
                    },
                    50
                );
        },
    },
};
</script>

<style lang="scss" scoped>
.v-form-index {
    // position: fixed;
    // right: 15px;
    // bottom: 0;
    // z-index: 55;
    // height: 175px;
    // overflow-x: scroll;
    // width: 80vw;
    // background-color: #222;
    // padding: 15px;
    .slide {
        // width: 150px;
        // display: inline-block;
        // margin-right: 5px;
        // position: relative;
        .add-step-box {
            opacity: 0;
        }
        &:hover,
        &.active {
            .slide-img,
            .slide-img-faker {
                border: 3px solid $highlight;
            }
        }
        &:hover {
            .slide-mg-faker {
                background-color: $highlight;
            }
            .add-step-box {
                opacity: 1;
            }
        }
        &.no-steps,
        &.no-steps:hover {
            .slide-img,
            .slide-img-faker {
                border: 3px solid #222;
            }
            opacity: 0.3;
        }
        &.no-steps:hover {
            opacity: 1;
        }
    }
}

.slide-img-faker {
    background-color: #222;
    width: 100%;
    min-height: 98px;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
}
.add-step-box {
    position: absolute;
    top: 30%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 130px;
    background-color: $highlight;
    border-radius: 0.4em;
    padding: 15px;
}

.add-step-box:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: $highlight;
    border-bottom: 0;
    margin-left: -10px;
    margin-bottom: -10px;
}

/* Marius styles */
.v-form-index {
    display: flex;
    flex-wrap: wrap;
    .slide {
        width: 45%;
        position: relative;
        margin: 0.25rem;
    }

    .slide-img {
        width: 100%;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        border: 3px solid #343434;
    }
}

.active {
    border: solid 2px $highlight !important;
    border-radius: 3px;
    //background-color:#da5e06;
}

.slideNumber {
    position: absolute;
    bottom: 2px;
    left: 3px;
}

.drop-zone {
    min-height: 20px;
}
.highlight {
    border: dotted 2px grey;
    border-radius: 6px;
}

.drop-active {
    background-color: $highlight;
}

.drag-el {
    border: solid 2px #2d2d2d;
    border-radius: 3px;
    background: #2d2d2d;
}

.form-text-dark {
    background-color: #1f1f1f !important;
}

.drag-active {
    background: #999;
    border: solid 2px #999;
}

.drag-grip {
    cursor: move;
    color: grey;
}

.drag-grip:hover {
    color: white;
}

.step-warning {
    background: pink;
}
</style>
