<template>
  <div>
    <h1>
      <icon class="mr-2" type="poll-h" size="0.9"/>
      {{ $t('createNewForm') }}
    </h1>
    <organization-switcher
        @setOrganization="id => {localOrganizationId = id}"
        @setTeam="id => {teamId = id}"
        @unsetTeam="teamId = ''"
        :pre-set-organization-id="organizationId"
        class="mb-2"
     />
    <input
        type="text"
        :class="['form-text mt-3 mb-3', $v.name.$dirty && $v.name.$error ? 'form-group--error' : '']"
        :id="'name'"
        v-model="name"
        :placeholder="$t('enternamehere')"
        @input="delayTouch($v.name)"
    >

    <div class="form-error" v-if="$v.name.$dirty && !$v.name.required && $v.name.$error">{{
        $t('errors.required')
      }}
    </div>
    <div class="form-error" v-if="$v.name.$dirty && !$v.name.alphaNumSpace">{{ $t('errors.alphaNumSpaceOnly') }}</div>

    <div class="form-error" v-if="$v.name.$dirty && !$v.name.maxLength">{{
        $t('errors.atMostCharacters', {num: 128})
      }}
    </div>

    <div class="form-error" v-if="$v.name.$dirty && !$v.name.minLength">{{
        $t('errors.atLeastCharacters', {num: 3})
      }}
    </div>
    <div class="form-error" v-if="$v.name.$dirty && !$v.name.isUnique">{{ $t('errors.formNameAlreadyExists') }}</div>

    <div class="inline-edit-label">
      <b>{{ $t('description') }}</b>
    </div>
    <div>
    <textarea
      :class="['form-text mb-3', $v.description.$dirty && $v.description.$error ? 'form-group--error' : '']"
      :id="'description'"
      v-model="description"
      :placeholder="$t('enterdeschere')"
      @input="delayTouch($v.description)"
    />
      <div class="form-error" v-if="$v.description.$dirty && !$v.description.maxLength">{{
          $t('errors.atMostCharacters', {num: 128})
        }}
      </div>
    </div>

    <div v-if="showCodemirror">
      {{ $t('configuration') }}
    </div>
    <codemirror
        v-if="showCodemirror"
        :show-save-button="false"
        @change="updateValue"
    />
    <div class="form-error" v-if="$v.content.$dirty && !$v.content.required && $v.content.$error">{{
        $t('errors.required')
      }}
    </div>
    <Button
        type="button"
        class="mb-3 mt-5"
        :deactivated="!enableSaving"
        @click="save"
    >
      <icon v-if="enableSaving" type="save"/>
      <icon v-else type="ban"/>
      {{ $t('SAVE') }}
    </Button>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import {required, minLength, maxLength} from 'vuelidate/lib/validators';
import {alphaNumSpace} from '@/customValidators';
import OrganizationSwitcher from "@/components/organizations/OrganizationSwitcher";
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
import Codemirror from "@/components/widgets/editors/Codemirror";
import Button from "@/components/forms/Button";

export default {
  name: "FormAdd",
  components: {
    Icon,
    OrganizationSwitcher,
    Codemirror,
    Button
  },
  mixins: [VuelidateMixin],
  props: {
    showCodemirror: {type: Boolean, default: false},
    organizationId: {type: String, default: null},
  },
  data() {
    return {
      id: '',
      enableSaving: false,
      name: '',
      description: '',
      content: '{}',
      localOrganizationId: '',
      teamId: ''
    };
  },
  watch: {
    '$v.$invalid': function () {
      this.enableSaving = !this.$v.$invalid;
      this.$emit('enableSaving', !this.$v.$invalid);
    },
  },
  beforeMount() {
    if(this.organizationId) {
      this.localOrganizationId = this.organizationId;
    }
  },
  validations: {
    content: {
      required,
    },
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(127),
      alphaNumSpace,
      async isUnique(value) {
        // standalone validator ideally should not assume a field is required
        if (value === '') {
          return true;
        }
        let bool = true;
        await this.$store.dispatch('checkIfFormNameExists',
            {name: value, type: 'Asset', value: value, organizationId: this.localOrganizationId,}).then(data => {
          bool = data.length === 0;
        });
        return bool;
      },
    },
    description: {
      maxLength: maxLength(1024),
    }
  },
  methods: {
    updateValue: function (val) {
      this.content = val;
    },
    save: async function () {
      if (this.enableSaving) {
        let args = {
          name: this.name,
          slug: this.name,
          description: this.description,
          type: 'form',
          content: this.content,
          organizationId: this.localOrganizationId,
          teams: this.teamId ? [this.teamId] : []
        };
        await this.$store.dispatch('createForm', args).then(form => {
          this.$emit('created', form.id);
        });
      }
    },
  },
}
</script>