<template>
  <div class="p-2 mb-3">
  <!--NO TRANSLATION HERE-->
    <div class="col-3 pt-2 mb-2">
    </div>
    <div class="col-12"><slot /></div>
  </div>
</template>

<script>
import {vFormControls} from '@/enum';

export default {
  name: "vFormSEPARATOR",
  components: {
  },
  props: {
    element: {type: Object, required: true},
  },
  data() {
    return {
      vFormControls: vFormControls
    };
  },
  beforeMount() {
    this.element.formElementType = vFormControls['SEPARATOR'];
  },
  methods: {
    save() {
      this.$emit('save', this.element);
    }
  }
}
</script>

<style scoped>

</style>