<template>
<div>
  {{ getLabel(config, lang) }}
  <div class="clickable slide-control-element mb-2" @click="$emit('goToSlide', getTargetSlide(option))" :key="index" v-for="(option, index) in config.options">
    {{ getText(option, lang) }}
  </div>
</div>
</template>

<script>
import UtilsMixinJs from "@/components/vForm/viewer/UtilsMixin.js";

export default {
  name: "SlideControl",
  props: {
    config: {type: Object, required: true},
    lang: {type: String, required: true}
  },
  mixins: [UtilsMixinJs],
  methods: {
    validate() {
        return true;
    },
    checkRequired() {
       return true;
    },
    getLogEntries() {
        return [];
    }
  }
}
</script>

<style lang="scss" scoped>
  .slide-control-element {
    background-color: $highlight-color;
    padding:8px;
    color: $on-dark-color;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    &:hover {
      background-color: lighten($highlight-color, 10%);
    }
  }
</style>