import { render, staticRenderFns } from "./vFormViewer.vue?vue&type=template&id=24b80ac5&scoped=true&"
import script from "./vFormViewer.vue?vue&type=script&lang=js&"
export * from "./vFormViewer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24b80ac5",
  null
  
)

export default component.exports