<template>
    <div :style="{ 'z-index': showSelect ? 80 : 0 }" class="special-select-outer row">
        <div v-if="selected" class="selected d-flex col-10" style="flex-wrap: wrap">
            <!-- <span v-if="!checkSlideValidity(selected)"
                ><icon type="exclamation-triangle" /> {{ $t("invalid") }}</span
            > -->
            <div
                v-for="(uuid, index) in this.selected"
                :key="index"
                class="d-flex mb-2 ml-1"
            >
                <div
                    v-if="selected.length > 1"
                    :id="'drop-zone' + index"
                    :class="[
                        'drop-zone slide-drop-zone',
                        highlightDropZones ? 'highlight' : '',
                    ]"
                    @drop="onDrop($event, index)"
                    @dragover.prevent
                    @dragenter="dragenter($event)"
                    @dragleave="dragleave($event)"
                ></div>
                <div class="">
                    <img
                        :id="'img' + index"
                        draggable
                        @dragstart="startDrag($event, uuid)"
                        @dragend="dragend($event)"
                        :src="thumbs[uuid]"
                        v-if="thumbs[uuid]"
                        :class="[
                            'drag-el slide-img small',
                            selected.length === 1 ? 'ml-3' : '',
                        ]"
                    />
                    <div style="position: relative">
                        <img
                            :id="'img' + index"
                            draggable
                            @dragstart="startDrag($event, uuid)"
                            @dragend="dragend($event)"
                            v-if="!thumbs[uuid]"
                            :class="[
                                'drag-el slide-img small slide-not-found',
                                selected.length === 1 ? 'ml-3' : '',
                            ]"
                            alt="Not Found"
                        />
                        <Button
                            class="icon-button"
                            v-if="!thumbs[uuid]"
                            @click="removeSlide(uuid)"
                            style="position: absolute; left: 58px; top: 25px"
                        >
                            <icon type="trash-alt" />
                        </Button>
                    </div>
                </div>
            </div>
            <div
                v-if="selected.length > 1"
                id=""
                :class="[
                    'drop-zone slide-drop-zone mb-2',
                    highlightDropZones ? 'highlight' : '',
                ]"
                @drop="onDrop($event, 2000)"
                @dragover.prevent
                @dragenter="dragenter($event)"
                @dragleave="dragleave($event)"
            ></div>
        </div>
        <div class="col-2 text-right">
            <div class="clickable d-inline-block mr-3" @click="toggleSelect">
                <icon :type="showSelect ? 'times' : 'edit'" />
            </div>
        </div>
        <popup
            @close="
                () => {
                    showSelect = false;
                }
            "
            v-if="showSelect"
        >
            <div slot="popupMainContent" v-if="showSelect" class="">
                <div class="d-flex" style="flex-wrap: wrap">
                    <div
                        @click="selectSlide(slide)"
                        :class="[
                            'option ml-2 mb-2',
                            isSlideSelected(slide) ? 'active' : '',
                            isSlideLinkedToStep(slide) ? 'slide-locked' : 'clickable',
                        ]"
                        :key="index"
                        v-for="(slide, index) in slides.filter((x) => x.id !== -1)"
                    >
                        <div class="slide-container">
                            <div class="ml-1 mb-1">
                                Slide {{ slide.id !== -1 ? slide.id : "none" }}
                            </div>
                            <div>
                                <img
                                    v-if="thumbs[slide.uuid]"
                                    :key="index + forceReRenderKey"
                                    :class="['slide-img m-1']"
                                    :src="thumbs[slide.uuid]"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <Button @click="clearSelection">{{ $t("Reset") }}</Button>
                    </div>
                    <div class="col-6" style="text-align: end">
                        <Button @click="toggleSelect">{{ $t("Ok") }}</Button>
                    </div>
                </div>
            </div>
        </popup>
    </div>
</template>

<script>
import Icon from "@/components/Icon";
import Popup from "@/components/Popup";
import Button from "@/components/forms/Button";
export default {
    name: "slideSelector",
    components: {
        Icon,
        Popup,
        Button,
    },
    props: {
        thumbs: { type: Object, default: null },
        slides: { type: Array, default: null },
        initialSelected: { type: Array, default: null },
        displayAllSlides: { type: Boolean, default: false },
        excludeSlides: { type: Array, default: null },
    },
    data() {
        return {
            forceReRenderKey: 0,
            selected: [],
            showSelect: false,
            highlightDropZones: false,
        };
    },
    watch: {
        initiallySelected() {
            this.selected = this.initialSelected;
        },
    },
    beforeMount() {
        if (this.initialSelected) {
            this.selected = this.initialSelected;
        }
    },
    mounted() {},
    methods: {
        clearSelection() {
            this.selected = [];
        },
        checkSlideValidity(slide) {
            if (!slide || !slide.uuid) {
                return true;
            }
            return true;
        },

        selectSlide(slide) {
            if (!this.isSlideLinkedToStep(slide)) {
                const foundIndex = this.selected.findIndex((x) => x === slide.uuid);
                if (foundIndex >= 0) {
                    this.selected.splice(foundIndex, 1);
                } else {
                    this.selected.push(slide.uuid);
                }
                this.$emit("selected", this.selected);
            }
        },
        toggleSelect() {
            this.showSelect = !this.showSelect;
        },
        removeSlide(uuid) {
            var index = this.selected.findIndex((x) => x === uuid);
            this.selected.splice(index, 1);
        },
        isSlideLinkedToStep(slide) {
            if (!this.excludeSlides){
                return false;
            }
            
            return this.excludeSlides.find((x) => x === slide.uuid);
        },
        isSlideSelected(slide) {
            return this.selected.find((x) => x === slide.uuid);
        },
        dragend() {
            this.highlightDropZones = false;
        },
        dragenter(evt) {
            evt.target.classList.add("drop-active");
        },
        dragleave(evt) {
            evt.target.classList.remove("drop-active");
            evt.srcElement.classList.remove("drop-active");
        },
        startDrag(evt, uuid) {
            this.highlightDropZones = true;
            evt.dataTransfer.dropEffect = "none";
            evt.dataTransfer.effectAllowed = "move";
            evt.dataTransfer.setData("uuid", uuid);
        },
        onDrop(evt, targetIndex) {
            const uuid = evt.dataTransfer.getData("uuid");
            var sourceIndex = this.selected.findIndex((x) => x === uuid);
            let el = this.selected[sourceIndex];
            if (targetIndex > this.selected.length) {
                targetIndex = this.selected.length + 1;
            }

            //avoid move blow one item when not necessary
            if (targetIndex != sourceIndex + 1) {
                if (targetIndex < this.selected.length) {
                    this.selected.splice(sourceIndex, 1);
                    this.selected.splice(
                        targetIndex > sourceIndex ? targetIndex - 1 : targetIndex,
                        0,
                        el
                    );
                } else {
                    this.selected.splice(sourceIndex, 1);
                    this.selected.push(el);
                }
            }

            evt.srcElement.classList.remove("drop-active");
            this.highlightDropZones = false;
            //this.forceReRenderKey++;
        },
    },
};
</script>

<style lang="scss" scoped>
.slide-locked,
.slide-locked img {
    opacity: 0.4 !important;
}
.slide-not-found {
    min-height: 67px;
    min-width: 100px;
    background: transparent;
    border: solid 1px $highlight !important;
    color: white;
    font-size: 14px;
}
.special-select-outer {
    position: relative;
    img.small {
        width: 100px;
    }
}
.special-select {
    width: 150px;
    height: 200px;
    overflow-y: scroll;
    position: absolute;
    img {
        max-width: 100%;
    }
}
.option {
    border: 3px solid transparent;
    &:hover img {
        opacity: 1;
    }
    &.active {
        border: 3px solid $highlight;
    }
    &.active img {
        opacity: 1;
    }
}

.slide-container img {
    opacity: 0.8;
}

.slide-img {
    width: 250px;
    height: auto;
    &:hover {
        opacity: 0.8;
    }
}

.highlight {
    border: dotted 2px grey;
    border-radius: 6px;
}

.slide-drop-zone {
    width: 25px;
    min-height: 50px;
}
.drop-active {
    background-color: $highlight !important;
}

.drag-el {
    cursor: move;
    border: solid 1px white;
    border-radius: 2px;
}
</style>
