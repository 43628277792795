<template>
  <main-table-layout
    tab-selector="projecttab"
      type="project"
      back-button-tab-selector="projectbackbutton"
  >
      <div slot="mainContent" class="row">
      <slide-show-display
        :project-id="$route.params.id"
        :lang="lang ? lang : 'Unknown'"
        :show-images="true"
      />
  </div>
  </main-table-layout>
</template>

<script>
import SlideShowDisplay from "@/components/vForm/viewer/SildeShowDisplay";
import MainTableLayout from "@/layouts/MainTableLayout";
export default {
  name: "ProjectFormView",
  components: {
    SlideShowDisplay,
    MainTableLayout
  },
  props: {
    //lang: {type: String, required: true}
  },
  data() {
    return {
      lang: 'Unknown'
    };
  }
  }
</script>

<style scoped>

</style>