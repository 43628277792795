<template>
<div>

</div>
</template>

<script>
import UtilsMixinJs from "@/components/vForm/viewer/UtilsMixin.js";

export default {
  name: "META_LIST_FILTER",
  mixins: [UtilsMixinJs],
  props: {
    config: {type: Object, required: true},
    lang: {type: String, required: true}
  },
}
</script>

<style scoped>

</style>