<template>
    <div class="p-2 mb-3">
        <!--ADD DIX TRANSLATION-->
        <div class="col row mt-2 dflex">
            <!--FOR EACH TRANSLATION ONE FIELD-->
            <div
                v-if="element.currentLang === key"
                class="col-12 dflex"
                :key="index2 + 1000000000 + forceReRenderKey"
                v-for="(key, index2) in Object.keys(element.label.dix)"
            >
                <div class="" style="margin: auto">
                    <v-form-image-dix-cell :element="element" :image-dix="element.label.imageDix" previewSize="medium" />
                </div>
               
            </div>
        </div>
        <div class="w-100" />
        <div class="col-12">
            <slot />
        </div>
    </div>
</template>

<script>
import vFormImageDixCell from "@/components/vForm/vFormImageDixCell";
import { vFormControls } from "@/enum";

export default {
    name: "vFormIMAGE",
    components: {
        vFormImageDixCell,
    },
    props: {
        element: { type: Object, required: true },
        stepNo: { type: Number, required: true },
    },
    data() {
        return {
            forceReRenderKey: 0,
            editImage: "",
            vFormControls: vFormControls,
        };
    },
    beforeMount() {
        this.element.formElementType = vFormControls["IMAGE"];
    },
    methods: {
        save() {
            this.$emit("save", this.element);
        },
    },
};
</script>

<style scoped></style>
