<template>
    <!-- <one-column-sidebar-left
      back-button-tab-selector="formbackbutton"
      tab-selector="formtab"
      sub-menu-selector="librarypanel"
    > -->
    <main-table-layout
        :has-top-bar="false"
        tab-selector="formtab"
        type="form"
        back-button-tab-selector="formbackbutton"
        :id="$route.params.id"
    >
        <div slot="mainContent">
            <content-title-block
                v-if="$store.getters.getFormName(id)"
                :value="$store.getters.getFormName(id)"
                :id="$route.params.id"
                store-name="Form"
                :organization-id="$store.getters.getFormOrganization($route.params.id)"
            />
            <content-description-block
                :value="$store.getters.getFormDescription(id)"
                :id="$route.params.id"
                store-name="Form"
            />
            <meta-panel
                :key="item.id"
                v-for="item in this.$store.getters.getFormMetaSets($route.params.id)"
                :title="item.description"
                :fields="item.metaFields"
                icon-left="folder"
                store-name="Form"
                :organization-id="$store.getters.getFormOrganization($route.params.id)"
                @updated="loadForm"
            />
            <div class="darker p-3 mb-4">
                <span class="lighter">{{
                    $t("selectedType", { type: $t("type.project") })
                }}</span
                ><br />
                <p>{{ $t("vFormProjectHint") }}</p>
                <br />
                <div v-if="error" class="form-error">{{ error }}</div>
                <content-display
                    class="d-inline-block mr-2"
                    :list-name="id"
                    type="project"
                    :value="projectId"
                    :show-preview="true"
                    v-if="projectId"
                />
                <span class="mr-1" v-else>-</span>
                <div
                    @click="projectEditing = !projectEditing"
                    class="d-inline-block clickable"
                >
                    <icon
                        :type="projectEditing || (projectId && error) ? 'times' : 'edit'"
                    />
                </div>
                <div
                    v-if="(projectEditing && projectId) || error"
                    class="clickable"
                    @click="removeProject"
                >
                    <icon type="times" />{{ $t("removeProject") }}
                </div>
                <universal-selector
                    v-if="(!projectId && projectEditing) || error"
                    class="mt-2"
                    @addItem="addProject"
                    :exclude-from-item-list="projectId ? [projectId] : []"
                    list-name="formProjectList"
                    store-name="Project"
                    filter-attribute="name"
                    :limit-by-organization-id="
                        $store.getters.getFormOrganization($route.params.id)
                    "
                    tag-icon="folder"
                    :show-organization="true"
                />
            </div>
            <div class="d-flex row">
                <div class="col-6">
                    <info-panel
                        :collapsible="true"
                        :initially-collapsed="true"
                        slot="mainContent"
                        :id="$route.params.id"
                        :tags="$store.getters.getFormTags($route.params.id)"
                        :taggable="true"
                        @removeTag="removeTag"
                        @addTag="addTag"
                        :limit-tags-to-organization="
                            $store.getters.getFormOrganization($route.params.id)
                        "
                        preview-id="formEdit"
                        :organization="
                            $store.getters.getFormOrganization($route.params.id)
                        "
                    >
                        <div slot="info" class="mt-3">
                            <span class="lighter">{{ $t("type.project") }}:</span>
                            <router-link
                                v-if="projectId"
                                target="_blank"
                                :to="'/project/' + projectId + '/general'"
                                >{{
                                    instance && instance.project
                                        ? instance.project.name
                                        : "-"
                                }}</router-link
                            >
                        </div>
                    </info-panel>
                </div>
                <div class="col-6">
                    <team-attacher
                        :initially-collapsed="true"
                        v-if="$store.getters.getFormOrganization($route.params.id)"
                        class=""
                        slot="mainContent"
                        storeName="Asset"
                        :id="$route.params.id"
                        :teams="teams"
                        :limit-by-organization-id="
                            $store.getters.getFormOrganization($route.params.id)
                        "
                        @added="loadAssetTeams"
                        @removed="loadAssetTeams"
                    />
                </div>
            </div>
            <h2>{{ $t("configuration") }}</h2>
            <v-form-editor
                :key="forceReRenderKey"
                class="mt-2"
                :code="$store.getters.getFormRawContent($route.params.id)"
                :project-id="projectId"
                :organization-id="$store.getters.getFormOrganization($route.params.id)"
                :asset-name="$store.getters.getFormName($route.params.id)"
                :target-teams="teams"
                @save="updateValue"
                @error="setProjectLoadError"
            />
            <codemirror
                :value="$store.getters.getFormRawContent($route.params.id)"
                @save="updateValue"
                :do-not-format-on-startup="true"
            />
        </div>
    </main-table-layout>
</template>

<script>
import InfoPanel from "../components/InfoPanel";
//import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
import MainTableLayout from "../layouts/MainTableLayout";
import MetaPanel from "../components/widgets/meta/MetaPanel";
import ContentTitleBlock from "../components/ContentTitleBlock";
import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
import Codemirror from "@/components/widgets/editors/Codemirror";
import TeamAttacher from "@/components/organizations/TeamAttacher";
import vFormEditor from "@/components/vForm/vFormEditor";
import UniversalSelector from "@/components/organizations/UniversalSelector";
import ContentDisplay from "@/components/widgets/editors/ContentDisplay";
import Icon from "@/components/Icon";
import vFormMixin from "@/components/vForm/vFormMixin.js";

export default {
    name: "FormEdit",
    components: {
        MetaPanel,
        InfoPanel,
        //OneColumnSidebarLeft,
        MainTableLayout,
        ContentTitleBlock,
        ContentDescriptionBlock,
        Codemirror,
        TeamAttacher,
        vFormEditor,
        UniversalSelector,
        ContentDisplay,
        Icon,
    },
    mixins: [vFormMixin],
    data() {
        return {
            id: "",
            teams: [],
            instance: {},
            projectId: "",
            projectEditing: false,
            forceReRenderKey: 0,
            error: "",
        };
    },
    watch: {
        projectId() {
            this.forceReRenderKey++;
        },
    },
    beforeMount() {
        this.loadForm();
        this.loadAssetTeams();
        this.id = this.$route.params.id;
        this.loadInstances();
    },
    methods: {
        setProjectLoadError() {
            this.error = this.$t("projectFilesNotAvailable");
        },
        loadAssetTeams() {
            this.$store
                .dispatch("clientLoadAssetTeams", {
                    id: this.$route.params.id,
                })
                .then((data) => {
                    this.teams = data;
                });
        },
        loadForm() {
            this.$store
                .dispatch("loadForm", {
                    id: this.$route.params.id,
                    include: "tags,metaSets",
                })
                .catch((e) => {
                    if (e.status === 401) {
                        this.$router.push("/access-denied");
                    }
                });
        },
        removeTag: function (tag) {
            this.$store.dispatch("removeFormTag", {
                id: this.$route.params.id,
                args: [tag.id],
            });
        },
        addTag: function (tag) {
            this.$store.dispatch("addFormTag", {
                id: this.$route.params.id,
                args: [tag.id],
            });
        },
        updateTags: function (tags) {
            this.$store.dispatch("updateForm", {
                id: this.$route.params.id,
                tags: tags,
            });
        },
        updateValue: function (content) {
            this.$store
                .dispatch("updateForm", {
                    id: this.$route.params.id,
                    content: JSON.stringify(JSON.parse(content)), // this is to minify the json code
                })
                .then(() => {
                    console.log(this.$t("events.formUpdated"));
                    this.$store.dispatch("createNotification", {
                        text: this.$t("events.formUpdated"),
                    });
                });
        },
        loadInstances() {
            this.$store
                .dispatch("clientGetCrossProjectInstances", {
                    filter: "assetId eq " + this.$route.params.id,
                })
                .then((instances) => {
                    if (instances.length) {
                        // vForm is connected to only one single project
                        this.instance = instances[0];
                        this.projectId = instances[0].projectId;
                    }
                });
        },
        removeProject() {
            this.error = null;
            this.removeProjectFromvForm(this.projectId, this.instance.id)
                .then(() => {
                    this.projectId = null;
                    this.instance = null;
                    this.forceReRenderKey++;
                });
        },
        addProject(project) {
            this.error = null;
            return this.addProjectTovForm(project.id, this.$route.params.id)
             .then(instance => {
                    this.instance = instance;
                    this.projectId = this.instance.projectId;
                    this.projectEditing = false;
                    this.forceReRenderKey++;
                    // reload asset teams so they bubble down to the vFormEditor
                    this.loadAssetTeams();
              });

        },
    },
};
</script>
