<template>
  <div class="slideshow">
    <div v-if="vForm" class="slide-inner mt-4">
         <div class="slide-logo">

        </div>
        <div class="version-error p-2" v-if="showVersionMessage">
            This form was created with an old version of the form editor.<br />
            Some functionalities might be broken. Go to vhub.ch to the edit page
            of the form and update it there.
        </div>
        <step
              v-if="slides && slides.length"
              :class="['slide-' + activeSlide]"
              :step="getConfig(getActiveSlideUuid())"
              :lang="lang"
              :is-active="true"
              :project-id="projectId"
              @nextSlide="nextSlide()"
              @prevSlide="prevSlide()"
              @nextStep="nextStep()"
              @prevStep="prevStep()"
              @goToStep="goToStep"
              @goToSlide="goToSlide"
              @goToSlideNo="goToSlideNo"
              @log="log"
        ><div slot="header" class="slide-header clickable mb-2 p-2">
          <div class="float-right">{{ activeSlide }} / {{slides.length}}</div>
          <div @click="goToSlideNo(0)"><icon type="home" /></div>
        </div></step>
        <div v-if="showImages" :key="index + forceReRenderKey" :class="['slide', activeSlide === index ? 'active' : '']" v-for="(slide, index) in slides">
          <img
            :class="['slide-img mb-1']"
            :src="thumbs[index]"
          />
        </div>
    </div>
  </div>
</template>

<script>
import Step from "@/components/vForm/viewer/Step";
import UtilsMixinJs from "@/components/vForm/viewer/UtilsMixin.js";
import Icon from "@/components/Icon";
export default {
  name: "SlideShowDisplay",
  components: {Step,Icon},
  mixins: [UtilsMixinJs],
  props: {
    projectId: {type: String, required: true},
    lang: {type: String, required: true},
    showImages: {type: Boolean, default: true},
  },
  data() {
    return {
      slides: [],
      thumbs: [],
      activeSlide: '',
      forceReRenderKey: 0,
      vForm: null,
      dataSetId: null,
      showVersionMessage: false
    };
  },
  beforeMount() {
    this.loadProjectForm();
    this.loadProjectConfig();
  },
  methods: {
    async loadProjectForm() {
      this.$store.dispatch('clientLoadProjectInstances', {id: this.projectId, filter: 'type eq form', include: 'asset'})
        .then(data => {
          console.log(data);
          if(data && data[0] && data[0].asset) {
            try {
              this.vForm = JSON.parse(data[0].asset.content);
              this.vForm = this.decodeEncode(this.vForm);
              console.log(this.vForm);
              if(this.vForm) {
                this.dataSetId = this.vForm.logID;
                if(!this.vForm.version) {
                  this.showVersionMessage = true;
                }
              }
            } catch (e) {
              console.log(e);
              console.log('invalid vform json')
            }
          }
        })

    },
     /**
    * Decodes or encodes the config
    * (for saving it must be encoded)
    * */
    decodeEncode(config, decode = true) {
       if(config.steps) {
          config.steps = config.steps.map(item => {
            if(item.elements) {
              item.elements = item.elements.map(el => {
                  const keys = Object.keys(el.label.dix);
                  keys.map(key => {
                    el.label.dix[key] = decode ? decodeURIComponent(el.label.dix[key].replace(/\+/g, ' ')) : encodeURIComponent(el.label.dix[key]);
                  })
                  return el;
              })
            }
            return item;
          })
          return config;
        }
    },

    /***
    * Loads the thumbnail for each slide
    * and triggers the setUpConfig
    * */
    async loadThumbnails(offset, limit) {
      if(!this.showImages) {
        return;
      }
      for(let i = offset; i < (offset + limit); i++) {
        const item = this.slides[i];
        if(!item) {
          break;
        }
        const index = item.id;
        const num = index + 1;
        console.log(index);
        if(!this.thumbs[index]) {
            this.fillUpArray(this.thumbs, index);
            await this.$store.dispatch('clientDownloadProjectZipPart', {
              id: this.$route.params.id,
              key: 'presentation.zip',
              fileName: '_slides/' + ('000000000' + num).substr(-3) + '/thumbnail.png'
            })
            .then(async file => {
              const buff = await new Response(file.text).arrayBuffer();
              const imgBase64 = new Buffer(buff, 'binary').toString('base64');
              this.thumbs.splice(index, 0, 'data:' + file.headers['content-type'] + ';base64,' + imgBase64);
              console.log(this.thumbs);
            }).catch(e => {
              console.log(e);
              console.log('thumbnail not found for slide: ' + num)
            })
        }
      }
    },
    fillUpArray(array, index) {
       for(let i = 0; i < index; i++) {
        if(!array[i]) {
          array.splice(index, 0, null);
        }
       }
       return array;
    },
    /**
    * Loads the vStage presentation config file from vHUB
    * and reads its slide information
    * if slides are found, it also loads the thumbnail for each slide
    * */
    async loadProjectConfig() {
      if(this.$route.params.id) {
        this.$store.dispatch('clientDownloadProjectZipPart', {
            id: this.$route.params.id,
            key: 'presentation.zip',
            fileName: 'config.json'
          })
          .then(async file => {
            const config = file.text;
            if(config) {
              this.slides = JSON.parse(config).slides;

              // add a slide named "none" for steps which are not associated with a slide yet
              //this.slides.unshift({id: -1, uuid: 'none'});
              this.activeSlide = 0;
            }
            if(this.slides) {
              await this.loadThumbnails(0, 2);
              if(this.vForm) {
                  this.getSlideList(this.vForm, this.slides);
              }
            }
          })
          .catch(e => {
            console.log(e);
            this.$emit('error');
          })
      } else {
        this.slides = [{uuid: 'none'}];
        //this.setUpConfig();
      }
    },
    getActiveSlideUuid() {
      return this.slides[this.activeSlide].uuid;
    },
    goToSlide(uuid) {
      const index = this.slides.findIndex(item => {return item.uuid === uuid});
      if(index !== -1) {
        this.activeSlide = index;
        this.loadThumbnails(index, 1);
      }
    },
    goToSlideNo(no) {
      console.log("goToSlideNo");
      console.log(no);
      no = parseInt(no);
      if(no >= 0 && no < this.slides.length) {
        this.activeSlide = no;
        this.loadThumbnails(no, 2);
      } else {
        console.log('slide no is invalid')
      }
    },
    nextSlide() {
      const index = this.activeSlide;
      if(index+1 < this.slides.length) {
        this.activeSlide = this.activeSlide + 1;
      } else {
        this.activeSlide = 0;
      }
      this.loadThumbnails(this.activeSlide, 1);
    },
    prevSlide() {
      const index = this.activeSlide;
      //this.loadThumbnails(index - 1 >= 0 ? index-1 : 0, 1);
      if(index-1 >= 0) {
        this.activeSlide = this.activeSlide - 1;
      }
    },
    goToStep(uuid) {
      const conf = this.getStepConfig(uuid);
      if(conf.linkedSlides && conf.linkedSlides.length) {
        this.goToSlide(conf.linkedSlides[0]);
      } else {
        console.log("no linked slide for this step, cannot go!")
      }
    },
    nextStep() {
      const index = this.activeSlide;
      let targetUuid = null;
      for(let i = index + 1; i < this.slides.length; i++) {
        console.log('searching for next step slide index: ' + i);
        if(this.hasSteps(this.getConfig(this.slides[i].uuid))) {
          targetUuid = this.slides[i].uuid;
          break;
        }
      }
      this.goToSlide(targetUuid);
    },
    prevStep() {
      const index = this.activeSlide;
      let targetUuid = null;
      for(let i = index - 1; i >= 0; i--) {
        if(this.hasSteps(this.getConfig(this.slides[i].uuid))) {
          targetUuid = this.slides[i].uuid;
          break;
        }
      }
      this.goToSlide(targetUuid);
    },
    hasSteps(subconfig) {
      return !!subconfig.elements;
    },
    getStepConfig(uuid) {
      const res = this.vForm.steps.filter(item => {return item.uuid === uuid});
      if(res && res.length) {
       return res[0];
      }
      return {};
    },
    getConfig(uuid) {
      const res = this.vForm.steps.filter(item => {return item.linkedSlide === uuid || (item.linkedSlides && item.linkedSlides.includes(uuid))});
      if(res && res.length) {
       return res[0];
      }
      return {};
    },
    log(data) {
      if(this.dataSetId) {
        this.$store.dispatch('clientCreateDatasetRow', data);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .slideshow {
    width: 100%;
    height: 100vh;
   // background-color: #ddd;
   background-color: transparent;
    position: relative;
    overflow: hidden;
  }
  .slide-inner {
    width: 90%;
    position:absolute;
    left:0;
    height: 100%;
  }
  .slide {
    width: 100vw;
    height: 90vh;
    display: flex;
    position:absolute;
    left:0;
    top:0;
    z-index:-1;
    opacity: 0;
    pointer-events:none;
    .form {
      //width: 29%;
      //margin-left: 1%;
      position:relative;
    }
    &.active {
      opacity: 1;
      z-index:10;
    }
    img {
      width: 65%;
    }
  }
  .slide-header {
    width: 100%;
    background-color: #585b4e;
    z-index:15;
  }
  .version-error {
    width: 100%;
    background-color: #f87606;
    color: #fff;
    z-index:55;
    position:absolute;
    top:0;
    left:0;
  }
</style>