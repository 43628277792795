<template>
    <main-table-layout
        sub-menu-selector="adminpanel"
    >
        <div slot="buttonsRight"/>

      <content-filter slot="filter"
                      @filter="filtering"
                      :id="listName"
                      store-name="Organization"
                      :tag-filtering="false"
                      :org-filtering="false"
                      text-filtering-field-name="displayName"
      />
      <view-switcher
          slot="mainContentButtons"
          :id="'mainContent'"
          :table="true"
          :thumbnails="true"
      />

        <pagination
                slot="mainContentButtons"
                :limit="paginationLimit"
                :total="$store.getters.getOrganizationListOptions(listName, 'pagination_items')"
                :id="'organizationtablepage'"
                @setActive="loadPaginatedData"
                ref="pagination"
        />
        <Button
            permission="createOrganization"
            type="action-button" slot="mainContentButtons" link="/admin/organizations/add" icon="plus">
            {{ $t('createNewOrganization') }}
        </Button>
<Button
           slot="mainContent"
           @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
           type="reload"
           icon="redo"
           class="icon-button"
       />

            <table-widget
                    v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'table'"
                    slot="mainContent"
                    @sort="sort"
                    :id="'organizationsDefaultTable'"
                    :nav-by-clicking-row="true"
                    :deletable="false"
                    @deleteRow="deleteRow"
                    :show-tags="true"
                    :fields="tableFields"
                    :data="$store.getters.getOrganizationsByVisibility([0, 1], listName)"
                    :title-as-links="true"
                    :key="forceReRenderKey"
            />
            <thumbnails
                    v-if="$store.getters.getViewSwitcher($route.name, 'mainContent') === 'thumbnails'"
                    slot="mainContent"
                    @sort="sort"
                    :id="'organizationsDefaultThumbnails'"
                    :deletable="false"
                    @deleteRow="deleteRow"
                    :nav-by-clicking-row="true"
                    :fields="thumbnailFields"
                    :data="$store.getters.getVisibleOrganizationsByListName(listName)"
                    :title-as-links="true"
                    :key="forceReRenderKey"
            />
    </main-table-layout>
</template>

<script>
  import Button from "../components/forms/Button";
  import TableWidget from "../components/widgets/TableWidget";
  import MainTableLayout from "../layouts/MainTableLayout";
  import ViewSwitcher from "../components/widgets/ViewSwitcher";
  import Thumbnails from "../components/widgets/ThumbnailView";
  import Pagination from "../components/Pagination";
  import { mapState } from 'vuex';
  import ContentFilter from "@/components/widgets/filter/ContentFilter";
    export default {
        name: "Organizations",
        components: {
          Button,
          TableWidget,
          MainTableLayout,
          ViewSwitcher,
          Thumbnails,
          Pagination,
          ContentFilter,
        },
        data() {
            return {
                listName: 'orgOverview',
                paginationLimit: 15,
                viewId: 'mainContent',
                forceReRenderKey: 0,
                queryRelevantComponents: 2, //pagination and filter
                queryRelevantInits: 0,
                tableFields: [
                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'visibility',
                        id: 'visibility',
                        type: 'orgvisibility',
                        sortable: true
                    },
                    {
                        name: 'DisplayName',
                        id: 'displayName',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Last Update',
                        id: 'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
                thumbnailFields: [
                    {
                        name: '',
                        id:'',
                        type: 'image',
                    },
                    {
                        name: 'Last Update',
                        id: 'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'DisplayName',
                        id: 'displayName',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Tags',
                        id:'tags',
                        type: 'tags',
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
            };
        },
        computed: mapState({
            pages: state => state.page.pages,
        }),
        watch: {
            queryRelevantInits: function(newval) {
                if(newval === this.queryRelevantComponents) {
                    this.loadData( false);
                }
            }
        },
        methods: {
          loadPaginatedData(offset, initial) {
              this.offset = offset;
              this.loadData(initial);
            },
            loadData(initial = false) {
              this.loading = true;
              let args = {
                  listName: this.listName,
                 }
                  const paginationAddOn = {
                    paging: {
                      [this.listName]: true,
                    },
                    offset: {
                      [this.listName]: this.offset,
                    },
                    limit: {
                      [this.listName]: this.paginationLimit,
                    },
                    include: {
                      [this.listName]: 'tags',
                    },
                    sort: {
                      [this.listName]: this.sorting,
                    },
                  };
                  args.add = this.filter && this.filter.add ? {...paginationAddOn, ...this.filter.add } : paginationAddOn;
                  if(this.filter && this.filter.remove) {
                    args.remove = this.filter.remove;
                  }

                if(initial) {
                    this.queryRelevantInits++;
                    this.$store.dispatch('registerOrganizationsQueryParams', {listName: this.listName, params: args});
                }
                else {
                  this.$store.dispatch('loadOrganizations', args).then(() => {
                    this.loading = false;
                  });
                }
            },
            deleteRow: function(id) {
                this.$store.dispatch('deleteOrganization', {
                    args: [id]
                });
            },
            filtering: function(args, initial) {
              this.filter = args;
              this.loadData(initial);
            },
            sort(args) {
              const {fieldname, direction} = args;
              const sortString = direction + fieldname;
              if(this.sorting !== sortString) {
                this.sorting = sortString;
              }
              this.loadData(false);
            }
        }
    }
</script>