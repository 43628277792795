<template>
    <div class="p-2">
        <div class="col row mt-2 dflex">
            <div class="col-12 dflex checkbox-wrapper">
                <input
                    :id="'chkEmphasized' + $vnode.key"
                    type="checkbox"
                    v-model="element.emphasized"
                />
                <label :for="'chkEmphasized' + $vnode.key">{{ $t("emphasized") }}</label>
            </div>
        </div>
        <div
            v-if="element.currentLang === key"
            class="col-12 dflex"
            :key="index2 + 1000000000 + forceReRenderKey"
            v-for="(key, index2) in Object.keys(element.label.dix)"
        >
            <div class="fit-content">
                <v-form-image-dix-cell
                    :element="element"
                    :image-dix="element.label.imageDix"
                />
            </div>
            <div class="col p-0">
                <input
                    :placeholder="(key === 'Unknown' ? '' : key) + ' ' + $t('Headline')"
                    class="mb-1 form-text v-form-label form-text-dark ml-1"
                    type="text"
                    v-model="element.label.dix[key]"
                />
            </div>
        </div>
    </div>
</template>

<script>
import vFormImageDixCell from "@/components/vForm/vFormImageDixCell";
import { vFormControls } from "@/enum";

export default {
    name: "vFormHEADLINE",
    components: {
        vFormImageDixCell,
    },
    props: {
        element: { type: Object, required: true },
        stepNo: { type: Number, required: true },
    },
    data() {
        return {
            forceReRenderKey: 0,
            editImage: "",
            vFormControls: vFormControls,
        };
    },
    beforeMount() {
        this.element.formElementType = vFormControls["HEADLINE"];
    },
    methods: {
        langVisible(element, key) {
            return element.currentLang === key;
        },
        save() {
            this.$emit("save", this.element);
        },
    },
};
</script>

<style scoped></style>
