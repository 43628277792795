<template>
  <div id="v-form-outer" class="v-form-index fancy-scrollbar">
    <div class="v-form-inner" :style="{width: ((slides.length + 1) * 150) + 'px' }">

    <div
      @click="setActive(slide.uuid)"
      :href="slide.uuid ? '#' + slide.uuid : '#none'"
      :data-id="slide.uuid ? slide.uuid : 'none'"
      :class="['slide clickable', activeSlide === slide.uuid ? 'active' : '', slide.hasSteps ? '' : 'no-steps']"
      :key="index + forceReRenderKey"
      v-for="(slide, index) in slides">
       Slide {{ slide.id !== -1 ? slide.id : 'none' }}
       <div @click="$emit('addStep', slide)" class="add-step-box text-center" v-if="!slide.hasSteps || slide.uuid === 'none'">
          <icon type="puzzle-piece" /><br />{{ $t('createNewType', {type: 'step'}) }}
        </div>
       <img
          class="slide-img-faker mb-1"
          v-if="slide.id === -1" />
       <img
        v-if="thumbs[slide.uuid]"
        :key="index + forceReRenderKey"
        class="slide-img mb-1"
        :src="thumbs[slide.uuid]"
        />
    </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Icon from "@/components/Icon";
export default {
  name: "vFormIndex",
  components: {
    Icon
  },
  props: {
    config: {type: Object, required: true},
    thumbs: {type: Object, default: null},
    slides: {type: Array, default: null},
    initialActiveSlide: {type: String, default: null}
  },
  data() {
    return {
      forceReRenderKey: 0,
      activeSlide: null,
      tmpScrollLeft: 0
    };
  },
  watch: {
    thumbs: {
      deep: true,
      handler() {
        this.setUpScrollSpy();
      }
    },
    slides: {
      deep: true,
      handler() {
        this.forceReRenderKey++;
      }
    },
  },
  beforeMount() {
    this.activeSlide = this.initialActiveSlide ? this.initialActiveSlide : this.slides[0].uuid;
  },
  mounted() {
    this.setUpScrollSpy();
  },
  methods: {
    setActive(uuid, scrollTo = true) {
      this.activeSlide = uuid;
      this.$emit('selected', uuid);
      if(scrollTo) {
         $('html, body').stop().animate({
          scrollTop: $("#" + uuid).offset().top
      }, 1000);
       }
    },
    // eslint-disable-next-line no-unused-vars
    scrollToThumbnail(pos) {
       if(!this.tmpScrollLeft) {
          this.tmpScrollLeft = $('#v-form-outer').offset().left
       }

    $('#v-form-outer').stop().animate({
      scrollLeft: pos - $('#v-form-outer').width()/2,
      easing: 'linear'
     }, 50);
    },
    setUpScrollSpy() {
      const $this = this;
      const sectionIds = $('.v-form-index .slide');
      $(document).on('scroll', function(){
          sectionIds.each(function(){
              const container = $(this).attr('href');
              if(container && $(container).length) {
                const containerOffset = $(container).offset().top;
                const containerHeight = $(container).outerHeight();
                const containerBottom = containerOffset + containerHeight;
                const scrollPosition = $(document).scrollTop();

                if(scrollPosition < containerBottom - 20 && scrollPosition >= containerOffset - 100){
                   $this.setActive($(this).data('id'), false);
                   // still a bug: it switches rapidly between 0 and the actual position left
                   $this.scrollToThumbnail($(this).position().left);
                }
              } else {
                //console.log('container not found: ' + container);
              }
          });
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-form-index {
    position:fixed;
    right:15px;
    bottom: 0;
    z-index:55;
    height: 175px;
    overflow-x: scroll;
    width: 80vw;
    background-color: #222;
    padding:15px;
    .slide {
      width: 150px;
      display:inline-block;
      margin-right: 5px;
      position:relative;
      .add-step-box {
        opacity: 0;
      }
      &:hover, &.active {
        .slide-img, .slide-img-faker {
          border: 3px solid $highlight;
        }
      }
      &:hover {
        .slide-mg-faker {
          background-color: $highlight;
        }
        .add-step-box {
          opacity: 1;
        }
      }
      &.no-steps, &.no-steps:hover {
        .slide-img, .slide-img-faker {
         border: 3px solid #222;
        }
        opacity: 0.3;
      }
      &.no-steps:hover {
        opacity: 1;
      }

    }
  }
  .v-form-inner {
    position:relative;
    left: 0;
  }

  .slide-img {
    background-color: #222;
    width: 100%;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    border: 3px solid #222;
  }
  .slide-img-faker {
    background-color: #222;
    width: 100%;
    min-height:98px;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .add-step-box {
    position:absolute;
    top: 30%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 130px;
    background-color: $highlight;
    border-radius: .4em;
    padding:15px;
  }

  .add-step-box:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: $highlight;
    border-bottom: 0;
    margin-left: -10px;
    margin-bottom: -10px;
  }
</style>