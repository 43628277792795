<template>
  <div class="form">
    <div :class="['form pl-2', 'col-4 offset-8']">
      <div :class="['wrong-sign', validationIncorrect ? 'show' : '']"></div>
      <slot name="header" />
      {{step.elements ? step.elements.map(item => {return getComponentName(item.formElementTypeString)}) : 'hallo'}}
      <div :key="index" v-for="(item, index) in step.elements">
        <component
          :class="[getComponentName(item.formElementTypeString), 'mb-2']"
          :is="getComponentName(item.formElementTypeString)"
          :config="item"
          :lang="lang"
          :is-active="isActive"
          @goToSlide="goToSlide"
          @goToSlideNo="goToSlideNo"
          @goToStep="goToStep"
          :ref="'element' + index"
          :project-id="projectId"
        />
      </div>
      <div v-if="!step || !step.elements" class="button-container-upper d-flex justify-content-start">
        <div class="prev-button mr-1" @click="$emit('prevSlide')"><icon type="arrow-left-long" /><span class="text">prev slide</span></div>
        <div :class="['next-button', success ? 'success' : '']" @click="next"><icon type="arrow-right-long" /><span class="text">next slide</span></div>
        <canvas class="confetti" ref="confetti"></canvas>
      </div>
      <div class="button-container d-flex justify-content-start">
        <div class="prev-button mr-1" @click="$emit('prevStep')"><icon type="backward-step" /><span class="text">prev step</span></div>
        <div :class="['next-button', success ? 'success' : '']" @click="next(true)"><icon type="forward-step" /><span class="text">next step</span></div>
        <canvas class="confetti" ref="confetti"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import { vFormControls } from '@/enum';
import TEXTDISPLAY from "@/components/vForm/viewer/TEXTDISPLAY";
import CHECKBOXES from "@/components/vForm/viewer/CHECKBOXES";
import RADIOBUTTON from "@/components/vForm/viewer/RADIOBUTTON";
import QR_CODE from "@/components/vForm/viewer/QR_CODE";
import SLIDECONTROL from "@/components/vForm/viewer/SLIDECONTROL";
import STEP_CONTROL from "@/components/vForm/viewer/STEP_CONTROL";
import SHORT_ANSWER from "@/components/vForm/viewer/SHORT_ANSWER";
import SEPARATOR from "@/components/vForm/viewer/SEPARATOR";
import MULTIPLE_CHOICE from "@/components/vForm/viewer/MULTIPLE_CHOICE";
import HEADLINE from "@/components/vForm/viewer/HEADLINE";
import WEBSITE from "@/components/vForm/viewer/WEBSITE";
import IMAGEDISPLAY from "@/components/vForm/viewer/IMAGEDISPLAY";
import PROJECT_SWITCH from "@/components/vForm/viewer/PROJECT_SWITCH";
import META_LIST_FILTER from "@/components/vForm/viewer/META_LIST_FILTER";
import Icon from "@/components/Icon";
import confetti from "canvas-confetti";

export default {
  name: "Step",
  components: {
    TEXTDISPLAY,
    CHECKBOXES,
    RADIOBUTTON,
    QR_CODE,
    SLIDECONTROL,
    SHORT_ANSWER,
    SEPARATOR,
    MULTIPLE_CHOICE,
    HEADLINE,
    STEP_CONTROL,
    PROJECT_SWITCH,
    META_LIST_FILTER,
    IMAGEDISPLAY,
    WEBSITE,

    Icon
  },
  props: {
    step: {type: Object, default: () => {return {}}},
    lang: {type: String, required: true},
    isActive: {type: Boolean, default: false},
    projectId: {type: String, required: true},
  },
  data() {
    return {
      vFormControls: vFormControls,
      validationIncorrect: false,
      missingRequired: false,
      success: false,
      myConfetti: null
    };
  },
  beforeMount() {
    this.myConfetti = confetti.create(this.$refs.confetti, {
        resize: true,
        useWorker: true
      });
  },
  methods: {
    getComponentName(formElementTypeString) {
      let componentName = '';
      Object.entries(this.vFormControls).map((item) => {
        if(item[1] === formElementTypeString) {
          componentName = item[0];
        }
      });
      // TEXT and IMAGE are reserved vuejs words, cannot use components with this name
      if(componentName === "TEXT") {
        componentName = "TEXTDISPLAY";
      }
      else if(componentName === "IMAGE") {
        componentName = "IMAGEDISPLAY";
      }
      return componentName;
    },
    /**
    * Default behaviour is nextSlide – set nextStep to true to go to next step only
    * */
    next(nextStep = false) {
      this.validationIncorrect = false;
      const $this = this;
      setTimeout(() => {
        $this.doNext(nextStep);
      }, 300);
    },
    doNext(nextStep = false) {
      this.missingRequired = false;
      const showSuccess = this.validationIncorrect;
      if(this.step && this.step.elements) {
          for(let i = 0; i < this.step.elements.length; i++) {
            const component = this.$refs['element'+i] ? this.$refs['element'+i][0] : null;
            if(component) {
              console.log(component);

              if(component.getLogEntries) {
                const logEntries = component.getLogEntries();
                if(logEntries.length) {
                  for(let j = 0; j < logEntries.length; j++) {
                    this.$emit("log", logEntries[j]);
                  }
                }
              }
              // todo: in case of incorrect we will need to make the log entries for faulty answer
              if(component.validate) {
                const isValid = component.validate();
                if(!isValid) {
                  this.validationIncorrect = true;
                  break;
                }
              }

              if(component.checkRequired) {
                const hasRequired = component.checkRequired();
                if(!hasRequired) {
                  this.missingRequired = true;
                }
              }
            }
        }
      }
      console.log('Validation incorrect: ' + this.validationIncorrect);
      if(!this.validationIncorrect && !this.missingRequired) {
        if(showSuccess) {
          this.success = true;
          this.doConfetti();
        }
        const $this = this;
        setTimeout(() => {
        if(nextStep) {
           $this.$emit('nextStep');
        } else {
           $this.$emit('nextSlide');
        }
        }, 300);
      }
    },
    doConfetti() {
      console.log('confettiiiii')
      this.myConfetti({
        particleCount: 100,
        spread: 110
        // any other options from the global
        // confetti function
      });
    },
    goToSlide(uuid) {
      if(!this.validationIncorrect) {
        this.$emit('goToSlide', uuid);
      }
    },
    goToSlideNo(no) {
      if(!this.validationIncorrect) {
        this.$emit('goToSlideNo', no);
      }
    },
    goToStep(stepUuid) {
      if(!this.validationIncorrect) {
        this.$emit('goToStep', stepUuid);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .form {
    width: 100vw;
    height: 90vh;
    background-color: #ddd;
  }
  .button-container {
    position: absolute;
    bottom: 0;
    padding-bottom:25px;
    width: 100%;
  }
  .button-container-upper {
    position: absolute;
    bottom: 40px;
    padding-bottom:25px;
    width: 100%;
  }
  .next-button, .prev-button {
    background-color: $highlight-color;
    max-width: 45%;
    cursor:pointer;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    color: $on-dark-color;
    padding: 6px 10px;
    .icon {
      transition: all 300ms ease;
      margin-bottom: -3px;
    }
    .text {
      font-size: 1.1em;
    }
    &:hover {
      background-color: lighten($highlight-color, 10%);
    }
  }
  .next-button {
    padding-right: 35px;
    position:relative;
    .icon {
      position:absolute;
      right: 10px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    &:hover {
      .icon {
        right: 6px;
      }
    }
  }
  .prev-button {
    padding-left: 35px;
    position:relative;
    .icon {
      position:absolute;
      left: 10px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    &:hover {
      .icon {
        left: 6px;
      }
    }
  }
  .wrong-sign {
    position:absolute;
    background-color: #ff0008;
    left: 0;
    width: 0;
    height:100%;
    z-index:5;
    -webkit-transition: all 100ms ease;
    transition: all 100ms ease;
    transition-timing-function: cubic-bezier(.17,.67,.83,.67);
    &.show {
      width: 2vw;
      left:-2vw;
    }
    &.correct {
      background-color: #518000;
    }
  }
  @keyframes slideIn {
    from {
      left: 0;
      width: 0;
    }
    to {
      width: 2vw;
      left:-3vw;
    }
  }
  .confetti {
    width: 10vh;
    height:10vh;
    position:absolute;
    bottom: 0;
    z-index:0;
    pointer-events:none;
  }
</style>