<template>
    <div class="p-2 mb-3">
        <div class="col-12 dflex checkbox-wrapper">
            <input
                :id="'chkRequired' + $vnode.key"
                type="checkbox"
                v-model="element.isRequired"
            />
            <label :for="'chkRequired' + $vnode.key">{{ $t("isRequired") }}</label>
        </div>
        <div class="col-12 dflex checkbox-wrapper">
            <input
                :id="'chkCorrect' + $vnode.key"
                type="checkbox"
                v-model="element.validateCorrectness"
            />
            <label :for="'chkCorrect' + $vnode.key">{{
                $t("validateCorrectness")
            }}</label>
        </div>
        <div class="col-12 dflex checkbox-wrapper">
            <input :id="'chkid' + $vnode.key" type="checkbox" v-model="element.isID" />
            <label :for="'chkid' + $vnode.key"
                >{{ $t("isUserIdentifier")
                }}<info-helper class="ml-1" text="isUserIdentifierHint"
            /></label>
        </div>

        <!--DIX-->
        <!--FOR EACH TRANSLATION ONE FIELD-->
        <div
            v-if="element.currentLang === key"
            class="col-12 dflex"
            :key="index2 + 1000000000 + forceReRenderKey"
            v-for="(key, index2) in Object.keys(element.label.dix)"
        >
            <div class="fit-content">
                <v-form-image-dix-cell
                    :element="element"
                    :image-dix="element.label.imageDix"
                />
            </div>
            <div class="col p-0">
                <textarea
                    :placeholder="(key === 'Unknown' ? '' : key) + ' ' + $t('Question')"
                    rows="300"
                    class="form-text v-form-label form-text-dark ml-1"
                    type="text"
                    v-model="element.label.dix[key]"
                />

                <!--ANSWERS-->
                <div
                    class="dflex mt-1 row pl-1"
                    :key="index4 + 98239283"
                    v-for="(item, index4) in element.options"
                >
                    <div class="col-12 col-xxl-6 dflex">
                        <div class="col-auto pl-0 pr-0 fit-content">
                            <v-form-image-dix-cell
                                :element="element"
                                :image-dix="item.text.imageDix"
                            />
                        </div>
                        <div class="col pl-1 pr-0">
                            <textarea
                                :placeholder="
                                    (key === 'Unknown' ? '' : key) +
                                    ' ' +
                                    $t('answer') +
                                    ' ' +
                                    (index4 + 1)
                                "
                                rows="300"
                                class="form-text v-form-label form-text-dark"
                                type="text"
                                v-model="item.text.dix[key]"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-xxl-6 dflex mt-1">
                        <div class="col-10 pl-1 pr-0">
                            <div
                                v-if="index2 === 0"
                                class="col-12 dflex checkbox-wrapper row"
                            >
                                <input
                                    :id="'chkCorrect' + $vnode.key + index4"
                                    type="checkbox"
                                    v-model="item.isCorrectAnswer"
                                />
                                <label :for="'chkCorrect' + $vnode.key + index4">{{
                                    $t("isCorrectAnswer")
                                }}</label>
                            </div>
                        </div>
                        <div class="col-2 pl-0 pr-0 text-right">
                            <Button class="icon-button" @click="removeOption(index4)">
                                <icon type="trash-alt" />
                            </Button>
                        </div>
                    </div>
                    <div
                        class="w-100 mt-1 text-right col"
                        v-if="index4 === element.options.length - 1"
                    >
                        <Button class="icon-button" @click="addOption(key)">
                            <icon type="plus" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from "@/components/Icon";
import Button from "@/components/forms/Button";
import { vFormControls } from "@/enum";
import InfoHelper from "@/components/InfoHelper";
import vFormImageDixCell from "@/components/vForm/vFormImageDixCell";

export default {
    name: "vFormTEXT",
    components: {
        Icon,
        Button,
        InfoHelper,
        vFormImageDixCell,
    },
    props: {
        element: { type: Object, required: true },
        stepNo: { type: Number, required: true },
    },
    data() {
        return {
            forceReRenderKey: 0,
            editImage: "",
            vFormControls: vFormControls,
        };
    },
    watch: {
        element: {
            deep: true,
            handler() {
                if (this.element.validateCorrectness && !this.element.options.length) {
                    this.addOption("Unknown");
                }
            },
        },
    },
    beforeMount() {
        if (!this.element.options) {
            this.element.options = [];
        }
        this.element.formElementType = vFormControls["SHORT_ANSWER"];
    },
    methods: {
        removeOption(index) {
            this.element.options.splice(index, 1);
        },
        addOption(lang) {
            if (!this.element.options) {
                this.element.options = [];
            }
            this.element.options.push({
                isCorrectAnswer: false,
                isChecked: false,
                text: {
                    dix: {
                        [lang]: "",
                    },
                    imageDix: {
                        [lang]: "",
                    },
                },
            });
        },
        addTranslation(lang) {
            this.element.label.dix[lang] = {
                isRequired: false,
                emphasized: false,
                showastiles: false,
            };
            this.addOption(lang);

            this.forceReRenderKey++;
        },
    },
};
</script>
