import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/apps/ccLight/Home";
import vFormViewer from "@/apps/ccLight/vFormViewer"
Vue.use(VueRouter);

const router = new VueRouter({
    routes: getRoutes(),
    // Doesn't fit with vhub Apps:
    // mode: 'history',
})
export default router;

function getRoutes() {
    return [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                title: 'vHub CC | ' + 'Home',
                icon: 'poll-h',
                pagetitle: 'forms',
            },
        },
        {
            path: '/:id/vform',
            name: 'form_view',
            props: true,
            component: vFormViewer,
            meta: {
                title: 'vHub CC | ' + 'Forms',
                icon: 'poll-h',
            },
        },
    ];
}

// This callback adds editors-infos, page-titles etc. for the specific page
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {

    //item.editors.roles.filter(role => {return role === $store.getters.getUserRole})
    //if(to.meta.accessPermission && !to.meta.staticPermission) {
        //next('/access-denied');
    //}

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with editors tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    // eslint-disable-next-line no-unused-vars
    //const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.editors && r.editors.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale editors tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering editors tags if there are none.

    if (!nearestWithMeta) return next();

    // Turn the editors tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which editors tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the editors tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});