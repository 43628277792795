<template>
    <div class="v-form-container">
        <div v-if="config.steps && !config.version" class="form-error">You have opened an older vFrom file, which was automatically converted to the latest vFrom version.
         Before saving, please check that all elements are correct and no information was lost.</div>
        <popup
            @close="
                () => {
                    showDeletePrompt = false;
                }
            "
            v-if="showDeletePrompt"
        >
            <delete-prompt
                slot="popupMainContent"
                @abort="
                    () => {
                        showDeletePrompt = false;
                        resetDelete();
                    }
                "
                @confirm="
                    () => {
                        showDeletePrompt = false;
                        if (toBeDeletedType === 'element') {
                            deleteElement();
                        } else {
                            deleteStep();
                        }
                    }
                "
            />
        </popup>
        <div class="row">
            <div class="cell col-2 col-xxl-2 pl-2 pr-1">
                <div style="height: 1038px" class="dark-ui p-1">
                    <h3>1. STEPS</h3>
                    <v-form-index-steps
                        :slides="slides"
                        :thumbs="thumbs"
                        :config="config"
                        :key="forceReRenderKey + 23498723"
                        :initial-active-step="activeStep"
                        @addStep="addStep"
                        @deleteStep="deleteStep"
                        @selected="
                            (uuid) => {
                                activeStep = uuid;
                            }
                        "
                    />
                    <div class="mt-5" style="display: block; min-height: 40px">
                        <Button style="float: right" class="" @click="addStep">{{
                            $t("addStep")
                        }}</Button>
                    </div>
                </div>
            </div>
            <div class="cell col-2 pl-1 pr-1">
                <div style="" class="dark-ui p-2">
                    <h3>2. BUILDING BLOCKS</h3>
                    <div
                        style="height: 1038px; overflow-y: scroll; overflow-x: hidden"
                        class="fancy-scrollbar pr-1"
                    >
                        <info-panel
                            :collapsible="true"
                            class=""
                            info-panel-header="Text"
                            :initially-collapsed="false"
                            preview-id="textElements"
                        >
                            <div class="block-container" slot="info">
                                <div
                                    class="form-block col-xxl-6 col-md-12"
                                    @click="addBlockToStep(vFormControls.HEADLINE)"
                                >
                                    <h2>HEADLINE</h2>
                                    <h3 class="mt-2 mb-0">HELLO WORLD</h3>
                                </div>
                                <div
                                    class="form-block col-xxl-6 col-md-12"
                                    @click="addBlockToStep(vFormControls.WEBSITE)"
                                >
                                    <h2>WEBSITE</h2>
                                    <a
                                        target="_blank"
                                        href="https://sideeffects.ch"
                                        class="mt-2 mb-0"
                                        >http://sfx.ch</a
                                    >
                                </div>
                                <div
                                    class="form-block col-xxl-6 col-md-12"
                                    @click="addBlockToStep(vFormControls.CHECKBOXES)"
                                >
                                    <h2>CHECKBOXES</h2>
                                    <div class="checkbox-item mt-2">
                                        <div class="checkbox-icon"></div>
                                        <label class="ml-1">Option 1</label>
                                    </div>
                                    <div class="checkbox-item">
                                        <div class="checkbox-icon"></div>
                                        <label class="ml-1">Option 2</label>
                                    </div>
                                    <div class="checkbox-item">
                                        <div class="checkbox-icon"></div>
                                        <label class="ml-1">Option 3</label>
                                    </div>
                                </div>
                                <div
                                    class="form-block col-xxl-6 col-md-12"
                                    @click="addBlockToStep(vFormControls.TEXT)"
                                >
                                    <h2>TEXT FIELD</h2>
                                    <input
                                        class="form-text-dark"
                                        disabled
                                        type="text"
                                        value="Lorem Ipsum"
                                    />
                                </div>

                                <div
                                    class="form-block col-xxl-6 col-md-12"
                                    @click="addBlockToStep(vFormControls.MULTIPLE_CHOICE)"
                                >
                                    <h2>MULTI CHOICE</h2>
                                    <div class="checkbox-item mt-2">
                                        <div class="checkbox-icon"></div>
                                        <label class="ml-1">Option 1</label>
                                    </div>
                                    <div class="checkbox-item">
                                        <div class="checkbox-icon"></div>
                                        <label class="ml-1">Option 2</label>
                                    </div>
                                    <div class="checkbox-item">
                                        <div class="checkbox-icon"></div>
                                        <label class="ml-1">Option 3</label>
                                    </div>
                                </div>
                                <div
                                    class="form-block col-xxl-6 col-md-12"
                                    @click="addBlockToStep(vFormControls.SHORT_ANSWER)"
                                >
                                    <h2>SHORT ANSWER</h2>
                                    <textarea
                                        rows="3"
                                        cols=""
                                        class="form-text-dark"
                                        disabled
                                    >
Lorem ipsum dolor sit amet, consectetur</textarea
                                    >
                                </div>
                            </div>
                        </info-panel>

                        <info-panel
                            :collapsible="true"
                            class=""
                            info-panel-header="Media"
                            :initially-collapsed="false"
                            preview-id="mediaElements"
                        >
                            <div class="block-container" slot="info">
                                <div
                                    class="form-block col-xxl-6 col-md-12"
                                    @click="addBlockToStep(vFormControls.IMAGE)"
                                >
                                    IMAGE
                                </div>
                                <div
                                    class="form-block col-xxl-6 col-md-12"
                                    @click="addBlockToStep(vFormControls.QR_CODE)"
                                >
                                    QR CODE
                                </div>
                                <div
                                    class="form-block col-xxl-6 col-md-12"
                                    @click="addBlockToStep(vFormControls.STEP_CONTROL)"
                                >
                                    STEP CONTROL
                                </div>
                                <div
                                    class="form-block col-xxl-6 col-md-12"
                                    @click="addBlockToStep(vFormControls.PROJECT_SWITCH)"
                                >
                                    PROJECT SWITCH
                                </div>
                                <div
                                    class="form-block col-xxl-6 col-md-12"
                                    @click="
                                        addBlockToStep(vFormControls.META_LIST_FILTER)
                                    "
                                >
                                    META LIST FILTER
                                </div>
                                <div
                                    class="form-block col-xxl-6 col-md-12"
                                    @click="addBlockToStep(vFormControls.MEDIA_LOADER)"
                                >
                                    MEDIA LOADER
                                </div>
                            </div>
                        </info-panel>
                        <info-panel
                            :collapsible="true"
                            class=""
                            info-panel-header="Layout"
                            :initially-collapsed="false"
                            preview-id="layoutElements"
                        >
                            <div class="block-container dark-ui" slot="info">
                                <div
                                    class="form-block col-xxl-6 col-md-12"
                                    @click="addBlockToStep(vFormControls.SEPARATOR)"
                                >
                                    DIVIDER
                                    <div class="separatorBlock"></div>
                                </div>
                            </div>
                        </info-panel>
                    </div>
                </div>
            </div>
            <div class="cell col-4 pl-1 pr-1">
                <div class="dark-ui">
                    <h3 class="p-2">3. FORM EDITOR</h3>
                    <!--STEP CONTAINER SORTED BY ASSOCIATED SLIDES-->
                    <div class="p-2">
                        <language-selector
                            @update="addTranslation"
                            :use-plain-language="true"
                            :value="language"
                        />
                    </div>
                    <div
                        v-if="config && config.steps.length"
                        class=""
                        :id="step.uuid"
                        :key="index + forceReRenderKey + 4529887"
                        v-for="(step, index) in config.steps"
                    >
                        <div
                            @click="toggleCollapse"
                            v-if="step.uuid == activeStep"
                            :class="[
                                'custom-info-header',
                                collapseAll ? 'is-collapsed' : '',
                            ]"
                        >
                            <h2 class="mb-0">{{ step.name ? step.name : "unnamed" }}</h2>
                            <icon type="angle-down" />
                        </div>
                        <info-panel
                            :collapsible="false"
                            class="mt-1"
                            :info-panel-header="step.name ? step.name : 'unnamed'"
                            :initially-collapsed="false"
                            :preview-id="'id-' + step.step"
                            v-if="step.uuid == activeStep"
                        >
                            <div class="" style="background: #21252b" slot="box">
                                <div
                                    class="slide-selector-container pt-2 pb-2"
                                    v-if="true"
                                >
                                    <!--SELECT SLIDE FOR STEP-->
                                    <slide-selector
                                        :initial-selected="step.linkedSlides"
                                        :exclude-slides="getAssignedSlides(step.uuid)"
                                        @selected="
                                            (selectedSlides) => {
                                                updateStepSlides(step, selectedSlides);
                                            }
                                        "
                                        :slides="slides"
                                        :thumbs="thumbs"
                                        :displayAllSlides="true"
                                        class=""
                                    />
                                </div>

                                <!--FIXED BOTTOM LEFT UPDATE BUTTON-->
                                <Button class="updateButton" @click="saveContent"
                                    >{{ $t("save") }} <icon type="save"
                                /></Button>

                                <!--<Button class="ml-1" @click="saveContent">{{ $t('remove') }} <icon type="trash-alt" /></Button>-->
                                <div
                                    class="fancy-scrollbar pr-1"
                                    style="
                                        height: 784px;
                                        overflow-y: scroll;
                                        overflow-x: hidden;
                                    "
                                >
                                    <div
                                        class="vform-element mt-2 mb-2"
                                        v-for="(element, elIndex) in step.elements"
                                        :key="elIndex + 6623000"
                                    >
                                        <div class="">
                                            <h2
                                                class="vFormBlockTitle pt-1 pl-1 col-9"
                                                @click="toggleElement(element)"
                                                style="cursor: pointer"
                                            >
                                                {{ element.formElementTypeString }}
                                            </h2>
                                            <div class="vFormBlockActions mt-1">
                                                <!-- MOVE DOWN -->
                                                <div class="mr-3" v-if="element.warnings">
                                                    <icon
                                                        class="warning-icon"
                                                        type="exclamation-triangle"
                                                    />
                                                </div>
                                                <div
                                                    class="mr-3"
                                                    v-if="
                                                        elIndex !==
                                                        step.elements.length - 1
                                                    "
                                                    @click="
                                                        moveElementDown(step, elIndex)
                                                    "
                                                >
                                                    <icon type="angle-down" />
                                                </div>
                                                <div
                                                    class="mr-3 icon-disabled"
                                                    v-if="
                                                        elIndex ==
                                                        step.elements.length - 1
                                                    "
                                                >
                                                    <icon type="angle-down" />
                                                </div>
                                                <!-- MOVE UP -->
                                                <div
                                                    class="mr-3"
                                                    v-if="elIndex !== 0"
                                                    @click="moveElementUp(step, elIndex)"
                                                >
                                                    <icon type="angle-up" />
                                                </div>
                                                <div
                                                    class="mr-3 icon-disabled"
                                                    v-if="elIndex == 0"
                                                >
                                                    <icon type="angle-up" />
                                                </div>
                                                <div
                                                    class="mr-3"
                                                    @click="deleteElement(step, elIndex)"
                                                >
                                                    <icon type="trash-alt" />
                                                </div>
                                            </div>
                                        </div>
                                        <!--EDITOR HERE-->
                                        <component
                                            v-show="element.expanded === true"
                                            :key="elIndex + 1023000"
                                            :is="'vForm' + getComponent(element)"
                                            :element="element"
                                            :step-no="step.step"
                                            :thumbs="thumbs"
                                            :slides="slides"
                                            :steps="config.steps"
                                            @save="
                                                (el) => {
                                                    element = el;
                                                }
                                            "
                                        >
                                        </component>
                                    </div>
                                </div>
                            </div>
                        </info-panel>
                    </div>
                </div>
            </div>
            <div class="cell col-4 darker pl-1"><h3>4. PREVIEW</h3></div>
        </div>
        <div v-if="true">
            <div class="darker p-3 mb-3">
                Unique identifier<info-helper class="ml-1" text="isUserIdentifierHint" />:
                {{ IDFields ? IDFields.length : 0 }}<br />
                <div :key="index + 234234" v-for="(step, index) in IDFields">
                    in step {{ step.step }} {{ step.name ? "(" + step.name + ")" : "" }}
                </div>
                <br />
                <Button
                    v-if="!config.logID"
                    class="highlight"
                    @click="createLoggingTable"
                    :deactivated="!(IDFields && IDFields.length === 1)"
                    >{{ $t("addLoggingTable") }}</Button
                >
                <info-helper
                    text="If you want to save the results in a table you need to create a logging table beforehand. A logging table can only be created if there is at least 1 element of the type short answer with the isID property set"
                />
                <content-display
                    class="w-100 mt-1 too-long-text-break"
                    v-if="config.logID"
                    :list-name="config.logID"
                    type="dataset"
                    :value="config.logID"
                    :show-teams="true"
                />
                <router-link
                    target="_blank"
                    :to="'/library/dataset/' + config.logID + '/general'"
                    >Link</router-link
                >
            </div>
            <Button @click="toggleCollapse" class="button mb-2"
                >{{ collapseAll ? $t("openAll") : $t("collapseAll") }}
                <icon :type="collapseAll ? 'box-open' : 'box'"
            /></Button>
            <div @click="loadProjectConfig">Load conf</div>
        </div>
    </div>
</template>

<script>
import Button from "@/components/forms/Button";
import Icon from "@/components/Icon";
import InfoPanel from "@/components/InfoPanel";
import LanguageSelector from "@/components/forms/LanguageSelector";
import vFormHEADLINE from "@/components/vForm/vFormHEADLINE";
import vFormWEBSITE from "@/components/vForm/vFormWEBSITE";
import vFormTEXT from "@/components/vForm/vFormTEXT";
import vFormMULTIPLE_CHOICE from "@/components/vForm/vFormMULTIPLE_CHOICE";
import vFormSEPARATOR from "@/components/vForm/vFormSEPARATOR";
import vFormSHORT_ANSWER from "@/components/vForm/vFormSHORT_ANSWER";
import vFormQR_CODE from "@/components/vForm/vFormQR_CODE";
import vFormIMAGE from "@/components/vForm/vFormIMAGE";
import vFormSTEP_CONTROL from "@/components/vForm/vFormSTEP_CONTROL";
import vFormSLIDE_CONTROL from "@/components/vForm/vFormSLIDE_CONTROL";
import vFormPROJECT_SWITCH from "@/components/vForm/vFormPROJECT_SWITCH";
import vFormMEDIA_LOADER from "@/components/vForm/vFormMEDIA_LOADER";
import vFormIndex from "@/components/vForm/vFormIndex";
import vFormIndexSteps from "@/components/vForm/vFormIndexSteps";
import SlideSelector from "@/components/vForm/SlideSelector";
import { vFormControls } from "@/enum";
import InfoHelper from "@/components/InfoHelper";
import Popup from "@/components/Popup";
import DeletePrompt from "@/components/forms/DeletePrompt";
import ContentDisplay from "@/components/widgets/editors/ContentDisplay";
import { v4 as uuidv4 } from "uuid";
export default {
    name: "vFormEditor",
    components: {
        Button,
        Icon,
        InfoPanel,
        LanguageSelector,
        vFormHEADLINE,
        vFormWEBSITE,
        vFormTEXT,
        vFormMULTIPLE_CHOICE,
        vFormSEPARATOR,
        vFormSHORT_ANSWER,
        vFormQR_CODE,
        vFormIMAGE,
        vFormSTEP_CONTROL,
        vFormSLIDE_CONTROL,
        vFormPROJECT_SWITCH,
        vFormMEDIA_LOADER,
        vFormIndex,
        vFormIndexSteps,
        SlideSelector,
        InfoHelper,
        Popup,
        DeletePrompt,
        ContentDisplay,
    },
    props: {
        code: { type: String, default: "" },
        projectId: { type: String, default: "" },
        organizationId: { type: String, required: true },
        assetName: { type: String, required: true },
        targetTeams: { type: Array, default: null },
    },
    data() {
        return {
            config: {},
            collapseAll: false,
            forceReRenderKey: 0,
            slides: null,
            thumbs: {},
            editImage: null,
            vFormControls: vFormControls,
            activeStep: "", //step uuid
            editingStepId: null,
            IDFields: [],
            showDeletePrompt: false,
            toBeDeleted: null,
            toBeDeletedType: null,
            language: "Unknown",
            elementsCollapsed: false,
        };
    },
    computed: {},
    watch: {
        slides() {
            this.loadThumbnails();
        },
        code() {
            this.setUpConfig();
        },
        projectId() {
            this.loadProjectConfig();
        },
        targetTeams() {
            this.addTeamsToLoggingTable();
        },
    },
    beforeMount() {
        this.loadProjectConfig();
    },
    methods: {
        getAssignedSlides(currentStepUuid) {
            let result = [];
            this.config.steps.forEach((step) => {
                if (currentStepUuid != step.uuid) {
                    result = [...step.linkedSlides, ...result];
                }
            });

            const uniqueSet = new Set(result);
            return [...uniqueSet];
        },
        async addTeamsToLoggingTable() {
            if (this.config.logID && this.targetTeams.length) {
                this.$store.dispatch(`clientAddDataSetResource`, {
                    id: this.id,
                    params: this.targetTeams.map((item) => {
                        return item.id;
                    }),
                });
            }
        },
        /**
         * Creates and adds a logging table so the vForm results (data entered into the form) get saved into a table
         * */
        async createLoggingTable() {
            let args = {
                name: this.assetName,
                type: "tbl",
                organizationId: this.organizationId,
                teams: this.targetTeams ? this.targetTeams : [],
                schema: {
                    columns: {
                        userID: {
                            type: "string",
                            columnIndex: 0,
                        },
                        sessionID: {
                            type: "string",
                            columnIndex: 1,
                        },
                        eventType: {
                            type: "string",
                            columnIndex: 2,
                        },
                        time: {
                            type: "timestamp",
                            columnIndex: 3,
                        },
                        slideName: {
                            type: "string",
                            columnIndex: 4,
                        },
                        message: {
                            type: "string",
                            columnIndex: 5,
                        },
                        location: {
                            type: "string",
                            columnIndex: 6,
                        },
                        durationInSeconds: {
                            type: "number",
                            columnIndex: 7,
                        },
                        points: {
                            type: "number",
                            columnIndex: 8,
                        },
                        totalAnswers: {
                            type: "number",
                            columnIndex: 8,
                        },
                    },
                },
            };
            await this.$store.dispatch("createDataset", args).then((dataset) => {
                this.config.logID = dataset.id;
                this.saveContent();
            });
        },

        /**
         * Removes a step from the config
         * First time it is called, it sets the toBeDeleted to the step.step and shows the confirm dialog
         * Second time it is called it finally deletes the step
         * @params {Integer} - the step.step number (must be unique)
         * */
        deleteStep(stepUuid = null) {
            if (this.toBeDeletedType) {
                const index = this.config.steps.findIndex((item) => {
                    return item.uuid === this.toBeDeleted;
                });
                this.config.steps.splice(index, 1);
                this.resetDelete();
                //this.countStepsPerSlide();
                this.forceReRenderKey++;
            } else {
                this.toBeDeleted = stepUuid;
                this.toBeDeletedType = "step";
                this.showDeletePrompt = true;
            }
        },
        /**
         * Removes a element from the step
         * First time it is called, it sets the toBeDeleted to the parent step and the index of the element and shows the confirm dialog
         * Second time it is called it finally deletes the element
         * @params {Object} - step the step object
         * @params {Integer} - the index of the element in the step.elements array
         * */
        deleteElement(step, elIndex) {
            if (this.toBeDeletedType) {
                const { step, elIndex } = this.toBeDeleted;
                step.elements.splice(elIndex, 1);
                this.resetDelete();
            } else {
                this.toBeDeleted = { step, elIndex };
                this.toBeDeletedType = "element";
                this.showDeletePrompt = true;
            }
        },
        /**
         * After deletion or on abort: reset the vars
         * */
        resetDelete() {
            this.toBeDeleted = null;
            this.toBeDeletedType = null;
        },
        moveElementUp(step, index) {
            const tmp = step.elements[index - 1];
            step.elements[index - 1] = step.elements[index];
            step.elements[index] = tmp;
            this.forceReRenderKey++;
        },
        toggleElement(element) {
            element.expanded = !element.expanded;
        },
        moveElementDown(step, index) {
            const tmp = step.elements[index + 1];
            step.elements[index + 1] = step.elements[index];
            step.elements[index] = tmp;
            this.forceReRenderKey++;
        },
        getComponent(element) {
            const editorName =
                element.formElementTypeString === vFormControls.CHECKBOXES
                    ? vFormControls.MULTIPLE_CHOICE
                    : element.formElementTypeString;
            const component = Object.keys(vFormControls).filter((key) => {
                return vFormControls[key] === editorName;
            })[0];
            return component;
        },
        /**
         * Sets the editingStepId to the
         * */
        editStepName(step) {
            if (!step.name) {
                step.name = "no name";
            }
            this.editingStepId = step.step;
        },
        /**
         * @params {Object} - step: the step to modify
         * @params {selectedSlides} - [uuid]: array of slide uuids
         * */
        updateStepSlides(step, selectedSlides) {
            step.linkedSlides = selectedSlides;
        },
        /**
         * Sets the property "hasSteps" on each slide with a boolean,
         * */
        // countStepsPerSlide() {
        //     if (this.slides) {
        //         this.slides = this.slides.map((item) => {
        //             item.hasSteps = this.getStepsPerSlide(item.uuid).length;
        //             return item;
        //         });
        //     }
        // },
        /**
         * Returns steps per slide
         * if the step slide uuid is not found in the slides from the project,
         * the step will appear in the "none" slide and the slide link marked as invalid
         * */
        // getStepsPerSlide(uuid) {
        //     if (!this.config || !this.config.steps) {
        //         return [];
        //     }
        //     if (uuid === "none") {
        //         return this.config.steps.filter((step) => {
        //             return (
        //                 (step.linkedSlide &&
        //                     (!step.linkedSlide || step.linkedSlide === "none")) ||
        //                 !step.linkedSlide ||
        //                 this.hasInvalidSlide(step)
        //             );
        //         });
        //     }

        //     return this.config.steps.filter((step) => {
        //         return step.linkedSlides && step.linkedSlides.find(uuid);
        //     });
        // },
        /**
         * Helper method for getStepsPerSlide
         * checks whether the step slide is in the slides array or not
         * if not, it is marked as invalid
         * */
        // hasInvalidSlide(step) {
        //     if (!step.linkedSlide.uuid) {
        //         return false;
        //     }
        //     return (
        //         this.slides.length &&
        //         !this.slides.filter((slide) => {
        //             return slide.uuid === step.linkedSlide.uuid;
        //         }).length
        //     );
        // },
        /**
         * Get the highest step.step number
         * */
        getMaxStepNo() {
            let max = 0;
            // get max step und mach die Step No max + 1
            // nimm step no ausm header
            this.config.steps.map((step) => {
                if (step.step > max) {
                    max = step.step;
                }
            });
            return max;
        },
        /**
         * Add a step to the config.steps array
         * if a slide is provided, the step will be attached to the slide
         * @params {Object} - a slide object, containing
         *   - {Uuid} - uuid
         *   - {Integer} - id
         * **/
        addStep(slide = null) {
            if (!this.config.steps) {
                this.config.steps = [];
                this.setVersion();
            }
            this.config.steps.push({
                step: this.getMaxStepNo() + 1,
                uuid: uuidv4(),
                elements: [],
                name: "unnamed step",
                hasNextButton: true,
            });
            if (slide) {
                this.addToSlide(
                    this.config.steps[this.config.steps.length - 1],
                    slide.uuid,
                    slide.id
                );
            }
            this.forceReRenderKey++;
        },
        /**
         * Add a new element to the step
         * @params {value} - the enum value of vFormControls
         * @parmas {Object} - the step object to which to add the element
         * **/
        addBlockToStep(value) {
            let step = this.config.steps.find((x) => x.uuid === this.activeStep);
            if (step) {
                const object = {
                    formElementTypeString: value,
                    formElementType: "",
                    //linkedSlide: step.linkedSlides ? { uuid: step.linkedSlides[0] } : "",
                    label: {
                        dix: {
                            Unknown: "",
                        },
                        imageDix: {
                            Unknown: "",
                        },
                    },
                    expanded: true,
                };
                step.elements.push(object);

                this.forceReRenderKey++;
            }

            this.addTranslation(this.language);
        },
        /**
         * Loads the vStage presentation config file from vHUB
         * and reads its slide information
         * if slides are found, it also loads the thumbnail for each slide
         * */
        async loadProjectConfig() {
            if (this.projectId) {
                this.$store
                    .dispatch("clientDownloadProjectZipPart", {
                        id: this.projectId,
                        key: "presentation.zip",
                        fileName: "config.json",
                    })
                    .then(async (file) => {
                        const config = file.text;
                        if (config) {
                            this.slides = JSON.parse(config).slides;
                        }

                        this.setUpConfig();
                    })
                    .catch(() => {
                        this.$emit("error");
                    });
            } else {
                this.slides = [{ uuid: "none" }];
                this.setUpConfig();
            }
        },
        /***
         * Loads the thumbnail for each slide
         * and triggers the setUpConfig
         * */
        async loadThumbnails() {
            for (let i = 0; i < this.slides.length; i++) {
                const item = this.slides[i];
                const num = item.id + 1;
                await this.$store
                    .dispatch("clientDownloadProjectZipPart", {
                        id: this.projectId,
                        key: "presentation.zip",
                        fileName:
                            "_slides/" +
                            ("000000000" + num).substr(-3) +
                            "/thumbnail.png",
                    })
                    .then(async (file) => {
                        const buff = await new Response(file.text).arrayBuffer();
                        const imgBase64 = new Buffer(buff, "binary").toString("base64");
                        this.thumbs[item.uuid] =
                            "data:" +
                            file.headers["content-type"] +
                            ";base64," +
                            imgBase64;
                    })
                    .catch(() => {
                        console.log("thumbnail not found for slide: " + num);
                    });
            }
            this.setUpConfig();
            this.forceReRenderKey++;
        },
        /**
         * Toggles the collapsed state of all the info-panels
         * */
        toggleCollapse() {
            this.collapseAll = !this.collapseAll;
            this.config.steps.forEach((step) => {
                step.elements.forEach((element) => {
                    element.expanded = !this.collapseAll;
                });
            });

            this.forceReRenderKey++;
        },
        /**
         * parses and url-decodes the config
         * counts the steps per slide and
         * checks if there is a user identifier present
         * */
        setUpConfig() {
            if (this.code) {
                console.log(this.code);
                this.config = JSON.parse(this.code);
                console.log(this.config);
                this.config.steps.forEach((step) => {
                    const index = this.config.steps.findIndex((s) => {
                        return s === step;
                    });
                    step.step = index + 1;
                    if (step.elements) {
                        step.elements.forEach((element) => {
                            element.expanded = true;
                        });
                    }
                });

                this.decodeEncode(true);
                this.normalizeSlideLinking();
                this.normalizeLinkedSlideToArray();
                this.checkForUserIdentifier();
                this.addTranslation(this.language);

                if (this.config.steps.length) {
                    this.activeStep = this.config.steps[0].uuid;
                }
            }
        },

        setVersion() {
            if (!this.config.version) {
                this.config.version = 2;
            }
        },
        /**
         * This if for legacy forms which have the linkedSlide only on the elements
         * check all elements and if one slide is given, set it to the parent as well
         * */
        normalizeSlideLinking() {
            if (this.config && this.config.steps && this.config.steps.length) {
                this.config.steps = this.config.steps.map((step) => {
                    if (step.linkedSlide) {
                        return step;
                    }
                    let linkedSlide = "";
                    if (step.elements && step.elements.length) {
                        step.elements.map((el) => {
                            if (el.linkedSlide) {
                                linkedSlide = el.linkedSlide.uuid;
                            }
                        });
                    }
                    if (linkedSlide && linkedSlide.uuid) {
                        step.linkedSlide = linkedSlide.uuid;
                    }
                    return step;
                });
            }
        },
        normalizeLinkedSlideToArray() {
            this.config.steps.forEach((step) => {
                if (!step.uuid) {
                    step.uuid = uuidv4();
                }

                if (!step.linkedSlides) {
                    step.linkedSlides = [];
                }

                if (step.linkedSlides.length === 0 && step.linkedSlide) {
                    step.linkedSlides = [step.linkedSlide];
                }
            });
        },
        /**
         * Checks if a unque user identifier is available
         * because the vForm only gets logged if an identifier is available
         * */
        checkForUserIdentifier() {
            if (this.config && this.config.steps) {
                this.IDFields = this.config.steps.filter((step) => {
                    return step.elements.filter((el) => {
                        return !!el.isID;
                    }).length;
                });
            }
        },
        /**
         * Decodes or encodes the config
         * (for saving it must be encoded)
         * */
        decodeEncode(decode = true) {
            if (this.config.steps) {
                this.config.steps = this.config.steps.map((item) => {
                    if (item.elements) {
                        item.elements = item.elements.map((el) => {
                            const keys = Object.keys(el.label.dix);
                            keys.map((key) => {
                                el.label.dix[key] = decode
                                    ? decodeURIComponent(
                                          el.label.dix[key].replace(/\+/g, " ")
                                      )
                                    : encodeURIComponent(el.label.dix[key]);
                            });
                            return el;
                        });
                    }
                    return item;
                });
            }
        },
        /**
         * Save the config to the content field
         * */
        saveContent() {
            this.setVersion();
            console.log(this.config);
            this.removeEmptyTranslations();
            this.decodeEncode(false);
            this.$emit("save", JSON.stringify(this.config));
            this.decodeEncode(true);

            console.log(this.config);
        },
        removeEmptyTranslations() {
            this.config.steps.forEach((step) => {
                step.elements.forEach((element) => {
                    delete element["currentLang"];
                    Object.keys(element.label.dix).forEach((key) => {
                        if (!element.label.dix[key]) {
                            delete element.label.dix[key];
                        }
                    });
                    Object.keys(element.label.imageDix).forEach((key) => {
                        if (!element.label.imageDix[key]) {
                            delete element.label.imageDix[key];
                        }
                    });

                    //options
                    if (element.options) {
                        element.options.forEach((option) => {
                            Object.keys(option.text.dix).forEach((key) => {
                                if (!option.text.dix[key]) {
                                    delete option.text.dix[key];
                                }
                            });
                            Object.keys(option.text.imageDix).forEach((key) => {
                                if (!option.text.imageDix[key]) {
                                    delete option.text.imageDix[key];
                                }
                            });
                        });
                    }
                });
            });
        },
        addTranslation(lang) {
            this.language = lang;
            this.config.steps.forEach((step) => {
                step.elements.forEach((element) => {
                    element.currentLang = lang;

                    //labels
                    if (!element.label.dix[lang]) {
                        element.label.dix[lang] = "";
                    }

                    if (!element.label.imageDix[lang]) {
                        this.addImgLang(lang, element);
                    }

                    //options
                    if (element.options) {
                        element.options.forEach((option) => {
                            if (!option.text.dix[lang]) {
                                option.text.dix[lang] = "";
                            }

                            if (!option.text.imageDix[lang]) {
                                option.text.imageDix[lang] = "";
                            }
                        });
                    }
                });
            });

            this.forceReRenderKey++;
        },
        addImgLang(lang, element) {
            element.label.imageDix[lang] = "";
        },
    },
};
</script>

<style lang="scss">
.v-form-container {
    .info-panel .box {
        padding: 0px;
    }

    .info-panel .info {
        padding: 0px !important;
    }

    .form-error {
        width: 100%;
        max-width: 100%;
    }
}

.v-form-container {
    min-width: 1280px;
}

.fit-content {
    max-width: fit-content;
}

.vform-element .label {
    width: 120px;
}

.vform-element {
    background-color: #343434 !important;
}

.dflex {
    display: flex;
    flex-wrap: wrap;
}

.checkbox-wrapper {
    margin-bottom: 0.5rem;
}

.checkbox-wrapper label {
    margin: auto 2px;
    cursor: pointer;
}
.checkbox-wrapper input {
    margin: auto 0px;
    cursor: pointer;
}

.w-90 {
    max-width: 90%;
}
.w-50 {
    width: 50%;
}
.w-40 {
    width: 40%;
}
.w-10 {
    width: 10%;
}
.form-text-dark {
    background-color: #1f1f1f !important;
    color: white;
    border-color: #1f1f1f !important;
    border-radius: 2px;
}
.answer-delete {
    text-align: right;
    flex: auto;
}
</style>

<style scoped>
.warning-icon {
    color: #da5e06;
}
.form-block a {
    text-decoration: underline;
}
.custom-info-header {
    cursor: pointer;
    background: #171615;
    width: 100%;
    position: relative;
    padding: 15px 25px;
}

.custom-info-header span {
    font-size: 1.14em;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.custom-info-header.is-collapsed span {
    transform: translateY(-50%) rotate(-90deg);
}

.vFormBlockActions {
    float: right;
    cursor: pointer;
    display: flex;
}

.vFormBlockTitle {
    display: inline-block;
    color: lightgray;
}
.icon-disabled {
    color: grey;
    cursor: initial;
}
.v-form-label {
    min-height: 150px;
}
.updateButton {
    position: fixed;
    bottom: 15px;
    left: 15px;
    z-index: 55;
}
.scrollspy-target {
    border: 2px solid red;
}
.block-container {
    display: flex;
    flex-wrap: wrap;
}
.form-block {
    background-color: #555;
    border: solid 2px #343434;
    border-radius: 6px;
    min-height: 50px;
    padding: 5px;
}
.form-block input,
textarea {
    width: 100%;
    cursor: pointer;
}
.form-block input[type="checkbox"] {
    cursor: pointer;
}
.form-block:hover {
    border: solid 2px #da5e06;
    cursor: pointer;
}
.form-block h2 {
    font-weight: bold;
    font-size: 0.95rem;
}
.form-block h3,
label,
input,
textarea {
    color: #ccc;
}
.separatorBlock {
    background: black;
    height: 5px;
}

.checkbox-icon:before {
    content: "\2714";
    font-size: 16px;
    top: 0px;
    left: 3px;
    color: #99a1a7;
    background: #000;
    padding: 1px 3px;
    border: solid 1px black;
    border-radius: 3px;
}
.checkbox-item {
    display: flex;
}

.slide-selector-container {
    margin-bottom: 5px;
    background: #343434;
}

/*new style */
.dark-ui {
    background-color: #343434;
}

.info-panel {
    background-color: #343434;
}
</style>
