<template>
  <div :class="[config.emphasized ? 'v-form-emphasized' : '']">
    {{ getLabel(config, lang) }}
  </div>
</template>

<script>
import UtilsMixinJs from "@/components/vForm/viewer/UtilsMixin.js";
export default {
  name: "Text",
  mixins: [UtilsMixinJs],
  props: {
    config: {type: Object, required: true},
    lang: {type: String, required: true}
  },
  methods: {
    validate() {
        return true;
    },
    checkRequired() {
       return true;
    },
    getLogEntries() {
        return [];
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-form-emphasized {
    font-size: 1.3em;
    color: $highlight-color;
    font-weight: bold;
  }
</style>