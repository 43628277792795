<template>
  <div id="app">
  <!--Step 2: receiving auth token from webapp: {{$store.getters.getLogin}} / {{$store.getters.authToken}} / {{authModuleLoaded && appHost}}<br />
  Login: {{ $store.getters.getLogin}}<br />
  {{$store.getters.getFullState}}-->
    <page-without-nav
      :app-host="appHost"
      v-if="authModuleLoaded">
        <router-view
          :project-id="$store.getters.getvStageProjectId"
         />
    </page-without-nav>
    <div v-else>
      loading spinner
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import PageWithoutNav from "@/apps/ccLight/PageWithoutNav";
import vStage from "@/store/vStage";
import {mapState} from "vuex";
import loadingSpinner from "@/components/LoadingSpinner";

export default {
  name: 'App',
  components: {
    PageWithoutNav,
    loadingSpinner
  },
  data() {
    return {
      authModuleLoaded: false,
      appHost: null,
    };
  },
  computed:
      mapState({
        currentUserId: state => state.users.currentUserId,
      }),
  beforeMount() {
    if(!this.$store.getters.getvStageModule) {
        this.$store.registerModule('vStage', vStage);
        console.log("vSTAGE Module loaded")
    }
    if(!this.$store.getters.getIdentityProviderName) {
      this.loadIdp();
    }
    if (window.vuplex) {
        this.addMessageListener();
    } else {
        window.addEventListener('vuplexready', this.addMessageListener);
    }
    window.callVue = this.callVue;
    this.getAppHost();
  },
  methods: {
    getAppHost() {
      return fetch(process.env.VUE_APP_CONFIG)
            .then(r => r.json())
            .then(config => {
                this.appHost = config.sfx.project.webapp.appHost;
              // eslint-disable-next-line no-console
                console.log(this.appHost);
                this.addWebappMessageListener();
                this.$store.dispatch("communicateReadyState");
      });
    },
    /***
    * This listener listens to the Vuplex plugin for vSTAGE (it is basically a browser)
    * */
    addMessageListener() {
        const $this = this;
        window.vuplex.addEventListener('message', function(event) {
            console.log("got message, origin: " + event.origin);
            const json = event.data;
            $this.callVue(json);
            // > JSON received: { "type": "greeting", "message": "Hello from C#!" }
            console.log('cc light JSON received: ' + json);
        });
    },
    /**
    * This listener listens to the webapp in case it is called in an iframe
    **/
    addWebappMessageListener() {
      const $this = this;
      window.addEventListener('message', function(event) {
            // always check the host which sends the message – or other non vHUB windows
            // can send messages to this window as well
             if (!$this.appHost || event.origin !== $this.appHost) {
              console.log(event.data);
              console.log("here is the cc light")
              console.log(event.origin + " does not equal " + $this.appHost);
              return;
             }

            const json = event.data;
            $this.callVue(json);
            // > JSON received: { "type": "greeting", "message": "Hello from C#!" }
            console.log('JSON received: ' + json);
        });
    },
     /**
    * Loads identity provider module
    * **/
    loadIdp() {
      fetch(process.env.VUE_APP_CONFIG)
      .then(r => r.json())
      .then(async config => {
          this.environment = config.sfx.environment;
          // get the selected identityProvider from the config.json
          const idp = config.identityProvider.name;
          // add the selected identityProvider module to tue vuex store
          const module = await import(`@/store/identityProviders/${idp}`);
          this.$store.registerModule('auth', module.default);
      }).then(async () => {
          await this.$store.dispatch('loadIdentityProviderClient');
          this.authModuleLoaded = true;
          console.log("idp module loaded")
      });
    },
    callVue(args) {
      this.$store.dispatch('vStageCommunicate', args);
    },
  }
}
</script>
<style>
    #app {
        height:100%;
        width:100vw;
        min-height:100vh;
        overflow-x: hidden;
    }
    #vhub {
      background-color: transparent !important;
    }
</style>

