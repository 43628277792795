<template>
<div></div>
</template>

<script>
export default {
  name: "vFormMixin.js.vue",
  methods: {
    /**
    * Removes a given form instance from a project
    * @params projectId - uuid - id of the target project
    * @params vFormInstanceId - uuid - id of the vForm Instance
    * */
    removeProjectFromvForm(projectId, vFormInstanceId) {
      return this.$store
                .dispatch("deleteProjectInstances", {
                    id: projectId,
                    args: [vFormInstanceId],
                })
    },
    /**
    * creates a vForm instance for a given project
    * @params projectId - uuid - id of the target project
    * @params vFormId - uuid - id of the form asset
    * @returns instance - Object - the created instance
    *
    * **/
    async addProjectTovForm(projectId, vFormId) {
      this.error = null;
      let instance;
      return await this.$store
      .dispatch("clientCreateProjectInstances", {
        id: projectId,
        args: [{assetId: vFormId}],
      })
      .then(async (data) => {
        instance = data[0];
        // load project teams
        return await this.$store.dispatch("loadProject", {
          id: projectId,
          include: "teams",
        });
      })
      .then((p) => {
        // attach all teams of the project to the vform (duplicates will be ignored)
        this.$store.dispatch(`clientAddAssetResource`, {
          id: vFormId,
          params: p.teams.map((item) => {
            return item.id;
          }),
        });
        return instance;
      })
    },
  }
}
</script>