<template>
  <div :class="['preview', currType + 'preview']">
    <slot/>
    <loading-spinner v-if="!hasGivenUp" />
    <component
        :is="component"
        :preview-data="localPreviewData"
        :id="previewId"
        :limited-preview="limitedPreview"
        :resize="true"
        :mime-type="mimeType"
        :key="forceReRenderKey"
    />
    <div v-if="!component && currType === 'word'" class="square-image" :id="id">
      <icon type="file-word" size="large"/>
      <!--<a download="qrcode.png" :href="getURL">Download</a>-->
    </div>
    <div v-else-if="!component && currType" class="square-image" :id="id">
      <icon type="file" size="large"/>
    </div>
    <div v-else-if="!component" :class="['square-image', limitedPreview ? 'lighter' : '']" :id="id">
      <icon class="lighter" type="cube" :size="limitedPreview ? '2' : 'large'" />
    </div>

    <div class="image-edit-toggler" v-if="editable" @click="$emit('edit')">
      <icon type="edit" />
    </div>
    <div @click="downloadFile" v-if="downloadable" class="preview-download-button">
      <icon type="file-download" /> <p v-if="originalKey">{{ key }}</p>
    </div>
    <div @click="downloadFile(originalKey)" v-if="downloadable && originalKey" class="preview-download-button2">
      <icon type="file-download" /> <p>{{ originalKey }}</p>
    </div>
    <div v-if="removable" class="remove" @click="$emit('clearPreviewUri')">
      <icon type="trash-alt" />
    </div>
    <slot name="afterpreview" />
  </div>
</template>

<script>
import FileTypeMixin from '@/components/files/FileTypeMixin.js.vue';
import Icon from "../Icon";
import { saveAs } from 'file-saver';
import LoadingSpinner from "../LoadingSpinner";

export default {
  name: "Preview",
  components: {
    Three: () => import('./Three'),
    Icon,
    LoadingSpinner,
    VideoPreview: () => import('@/components/preview/VideoPreview'),
    ImagePreview: () => import("@/components/preview/ImagePreview"),
    PDFPreview: () => import("@/components/preview/PDFPreview"),
    AudioPreview: () => import("@/components/preview/AudioPreview"),
  },
  mixins: [
    FileTypeMixin
  ],
  props: {
    assetId: {type: String, default: null},
    previewUri: {type: String, default: ''},
    previewData: {type: String, default: ''},
    type: {type: String, default: 'image'},
    /**
     * source {String} either asset, instance or project
     * */
    source: {type: String, default: ''},
    editable: {type: Boolean, default: false},
    downloadable: {type: Boolean, default: false},
    /**
    * A unique identifier in case multiple previews are used
    * */
    previewId: {type: String, required: true},
    limitedPreview: {type: Boolean, default: false},
    removable: {type: Boolean, default: true},
  },
  data() {
    return {
      forceReRenderKey: 0,
      component: 'ImagePreview',
      localPreviewUri: '',
      localPreviewData: null,
      currType: null,
      originalKey: null,
      mimeType: null,
      arrayBuffer: null,
      key: null,
      id: null,
      fbxMaxSize: 5000000, //209376 ca. 10MB
      previewMaxSize: 5000000,
      rawData: '',
      hasGivenUp: false,
    }
  },
  watch: {
    previewUri: function () {
      this.localPreviewUri = this.previewUri;
      if(this.localPreviewUri && !this.previewData) {
        this.localPreviewData = null;
        this.checkResource();
      }
      else if (!this.localPreviewUri && !this.previewData) {
        this.unloadPreviewContent();
        this.$emit('edit');
      }
    },
    localPreviewData: function () {
      if (this.localPreviewData) {
        this.$emit('preview');
      }
    },
    previewData: function() {
      if(this.previewData) {
        this.localPreviewData = this.previewData;
        this.setPreviewType();
      }
    },
    type: function(val) {
      this.currType = val ? val : this.currType;
    },
    currType: function() {
      this.setPreviewType();
    },
  },
  beforeMount() {
    if (!this.previewUri && !this.assetId) {
      this.unloadPreviewContent();
    } else if(this.assetId) {
      this.loadPreviewKey();
    } else {
      this.localPreviewUri = this.previewUri;
      this.checkResource();
    }
    if(this.type) {
      this.currType = this.type;
      this.setPreviewType();
    }
  },
  methods: {
    loadPreviewKey() {
      this.$store.dispatch("clientLoadAsset", {id: this.assetId})
        .then(asset => {
          this.localPreviewUri = asset.previewUri;
          this.checkResource();
        });
    },
    setPreviewType() {
      if(this.noPreview) {
        this.currType = null;
        this.component = null;
        return;
      }
      if(this.localPreviewUri) {
        this.currType = this.getTypeByFileEnding(this.localPreviewUri);
      }
      this.component = this.previews[this.currType];
    },
    downloadFile(key = null) {
      let arr = this.localPreviewUri.split('/');
      const localId = arr[arr.length - 2];
      let localKey = arr[arr.length - 1];
      if(typeof(key) === 'string') {
        localKey = key;
      }
      this.$store.dispatch(this.getDownloadDispatcher(), {id: localId, key: localKey}).then(data => {
        if (!data) {
          return '';
        }
        let fileToSave = data.text;
        if(this.isTextFile(localKey)) {
          fileToSave = new Blob([data.text], {
              type: 'application/json',
              name: localKey
          })
        }
        (async () => {
         // let arr = this.localPreviewUri.split('/');
          // var fileName = arr.pop();
          saveAs(fileToSave, this.id + '_' +localKey);
        })();
      });
    },
    getInfoDispatcher() {
      if (this.source === 'instance') {
        return 'clientGetInstanceInfo';
      }
      return 'clientGetAssetInfo';
    },
    getDownloadDispatcher() {
      if (this.source === 'instance') {
        return 'clientDownloadInstance';
      }
      else if(this.source === 'assetQrcode') {
        return 'clientLoadAssetQrcode';
      }
      else if(this.source === 'projectQrcode') {
        return 'clientLoadProjectQrcode';
      }
      else if(this.source.toLowerCase() === 'project') {
        return 'clientDownloadProject';
      }
      return 'clientDownloadAsset';
    },
    checkResource: function () {
      console.log('checking resource...')
      let arr = this.localPreviewUri.split('/');
      this.id = arr[arr.length - 2];
      this.key = arr[arr.length - 1];
      this.setPreviewType();
      // first check if preview is even possible for this type
      const ext = this.getExtension(this.localPreviewUri) ? this.getExtension(this.localPreviewUri).toLowerCase() : '';
      if(!ext || !this.allowPreviewsFor.includes(ext)) {
        console.log('no preview possible for this type');
        this.noPreview = true;
        this.setPreviewType();
        this.hasGivenUp = true;
        return;
      }
      else if (['fbx', 'model', 'assembly'].includes(this.currType)) {
        this.$store.dispatch(this.getInfoDispatcher(), {id: this.id, key: this.key}).then(data => {
          let size = Number(data['content-length']);
          if (size > this.fbxMaxSize) {
            this.$log.debug('fbx is too large, switching to image preview...');
            this.originalKey = this.key;
            this.key = this.getDefaultKey('png');
            this.localPreviewUri = this.localPreviewUri.replace(this.originalKey, this.key);
            this.$log.debug('switched to defaultKey ' + this.key);
            this.setPreviewType();
            this.forceReRenderKey++;
          }
          this.getResource();
        });
      } else {
        if(this.source === 'asset') {
            this.$store.dispatch(this.getInfoDispatcher(), {id: this.id, key: this.key}).then(data => {
              let size = Number(data['content-length']);
              if (size > this.previewMaxSize && this.limitedPreview) {
                this.noPreview = true;
                this.setPreviewType();
                this.hasGivenUp = true;
              } else {
                this.getResource();
              }
          });
        }
        else {
          this.getResource();
        }
      }
    },
    getResource: function () {
      this.setPreviewType();
      let args = {
         id: this.id,
        key: this.key
      };
      if(this.limitedPreview) {
        args.width = 60;
        args.height = 60;
      }
      this.$store.dispatch(this.getDownloadDispatcher(), args).then(data => {
        if (!data) {
          this.hasGivenUp = true;
          this.$emit('edit');
          return;
        }
        else {
          this.hasGivenUp = false;
        }
        (async () => {
          this.mimeType = data.headers['content-type'].toLowerCase();
          this.arrayBuffer = await new Response(data.text).arrayBuffer();
          switch (this.currType) {
            case 'image':
              this.render();
              break;
            case 'pdf':
              this.renderPDF();
              break;
            case 'video':
              this.render();
              break;
            case 'audio':
              this.render();
              break;
            default:
              this.localPreviewData = data;
              break;
          }
          this.hasGivenUp = true;
          this.$emit('preview');
        })();
      }).catch(err => {
        this.hasGivenUp = true;
        this.$log.error(err)
        // this.$emit('edit');
        this.noPreview = true;
        this.currType = 'any';
      });
    },
    render: function () {
      const imgBase64 = new Buffer(this.arrayBuffer, 'binary').toString('base64');
      this.localPreviewData = 'data:' + this.mimeType + ';base64,' + imgBase64;
    },
    renderPDF: function () {
      const imgBase64 = new Buffer(this.arrayBuffer, 'binary').toString('base64');
      let pdf = this.base64ToUint8Array(imgBase64);
      this.localPreviewData = {
        data: pdf
      };
    },
    base64ToUint8Array: function (base64) {
      const raw = atob(base64);
      const uint8Array = new Uint8Array(raw.length);
      for (let i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i);
      }
      return uint8Array;
    },
    unloadPreviewContent: function () {
      this.$log.warn('resetting preview image to null');
      this.localPreviewData = '';
      this.hasGivenUp = true;
    },
  },
}
</script>

<style lang="scss" scoped>
.remove, .preview-download-button, .preview-download-button2 {
  opacity: 0;
}
.preview:hover {
  .remove, .preview-download-button, .preview-download-button2 {
    opacity: 1;
  }
}
.image-edit-toggler, .preview-download-button, .remove, .preview-download-button2 {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 1.3em;
  //padding: 6px 8px 6px 10px;
  width:2.3em;
  height:2.3em;
  background-color: $highlight;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  .icon {
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }

  &:hover {
    background-color: darken($highlight, 10%);
  }
}
.pdfpreview {
  .image-edit-toggler {
    top: 50px;
  }
  /*.preview-download-button, .remove {
    bottom: 50px;
  }*/
}
.preview-download-button {
  bottom: 15px;
  right: 15px;
  top:auto;
  background-color: $panel-background-color;
  p {
    font-size: 0.7rem;
    position:absolute;
    top: -15px;
    background-color: $panel-background-color;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity:0;
  }
  &:hover {
    p {
      opacity: 1;
    }
  }
}
.preview-download-button2 {
  bottom: 15px;
  left: 15px;
  top:auto;
  background-color: $panel-background-color;
  p {
    font-size: 0.7rem;
    position:absolute;
    top: -15px;
    background-color: $panel-background-color;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity:0;
  }
  &:hover {
    p {
      opacity: 1;
    }
  }
}
.remove {
  bottom:15px;
  left:15px;
  right:auto;
  top:auto;
  background-color: $panel-background-color;
}
.preview {
  position:relative;
  .selectLinking {
    cursor:pointer;
    position:absolute;
    top:0;
    right:0;
    z-index:3;
    div {
      padding:5px 8px;
      background-color: rgba(255,255,255,0.8);
      color:#000;
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;
      &.selected, &:hover {
        background-color: $highlight;
        color:#fff;
      }
    }
  }
}

</style>
